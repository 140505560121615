import React, { useState } from "react"
import "./customCheckbox.css" // Add your CSS file or include styles directly in the component
import { Checkbox } from "@mui/material"

function CustomCheckbox({
  fill,
  onchange,
  isChecked,
  toggleCheckbox,
  isCheckedColor,
  isUnCheckedColor,
  defaultChecked = false,
}) {
  return (
    <>
      {fill ? (
        <div
          className={`custom-checkbox ${isChecked ? "checked" : ""}`}
          onClick={toggleCheckbox}
          style={{
            backgroundColor: isChecked
              ? isCheckedColor ?? "#FA8232"
              : isUnCheckedColor ?? "lightgray",
          }}
        >
          {/* You can add a label or any other content here */}
        </div>
      ) : (
        <Checkbox onChange={onchange} checked={defaultChecked} />
      )}
    </>
  )
}

export default CustomCheckbox
