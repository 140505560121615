import * as React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import MenuIcon from "@mui/icons-material/Menu"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import logo from "../../../assets/logo.png"
import { Avatar, Stack, Tooltip } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import "./navbar.css"
import CustomButton from "../Button/Button"
import Input from "../Input/Input"
// import { useSelector } from "react-redux"
import SearchIcon from "@mui/icons-material/Search"
import { BsFillCartFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { exit_session } from "../../../config/apiHandle/apiHandle"
import { success_toast_message } from "../../../utils/toast_notification"
import { get_all_products_async } from "../../../store/services/productsService"
import { IoSearchOutline } from "react-icons/io5"
import { primaryColour } from "../../../utils/colorTheme"
import { FaRegEnvelope } from "react-icons/fa"
import { IoMdNotificationsOutline } from "react-icons/io"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { deepOrange } from "@mui/material/colors"
import { IoIosArrowDown } from "react-icons/io"
import CustomPopover from "../../CustomPopover/CustomPopover"
import CustomDropdown from "../../CustomDropdown/CustomDropdown"
import { user_type_constant } from "../../../utils/constants"
import { LuLayoutDashboard } from "react-icons/lu"
import { FiLogOut } from "react-icons/fi"
import { useEffect } from "react"

function Navbar(props) {
  const { window: parentwindow } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [data, setData] = React.useState("")
  const { userAuth, userData } = useSelector((state) => state.auth)

  const [showDashboardBtn, setShowDashboardBtn] = React.useState(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const changeHandle = (elm) => {
    setData(elm.target.value)
  }
  const search = () => {
    if (window.location.pathname.includes("/category")) {
      dispatch(get_all_products_async({ data: { name: data } }))
    } else {
      dispatch(get_all_products_async({ data: { name: data } }))
      navigate("/category")
    }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          // display: { md: "block", sm: "none", xs: "none" },
          cursor: "pointer",
        }}
        my={1}
        onClick={() => navigate("/")}
      >
        <img
          style={{ objectFit: "contain", width: "90px", height: "60px" }}
          src={logo}
        />
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")} sx={{ color: "black" }}>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <CustomDropdown />
          </ListItemButton>
        </ListItem>

        {userAuth && userData?.user_type !== "seller" && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate("/add-cart")}
              sx={{ color: "black" }}
            >
              <ListItemText primary={"Cart"} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem
          disablePadding
          sx={{
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "block",
            },
          }}
        >
          <ListItemButton>
            {userData?.user_type === "seller"
              ? userData?.seller_business_profile &&
                showDashboardBtn && (
                  <CustomButton
                    style={{ backgroundColor: "#FFF4E5", color: "#F89500" }}
                    onClick={() => navigate("/seller-dashboard")}
                  >
                    Dashboard
                  </CustomButton>
                )
              : showDashboardBtn && (
                  <CustomButton
                    style={{ backgroundColor: "#FFF4E5", color: "#F89500" }}
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </CustomButton>
                )}
          </ListItemButton>
        </ListItem>

        <Stack
          spacing={2}
          style={{
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <>
            {userAuth ? null : (
              <Stack
                px={2}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  display: {
                    xl: "none",
                    lg: "none",
                    md: "none",
                    sm: "none",
                    xs: "flex",
                  },
                }}
              >
                <CustomButton onClick={() => navigate("/signup")}>
                  Signup
                </CustomButton>
                <CustomButton onClick={() => navigate("/login")}>
                  Login
                </CustomButton>
              </Stack>
            )}
            {/* {userData?.user_type === user_type_constant.SELLER ? null : (
              <Stack px={2} justifyContent={"center"} alignItems="">
                <CustomButton
                  style={{ backgroundColor: "#FFF4E5", color: "#F89500" }}
                  onClick={() => navigate("/complete-business-profile")}
                >
                  Become Seller
                </CustomButton>
              </Stack>
            )} */}
          </>
        </Stack>
      </List>
    </Box>
  )
  const container =
    parentwindow !== undefined ? () => parentwindow().document.body : undefined

  useEffect(() => {
    setShowDashboardBtn(
      location.pathname.split("/")[1] !== "seller-dashboard" &&
        location.pathname.split("/")[1] !== "dashboard",
    )
  }, [location.pathname])

  return (
    <Box sx={{ display: "flex", color: "black" }}>
      <AppBar
        component="nav"
        color="inherit"
        position="static"
        sx={{ boxShadow: "none" }}
      >
        <Stack
          className="sethampburger"
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-around"}
          p={0.5}
        >
          {/* <Toolbar > */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              display: {
                xl: "none",
                lg: "none",
                md: "none",
                sm: "flex",
                xs: "flex",
              },
              height: "40px",
              width: "40px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* logo image */}
          <Box
            sx={{
              display: { md: "block", sm: "none", xs: "none" },
              cursor: "pointer",
            }}
            my={1}
            onClick={() => navigate("/")}
          >
            <img
              style={{ objectFit: "contain", width: "80px", height: "50px" }}
              src={logo ?? ""}
            />
          </Box>

          <Stack
            sx={{
              flexGrow: {
                xs: 1,
                sm: 1,
                md: 0,
              },
            }}
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            gap={2}
          >
            <Stack sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
              <CustomDropdown />
            </Stack>

            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "5px",
                backgroundColor: "#EBEBEB",
                width: {
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 400,
                  xl: 450,
                },
                height: "40px",
              }}
            >
              <Input
                placeholder={"Search"}
                style={{
                  backgroundColor: "transparent",
                  borderRadius: 0,
                  width: "100%",
                  border: "none",
                }}
                onChange={(e) => changeHandle(e)}
              />
              <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
              <CustomButton
                style={{
                  borderRadius: "9px",
                  backgroundColor: "transparent",
                }}
                onClick={search}
              >
                <IoSearchOutline color={primaryColour} size={20} />
                {/* <SearchIcon fontSize="large" /> */}
              </CustomButton>
            </Stack>

            {/* small screen logout and dashboard  */}

            <Stack
              alignItems={"center"}
              sx={{
                display: {
                  xl: "none",
                  lg: "none",
                  md: "none",
                  sm: "block",
                  xs: "block",
                },
              }}
              direction={"row"}
              gap={2}
              px={2}
            >
              {userAuth ? (
                <>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Tooltip
                      title={`${userData?.first_name} ${userData?.last_name}`}
                    >
                      <Avatar
                        sx={{
                          bgcolor: !userData?.profile_avatar
                            ? deepOrange[500]
                            : "#EFF0EB",
                          width: 30,
                          height: 30,
                          border: "1px solid lightgrey",
                        }}
                        alt="Remy Sharp"
                        src={
                          userData?.profile_avatar ?? userData?.profile_avatar
                        }
                      >
                        {!userData?.profile_avatar &&
                          userData?.first_name.slice(0, 1)}
                      </Avatar>
                    </Tooltip>
                    <CustomPopover />
                  </Stack>
                </>
              ) : null}
            </Stack>

            {/* {userData?.user_type ===
            user_type_constant.SELLER ? null : userAuth ? (
              <Stack
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                  },
                }}
              >
                <CustomButton
                  style={{ backgroundColor: "#FFF4E5", color: "#F89500" }}
                  onClick={() => navigate("/complete-business-profile")}
                >
                  Become Seller
                </CustomButton>
              </Stack>
            ) : null} */}
          </Stack>
          <Stack
            sx={{
              display: {
                xs: "none",
                sm: "flex",

                md: "flex",
                color: "black",
                flexDirection: "row",
              },
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {userAuth ? (
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems="center"
                gap={2}
              >
                {/* <CustomButton onClick={submit}>Logout</CustomButton> */}
                {/* <Stack
                  position={"relative"}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                    },
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: -5,
                      right: -5,
                      width: 20,
                      height: 20,
                      fontSize: 12,
                      textAlign: "center",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                    }}
                  >
                    {3}
                  </span>
                  <FaRegEnvelope
                    onClick={() => navigate("/chat")}
                    fontSize={30}
                  />
                </Stack> */}
                {userData?.user_type === "seller"
                  ? userData?.seller_business_profile &&
                    showDashboardBtn && (
                      <CustomButton
                        style={{ backgroundColor: "#FFF4E5", color: "#F89500" }}
                        onClick={() => navigate("/seller-dashboard")}
                      >
                        Dashboard
                      </CustomButton>
                    )
                  : showDashboardBtn && (
                      <CustomButton
                        style={{ backgroundColor: "#FFF4E5", color: "#F89500" }}
                        onClick={() => navigate("/dashboard")}
                      >
                        Dashboard
                      </CustomButton>
                    )}

                <Stack
                  position={"relative"}
                  direction={"row"}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                    },
                    cursor: "pointer",
                  }}
                  alignItems={"center"}
                  gap={1}
                >
                  {userData?.user_type !== "seller" && (
                    <Tooltip title="Cart">
                      <IconButton
                        sx={{
                          cursor: "pointer",
                          color: "rgb(54, 54, 54)",
                          fontSize: "22px",
                        }}
                        onClick={() => navigate("/add-cart")}
                      >
                        <AiOutlineShoppingCart />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip
                    title={`${userData?.first_name} ${userData?.last_name}`}
                  >
                    <Avatar
                      sx={{
                        bgcolor: !userData?.profile_avatar
                          ? deepOrange[500]
                          : "#EFF0EB",
                        width: 30,
                        height: 30,
                        border: "1px solid lightgrey",
                      }}
                      alt="Remy Sharp"
                      src={userData?.profile_avatar ?? userData?.profile_avatar}
                    >
                      {!userData?.profile_avatar &&
                        userData?.first_name.slice(0, 1)}
                    </Avatar>
                  </Tooltip>
                  <CustomPopover />
                </Stack>

                {/* <Stack alignItems={"center"} direction={"row"} gap={2} px={2}> */}
                {/* <Stack>
                    <Typography
                      sx={{
                        fontSize: 15,
                        color: "rgb(54, 54, 54)",
                        fontWeight: "600",
                      }}
                    >
                      {userData?.first_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 15,
                        color: "rgb(54, 54, 54)",
                        fontWeight: "600",
                      }}
                    >
                      {userData?.last_name}
                    </Typography>
                  </Stack> */}
                {/* <Stack>
                    <CustomPopover />
                  </Stack> */}
                {/* </Stack> */}
              </Stack>
            ) : (
              <>
                <Stack justifyContent={"center"} alignItems="center">
                  <CustomButton
                    style={{
                      backgroundColor: "#EBEBEB",
                      color: primaryColour,
                    }}
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </CustomButton>
                </Stack>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Stack justifyContent={"center"} alignItems="center">
                  <CustomButton onClick={() => navigate("/signup")}>
                    Signup
                  </CustomButton>
                </Stack>
              </>
            )}
          </Stack>

          {/* <Stack
            position={"relative"}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
            }}
          >
            <span
              style={{
                position: "absolute",
                top: -5,
                right: -5,
                width: 20,
                height: 20,
                fontSize: 12,
                textAlign: "center",
                borderRadius: "50%",
                backgroundColor: "red",
                color: "white",
              }}
            >
              {3}
            </span>
            <BsFillCartFill fontSize={40} />
          </Stack> */}
        </Stack>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  )
}
Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  parentwindow: PropTypes.func,
}
export default Navbar
