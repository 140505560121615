import React, { useState } from "react"
import { Typography, Box, Stack, Grid, IconButton } from "@mui/material"
import IncreDecrBtn from "../../common/increDecBtn/Increm_Dec_Btn"
import camera from "../../../assets/checkout-camera.png"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { FaRegHeart } from "react-icons/fa"
import { FaHeart } from "react-icons/fa"
import css from "../../../screens/addCart/AddtoCart.module.css"
import { primaryColour } from "../../../utils/colorTheme"
import { useDispatch } from "react-redux"
import { add_wishlist_products_async } from "../../../store/services/wishlistService"

const CardAddCamera = ({
  cartImage,
  title,
  description,
  isFavorite,
  productId,
  price,
  product_quantity,
  delete_product,
  handle_Increment,
  handle_decrement,
}) => {
  const dispatch = useDispatch()
  const handleDescription = (desc) => {
    const description = desc.slice(0, 40)

    return description + "..."
  }

  const _handleFavoriteProduct = () => {
    dispatch(
      add_wishlist_products_async({
        product_id: productId,
        is_add: !isFavorite,
      }),
    )
  }

  return (
    <Stack
      flexWrap={{
        xl: "nowrap",
        lg: "nowrap",
        md: "nowrap",
        sm: "nowrap",
        xs: "wrap",
      }}
      alignItems={"center"}
      justifyContent={"center"}
      height={{
        xl: "140px",
        lg: "140px",
        md: "140px",
        sm: "140px",
        xs: "auto",
      }}
      px={1}
      pb={1.5}
      mb={2}
      mx={2}
      // sx={{ borderBottom: "1px solid #C5C5C5" }}
    >
      <Stack
        width={1}
        direction={{ xl: "row", lg: "row", md: "row", sm: "row", xs: "column" }}
        gap={1}
        alignItems={{
          xl: "center",
          lg: "center",
          md: "center",
          sm: "center",
          xs: "flex-start",
        }}
        justifyContent={"space-between"}
        height={1}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          height={1}
          width={1}
        >
          <Box
            sx={{
              height: "130px",
              width: "140px",
              borderRadius: "10px",
              backgroundColor: "#EFF0EB",
              p: 1,
            }}
          >
            <img
              height={"100%"}
              width={"100%"}
              src={cartImage || camera}
              alt="cart_Image"
              style={{ objectFit: "contain", borderRadius: "10px" }}
            />
          </Box>

          <Stack
            alignItems={"flex-start"}
            justifyContent={"space-around"}
            sx={{ height: "120px" }}
          >
            <Stack gap={0.5}>
              <Typography
                sx={{ fontSize: "15px !important" }}
                className={css.secondary_txt}
              >
                {title ?? ""}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#a6a6a6",
                  whiteSpace: {
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "nowrap",
                    sm: "nowrap",
                    xs: "wrap",
                  },
                }}
              >
                {handleDescription(description) ?? ""}
              </Typography>
            </Stack>
            {/* <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#a6a6a6",
                whiteSpace: "nowrap",
              }}
            >
              {`Size : XL`}
            </Typography> */}
            {/* <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#a6a6a6",
                whiteSpace: "nowrap",
              }}
            >
              {`Color : Black`}
            </Typography> */}

            <IncreDecrBtn
              handle_decrement={handle_decrement}
              handle_Increment={handle_Increment}
              quantity={product_quantity}
            />
          </Stack>
        </Stack>

        <Stack
          direction={{
            xl: "column",
            lg: "column",
            md: "column",
            sm: "column",
            xs: "row-reverse",
          }}
          justifyContent={{
            xl: "space-around",
            lg: "space-around",
            md: "space-around",
            sm: "space-around",
            xs: "space-between",
          }}
          alignItems={{
            xl: "flex-end",
            lg: "flex-end",
            md: "flex-end",
            sm: "flex-end",
            xs: "center",
          }}
          sx={{ width: 1, height: 1 }}
        >
          <Stack
            alignItems={{
              xl: "flex-end",
              lg: "flex-end",
              md: "flex-end",
              sm: "flex-end",
              xs: "center",
            }}
            direction={{
              xl: "column",
              lg: "column",
              md: "column",
              sm: "column",
              xs: "row",
            }}
          >
            <Typography
              sx={{ mx: 1, fontSize: "13px !important" }}
              className={css.primary_txt}
            >
              {product_quantity ?? ""} * ${price.toLocaleString() ?? "0"}
            </Typography>

            <Typography
              sx={{
                mx: 1,
                color: `${primaryColour} !important`,
                fontSize: "20px !important",
              }}
              className={css.secondary_txt}
            >
              ${(product_quantity * price).toLocaleString() ?? ""}
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <IconButton
              sx={{
                fontSize: 20,
                "&:hover": {
                  backgroundColor: "#fa823225",
                  color: primaryColour,
                },
              }}
              onClick={delete_product}
            >
              <MdOutlineDeleteOutline />
            </IconButton>
            
            <IconButton
              sx={{
                fontSize: 18,
                "&:hover": {
                  backgroundColor: "#fa823225",
                  color: primaryColour,
                },
              }}
              onClick={() => _handleFavoriteProduct()}
            >
              {isFavorite ? <FaHeart /> : <FaRegHeart />}
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CardAddCamera
