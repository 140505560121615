import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import css from "./CustomerDetail.module.css"
import CustomButton from "../../../components/common/Button/Button"
import { BiExport } from "react-icons/bi"
import wallet from "../../../assets/states_icons/wallet.png"
import cart from "../../../assets/states_icons/cart.png"
import { FiPlus } from "react-icons/fi"
import { MdOutlineDateRange } from "react-icons/md"
import { BiSlider } from "react-icons/bi"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { Input } from "antd"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"
import { useState } from "react"
import { useEffect } from "react"
import watch_pic1 from "../../../assets/productsListImages/watch.png"
import watch_pic2 from "../../../assets/productsListImages/watch2.png"
import pouch from "../../../assets/productsListImages/pouch.png"
import { FiTrash2 } from "react-icons/fi"
import { LuPencil } from "react-icons/lu"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { set_product_status } from "../../../store/slices/product_slice"
import {
  delete_product_async,
  get_product_by_user_id_async,
} from "../../../store/services/productsService"
import profile_img from "../../../assets/profile_pic.png"
import { MdLockOutline } from "react-icons/md"
import { FaRegEnvelope } from "react-icons/fa6"
import { FiPhone } from "react-icons/fi"
import { LuMapPin } from "react-icons/lu"
import { LuShoppingCart } from "react-icons/lu"

const ProductComponent = (props) => {
  const { image_url, title, product_qty } = props
  return (
    <Stack spacing={1} direction={"row"}>
      <Stack>
        <img
          height={44}
          width={44}
          // src={image_url ?? ""}
          src={watch_pic1}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>
          {title ?? "Image title"}
        </Typography>
        <Typography className={css.product_qty}>
          {product_qty ?? "+3 other products"}
        </Typography>
      </Stack>
    </Stack>
  )
}

/* -----> transaction history table column */
const transaction_history_columns = [
  {
    title: "Product",
    dataIndex: "product",
    render: (product) => {
      return (
        <>
          <ProductComponent title={product} />
        </>
      )
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    render: (total) => (
      <Typography className={css.total}>{`$${total ?? "84,611"}`}</Typography>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status = "Shiped") => {
      const process_val = status === "Processing"
      const deliver_val = status === "Delivered"
      return (
        <Typography
          className={css.status}
          sx={{
            backgroundColor: process_val
              ? "#FEEDEC"
              : deliver_val
              ? "#E7F4EE"
              : "#E8F8FD",
            color: process_val
              ? "#F04438"
              : deliver_val
              ? "#0D894F"
              : "#13B2E4",
          }}
        >
          {status ?? "Status"}
        </Typography>
      )
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (date) => (
      <Typography className={css.date}>{date ?? "12 Dec 2023"}</Typography>
    ),
  },
]

const CustomerDetail = () => {
  const [inner_width, setCurrentWidth] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { get_product_by_user_id_data } = useSelector((state) => state.product)

  useEffect(() => {
    setLoading(true)
    dispatch(set_product_status())
    dispatch(get_product_by_user_id_async())
  }, [])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500) // Simulated API response delay of 500ms

    const handleInnerWidth = () => {
      setCurrentWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleInnerWidth)

    return () => window.addEventListener("resize", handleInnerWidth)
  }, [])

  const customer_personal_info_arr = [
    {
      icon: MdLockOutline,
      label: "User ID",
      info: "ID-011221",
    },
    {
      icon: FaRegEnvelope,
      label: "Billing Email",
      info: "lindablair@mail.com",
    },
    {
      icon: FiPhone,
      label: "Phone Number",
      info: "050 414 8778",
    },
    {
      icon: LuMapPin,
      label: "Delivery Address",
      info: "1833 Bel Meadow Drive, Fontana, California 92335, USA",
    },
    {
      icon: LuShoppingCart,
      label: "Latest Transaction",
      info: "12 December 2022",
    },
  ]

  const states_arr = [
    {
      icon: wallet,
      title: "Total Balance",
      price: "$723.00",
      discount: "-25%",
    },
    {
      icon: cart,
      title: "Total Orders",
      price: "1296",
      discount: "+10%",
    },
  ]

  return (
    <Box
      width={
        inner_width < 500
          ? "95%"
          : inner_width < 600
          ? "100%"
          : inner_width < 700
          ? "95%"
          : "100%"
      }
      // sx={{ backgroundColor: "blue" }}
    >
      {/** -------- customer detail title -------- **/}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Typography className={css.title}>Customer Detail</Typography>

        {/* ---- export button ---- */}
        <CustomButton
          style={{
            backgroundColor: "#FFF8EE",
            border: "1px solid #FFF8EE",
            borderRadius: "8px",
          }}
          onClick={() => {}}
        >
          <Stack
            spacing={1}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <BiExport size={20} color="#fa8232" />
            <Typography className={css.export_add_btn}>Export</Typography>
          </Stack>
        </CustomButton>
      </Stack>

      {/** -------- customer detail profile -------- **/}

      <Grid
        container
        justifyContent={"space-between"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
        gap={1}
        my={3}
      >
        {/** -------- customer personal info -------- **/}

        <Grid
          item
          xl={4.5}
          lg={4.5}
          md={4.5}
          sm={4.5}
          xs={12}
          className={css.profile_wrapper}
          justifyContent={"space-between"}
          alignSelf={{
            xl: "center",
            lg: "start",
            md: "start",
            sm: "start",
            xs: "center",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box className={css.cover_img_wrapper}></Box>
            <Box className={css.profile_img_wrapper}>
              <img src={profile_img} alt="profile" />
            </Box>
            <Box className={css.customer_name_wrapper}>
              <Typography className={css.customer_name}>
                Linda Blair 
                {/* <span>Premium</span> */}
              </Typography>
              <Typography className={css.customer_email}>
                @linda_blair321
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ width: 0.85, margin: "auto" }} />

          <Box px={3} pb={2}>
            {customer_personal_info_arr.map((info, i) => {
              return (
                <Stack key={i} className={css.info_wrapper}>
                  <Box className={css.icon_wrapper}>
                    {<info.icon size={24} color="#667085" />}
                  </Box>
                  <Box sx={{overflow:"hidden"}} >
                    <Typography className={css.customer_detail_title}>
                      {info.label ?? ""}
                    </Typography>
                    <Typography className={css.customer_detail_description}>
                      {info.info ?? ""}
                    </Typography>
                  </Box>
                </Stack>
              )
            })}
          </Box>
        </Grid>

        {/** -------- customer products transactions history -------- **/}
        <Grid item xl={7.5} lg={7.5} md={7.5} sm={7.5} xs={12}>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={{
              xl: "nowrap",
              lg: "nowrap",
              md: "nowrap",
              sm: "nowrap",
              xs: "wrap",
            }}
            gap={2}
            mb={2}
          >
            {states_arr.map((item, i) => {
              const cart_clr = item.icon === cart

              return (
                <Grid
                  key={i}
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={css.customer_states_wrapper}
                >
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{
                      backgroundColor: cart_clr ? "#CFE7DC" : "#FAE1CF",
                      boxShadow: `0px 0px 7px 6px ${
                        cart_clr ? "#E7F4EE" : "#FDF1E8"
                      }`,
                    }}
                    className={css.image}
                  />
                  <Typography className={css.states_title}>
                    {item.title}
                  </Typography>
                  <Stack
                    direction={"row"}
                    mt={0.5}
                    flexWrap={"nowrap"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Typography className={css.price}>{item.price}</Typography>
                    <Typography
                      className={css.discount}
                      sx={{
                        color: cart_clr ? "#0D894F" : "#F04438",
                        backgroundColor: cart_clr ? "#E7F4EE" : "#FEEDEC",
                      }}
                    >
                      {item.discount}
                    </Typography>
                  </Stack>
                </Grid>
              )
            })}
          </Grid>
          {/** -------- table -------- **/}
          <Box className={`${css.transaction_tble} tabled`}>
            <Stack
              direction={"row"}
              px={2}
              py={1}
              justifyContent={"space-between"}
              flexWrap={{
                xl: "nowrap",
                lg: "nowrap",
                md: "nowrap",
                sm: "wrap",
                xs: "wrap",
              }}
            >
              <Typography className={css.tbl_title}>
                Transaction History
              </Typography>

              {/* ---- select date and filter btn ---- */}

              <Stack
                width={1}
                justifyContent={"flex-end"}
                direction={"row"}
                spacing={0.5}
              >
                <CustomButton
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #E0E2E7",
                    borderRadius: "8px",
                  }}
                  onClick={() => {}}
                >
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <MdOutlineDateRange size={20} color="#667085" />
                    <Typography className={css.select_filter_btn}>
                      Select Dates
                    </Typography>
                  </Stack>
                </CustomButton>

                <CustomButton
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #E0E2E7",
                    borderRadius: "8px",
                  }}
                  onClick={() => {}}
                >
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <BiSlider size={20} color="#667085" />
                    <Typography className={css.select_filter_btn}>
                      Filters
                    </Typography>
                  </Stack>
                </CustomButton>
              </Stack>
            </Stack>
            <Box className={css.table_wrapper}>
              <ProductListTable
                // handleTabChange={handleTabChange}
                loading={loading}
                dataSource={get_product_by_user_id_data}
                columns={transaction_history_columns}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CustomerDetail
