import css from "./Negotiation.module.css"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { set_product_status } from "../../../store/slices/product_slice"
import { get_product_by_user_id_async } from "../../../store/services/productsService"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"
import { primaryColour } from "../../../utils/colorTheme"
import { IoIosArrowBack } from "react-icons/io"
import Custom_Modal from "../../../components/common/CustomModel/CustomModel"
import Input from "../../../components/common/Input/Input"
import {
  add_counter_offer_negotiation_async,
  get_negotiation_request_by_id_async,
  negotiation_actions_response_async,
  negotiation_counter_offer_refresh_async,
  negotiation_request_list_async,
} from "../../../store/services/negotiationServices"
import { asyncStatus } from "../../../utils/asyncStatus"
import { TbRefresh } from "react-icons/tb"
import { place_order_async } from "../../../store/services/ordersService"
import { DateAndTimeFormat } from "../../../utils/common_functions/date_format"
import { useNavigate } from "react-router-dom"

const ProductComponent = (props) => {
  const { product_preview_image, negotiation_no, number_of_items } = props
  return (
    <Stack spacing={1} direction={"row"}>
      <Stack
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#EFF0EB",
          height: 44,
          width: 44,
          objectFit: "contain",
          p: "4px",
        }}
      >
        <img
          height={"100%"}
          width={"100%"}
          src={product_preview_image ?? ""}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>
          #{negotiation_no ?? ""}
        </Typography>
        <Typography className={css.products_qty}>
          {number_of_items > 1 &&
            `+${number_of_items - 1 ?? ""} other products`}
        </Typography>
      </Stack>
    </Stack>
  )
}

const MessageBoxComponent = (
  offerLabel,
  offerAmount,
  timeStamps,
  chatBoxPosition,
) => {
  const isMe = offerLabel === ": Me"
  const isAccepted = offerAmount === "accepted"
  const isRejected = offerAmount === "rejected"
  return (
    <Stack alignItems={chatBoxPosition} justifyContent={"center"} py={0.5}>
      <Stack
        sx={{
          border: `1px solid  ${
            isAccepted ? "lightgreen" : isRejected ? "#ffaaa5" : "#e3e0e0"
          }`,
          borderRadius: "10px",
        }}
        direction={isMe ? "row-reverse" : "row"}
        alignItems={"center"}
        justifyContent={"center"}
        py={1}
        px={1}
      >
        <Typography
          className={css.products_qty}
          sx={{
            whiteSpace: "nowrap",
            fontSize: "11px !important",
            lineHeight: "15px !important",
          }}
        >
          {offerLabel}&nbsp;
        </Typography>
        <Typography
          className={css.products_qty}
          sx={{
            whiteSpace: "nowrap",
            fontSize: "13px !important",
            color: isAccepted
              ? "green"
              : isRejected
              ? "#F04438"
              : primaryColour,
            lineHeight: "15px !important",
          }}
        >
          {!isAccepted && !isRejected && "$"} {offerAmount} &nbsp;
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} gap={2} px={1}>
        <Typography
          className={css.products_qty}
          sx={{
            whiteSpace: "nowrap",
            fontSize: "10px !important",
            lineHeight: "15px !important",
          }}
        >
          {DateAndTimeFormat(timeStamps)[0]}
        </Typography>

        <Typography
          className={css.products_qty}
          sx={{
            whiteSpace: "nowrap",
            fontSize: "10px !important",
            lineHeight: "15px !important",
          }}
        >
          {DateAndTimeFormat(timeStamps)[1]}
        </Typography>
      </Stack>
    </Stack>
  )
}

const Negotiations = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [inner_width, setCurrentWidth] = useState(window.innerWidth)
  const [negodetails, setNegodetails] = useState(false)
  const [showAll, SetShowAll] = useState(false)
  const [offerAmount, setOfferAmount] = useState({})
  const [openModal, setOpenModal] = useState({
    counterOffer: false,
    acceptedOffer: false,
    rejectedOffer: false,
    orderNow: false,
  })

  const {
    negotiation_request_list_data,
    negotiation_request_list_status,
    negotiation_request_by_id_data,
    negotiation_request_by_id_status,
    negotiation_counter_offer_refresh_status,
    negotiation_actions_response_status,
  } = useSelector((state) => state.negotiation_slice)

  const { userData } = useSelector((state) => state.auth)

  const negotiationTableLoader =
    negotiation_request_list_status === asyncStatus.LOADING
  const negotiationIdProductLoader =
    negotiation_request_by_id_status === asyncStatus.LOADING
  const negotiationCounterRefreshLoader =
    negotiation_counter_offer_refresh_status === asyncStatus.LOADING
  const negotiationActionsResponseLoader =
    negotiation_actions_response_status === asyncStatus.LOADING

  useEffect(() => {
    const handleInnerWidth = () => {
      setCurrentWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleInnerWidth)
    return () => window.addEventListener("resize", handleInnerWidth)
  }, [inner_width])

  const handleViewDetail = (id) => {
    setNegodetails(true)
    dispatch(get_negotiation_request_by_id_async(id))
  }

  const columns = [
    {
      title: "Product",
      dataIndex: "",
      render: (props) => {
        return (
          <>
            <ProductComponent {...props} />
          </>
        )
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (date) => (
        <Typography className={css.date}>{date.split("T")[0] ?? ""}</Typography>
      ),
    },
    {
      title: "Seller",
      dataIndex: "other_person_name",
      render: (customer) => {
        return (
          <>
            <Typography className={css.customer_name}>
              {customer ?? ""}
            </Typography>
          </>
        )
      },
    },
    {
      title: "Total",
      dataIndex: "total_estimated_cost",
      render: (total) => (
        <Typography className={css.amount}>
          $ {total.toLocaleString() ?? ""}
        </Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        // pending
        // accept
        // reject
        const statustxtColor =
          status === "pending"
            ? "#E46A11"
            : status === "accepted"
            ? "#0D894F"
            : "#F04438"
        const statusbgColor =
          status === "pending"
            ? "#FDF1E8"
            : status === "accepted"
            ? "#E7F4EE"
            : "#FEEDEC"
        return (
          <Typography
            className={css.status}
            sx={{
              backgroundColor: statusbgColor,
              color: statustxtColor,
            }}
          >
            {status ?? ""}
          </Typography>
        )
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (id) => {
        return (
          <Box onClick={() => handleViewDetail(id)}>
            <Typography
              className={css.actions}
              sx={{
                color: primaryColour,
              }}
            >
              View Details
            </Typography>
          </Box>
        )
      },
    },
  ]

  const _handleDescriptionLength = (descr) => {
    const des = descr.slice(0, 90)
    return `${des} ...`
  }

  useEffect(() => {
    dispatch(set_product_status())
    dispatch(get_product_by_user_id_async())
    dispatch(negotiation_request_list_async())
  }, [])

  const _handleCounterOffer = (id) => {
    setOpenModal({ ...openModal, counterOffer: false })
    dispatch(
      add_counter_offer_negotiation_async({
        ...offerAmount,
        negotiation_id: id,
      }),
    )
    setOfferAmount({})
  }

  const _handleAcceptedOffer = (id) => {
    setOpenModal({ ...openModal, acceptedOffer: false })
    dispatch(
      negotiation_actions_response_async({
        negotiation_id: id,
        action: "accepted",
      }),
    )
  }
  const _handleRejectedOffer = (id) => {
    setOpenModal({ ...openModal, rejectedOffer: false })
    dispatch(
      negotiation_actions_response_async({
        negotiation_id: id,
        action: "rejected",
      }),
    )
  }

  const displayedProducts = showAll
    ? negotiation_request_by_id_data?.products
    : negotiation_request_by_id_data?.products?.slice(0, 1)

  return (
    <Box width={inner_width < 500 ? "95%" : "100%"}>
      <Typography className={css.title}>
        {userData.user_type === "buyer"
          ? "My Negotiations"
          : "Negotiation Requests"}
      </Typography>
      {/* ---- search field and add customers btn and filter btn ---- */}
      <Grid
        container
        justifyContent={"space-between"}
        gap={0.5}
        flexWrap={"nowrap"}
      >
        <Grid
          item
          xl={negodetails ? 6 : 12}
          lg={negodetails ? 6 : 12}
          md={negodetails ? 6 : 12}
          sm={!negodetails && 12}
          xs={!negodetails && 12}
          alignItems={"center"}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: negodetails && "none",
              xs: negodetails && "none",
            },
          }}
        >
          {/** ------- customers table ------- **/}
          <Box className={`${css.table} tabled`}>
            <ProductListTable
              //   handleTabChange={handleTabChange}
              loading={negotiationTableLoader}
              //   pagination={tableParams.pagination}
              dataSource={negotiation_request_list_data}
              columns={columns}
            />
          </Box>
        </Grid>
        {/* ---- add customer btn and filter ---- */}
        <Grid
          item
          xl={negodetails && 6}
          lg={negodetails && 6}
          md={negodetails && 6}
          sm={negodetails && 12}
          xs={negodetails && 12}
          sx={{
            display: {
              xl: negodetails ? "block" : "none",
              lg: negodetails ? "block" : "none",
              md: negodetails ? "block" : "none",
              sm: !negodetails && "none",
              xs: !negodetails && "none",
            },
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              border: "1px solid #e0e2e7",
              borderRadius: "8px",
              margin: "20px 0px",
            }}
          >
            {negotiationIdProductLoader ? (
              <Stack
                sx={{ height: "50vh" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress size={20} sx={{ color: primaryColour }} />
              </Stack>
            ) : (
              <>
                <Stack
                  alignItems={"center"}
                  direction={"row"}
                  sx={{
                    backgroundColor: "#fafafa",
                    borderBottom: "1px solid #e3e0e0",
                  }}
                  p={1}
                >
                  <IconButton onClick={() => setNegodetails(false)}>
                    <IoIosArrowBack />
                  </IconButton>

                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.88)",
                      fontWeight: "600",
                      fontSize: "15px",
                      textAlign: "start",
                    }}
                  >
                    #{negotiation_request_by_id_data?.negotiation_no}
                  </Typography>
                </Stack>

                {/**   <!---- products detail section ----!>   **/}

                <Typography
                  sx={{
                    color: "#636363e0",
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "start",
                    backgroundColor: "#fafafa",
                    px: 2,
                    py: 1,
                    borderBottom: "1px solid #e3e0e0",
                  }}
                >
                  Products
                </Typography>

                <Box className={css.product_detail_list}>
                  {displayedProducts?.map(
                    ({
                      image_preview,
                      description,
                      category: { category, sub_category },
                      title,
                      quantity,
                      price,
                      product_id,
                    }) => {
                      return (
                        <Box key={product_id}>
                          <Stack
                            flex={"wrap"}
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={5}
                            pt={1}
                            key={product_id}
                          >
                            <Stack
                              spacing={1}
                              flex={"nowrap"}
                              direction={"row"}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "8px",
                                  overflow: "hidden",
                                  backgroundColor: "#EFF0EB",
                                  height: 60,
                                  width: 60,
                                  objectFit: "contain",
                                  p: "4px",
                                }}
                              >
                                <img
                                  height={"100%"}
                                  width={"100%"}
                                  src={image_preview ?? ""}
                                  alt="product_image"
                                />
                              </Stack>
                              <Stack justifyContent={"space-around"}>
                                <Typography
                                  className={css.products_qty}
                                  sx={{
                                    fontSize: "10px !important",
                                    whiteSpace: "wrap",
                                    lineHeight: "12px !important",
                                  }}
                                >
                                  {/* {category} - */} {sub_category}
                                </Typography>
                                <Typography className={css.image_title}>
                                  {title ?? ""}
                                </Typography>
                                <Typography
                                  className={css.products_qty}
                                  sx={{ fontSize: "10px !important" }}
                                >
                                  Qty:{" "}
                                  {`${quantity} x ${price.toLocaleString()}` ??
                                    ""}
                                </Typography>
                              </Stack>
                            </Stack>

                            <Stack
                              alignItems={"flex-end"}
                              justifyContent={"space-between"}
                              height={1}
                            >
                              <Typography
                                className={css.products_qty}
                                sx={{ fontSize: "10px !important" }}
                              >
                                {negotiation_request_by_id_data.createdAt.split(
                                  "T",
                                )[0] ?? ""}
                              </Typography>
                              <Stack
                                alignItems={"flex-end"}
                                justifyContent={"flex-end"}
                              >
                                <Typography
                                  className={css.products_qty}
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "10px !important",
                                  }}
                                >
                                  actual price
                                </Typography>
                                <Typography
                                  className={css.products_qty}
                                  sx={{
                                    color: primaryColour,
                                    fontSize: "15px !important",
                                    fontWeight: "600 !important",
                                  }}
                                >
                                  $ {price.toLocaleString()}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                          <Typography
                            className={css.products_qty}
                            sx={{
                              whiteSpace: "wrap",
                              pt: 1,
                              pb: 1,
                              fontSize: "11px !important",
                              lineHeight: "15px !important",
                            }}
                          >
                            {_handleDescriptionLength(description)}
                          </Typography>
                        </Box>
                      )
                    },
                  )}
                </Box>

                {negotiation_request_by_id_data?.products?.length > 1 && (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mx={2}
                    my={1}
                  >
                    {!showAll && (
                      <Box
                        sx={{ borderBottom: "1px solid #e3e0e0", width: "10%" }}
                      ></Box>
                    )}
                    <Typography
                      className={css.products_qty}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "11px !important",
                        lineHeight: "15px !important",
                      }}
                    >
                      {!showAll &&
                        `${
                          negotiation_request_by_id_data?.products?.length - 1
                        } other products`}
                    </Typography>
                    <Box
                      sx={{
                        borderBottom: "1px solid #e3e0e0",
                        width: showAll ? "80%" : "40%",
                      }}
                    ></Box>
                    <Typography
                      className={css.products_qty}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "11px !important",
                        lineHeight: "15px !important",
                        color: `${primaryColour} !important`,
                        cursor: "pointer",
                      }}
                      onClick={() => SetShowAll(!showAll)}
                    >
                      {showAll ? "Show less" : "Show all"}
                    </Typography>
                  </Stack>
                )}
                {negotiation_request_by_id_data?.products?.length > 1 && (
                  <Box
                    sx={{
                      width: "70%",
                      mb: 1,
                      borderBottom: "2px dashed #e3e0e0",
                      mx: "auto",
                    }}
                  ></Box>
                )}

                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  mx={2}
                >
                  <Stack alignItems={"center"} gap={0.5} mb={1}>
                    <Typography
                      className={css.products_qty}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "11px !important",
                        lineHeight: "15px !important",
                      }}
                    >
                      Total Estimated Cost
                    </Typography>
                    <Typography
                      className={css.products_qty}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "14px !important",
                        lineHeight: "15px !important",
                      }}
                    >
                      ${" "}
                      {negotiation_request_by_id_data?.total_estimated_cost?.toLocaleString()}
                    </Typography>
                  </Stack>
                  <Stack alignItems={"center"} gap={0.5}>
                    <Typography
                      className={css.products_qty}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "11px !important",
                        lineHeight: "15px !important",
                      }}
                    >
                      Number of products
                    </Typography>
                    <Typography
                      className={css.products_qty}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "14px !important",
                        lineHeight: "15px !important",
                      }}
                    >
                      {negotiation_request_by_id_data?.products?.length}
                    </Typography>
                  </Stack>
                </Stack>

                {/**   <!---- offer and chart section ----!>   **/}

                <Stack
                  sx={{
                    backgroundColor: "#fafafa",
                    px: 2,
                    py: 1,
                    borderBottom: "1px solid #e3e0e0",
                    borderTop: "1px solid #e3e0e0",
                  }}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      color: "#636363e0",
                      fontSize: "14px",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    Offers
                  </Typography>
                  <IconButton
                    onClick={() =>
                      dispatch(
                        negotiation_counter_offer_refresh_async(
                          negotiation_request_by_id_data?._id,
                        ),
                      )
                    }
                    sx={{
                      color: primaryColour,
                      fontSize: "20px",
                    }}
                    disabled={negotiationCounterRefreshLoader}
                  >
                    <TbRefresh
                      id={negotiationCounterRefreshLoader && "spinner"}
                    />
                  </IconButton>
                </Stack>

                <Box className={css.product_detail_list} px={2} py={1}>
                  {MessageBoxComponent(
                    `${
                      userData.user_type === "buyer"
                        ? ": Me"
                        : "Offered by buyer:"
                    }`,
                    negotiation_request_by_id_data?.initial_offered_amount,
                    negotiation_request_by_id_data?.createdAt,
                    `${
                      userData.user_type === "buyer" ? "flex-end" : "flex-start"
                    }`,
                  )}

                  {negotiation_request_by_id_data?.counter_offers?.map(
                    (
                      {
                        amount,
                        counter_offer_by,
                        createdAt,
                        negotiation_id,
                        type,
                        action,
                      },
                      i,
                    ) => {
                      const is_other_person =
                        counter_offer_by !== userData.user_type

                      const message =
                        type === "counter"
                          ? amount?.toLocaleString()
                          : type === "action"
                          ? action
                          : ""

                      return (
                        <Box key={i}>
                          {is_other_person
                            ? MessageBoxComponent(
                                `Offered by ${counter_offer_by}: `,
                                message,
                                createdAt,
                                "flex-start",
                              )
                            : MessageBoxComponent(
                                `: Me`,
                                message,
                                createdAt,
                                "flex-end",
                              )}
                        </Box>
                      )
                    },
                  )}
                </Box>
                {/* <!-------------- BUTTONS OF COUNTER OFFER ,REJECT,ORDER ACCEPT --------------!> */}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={1}
                  px={2}
                  py={1}
                >
                  <Button
                    variant="outlined"
                    className={css.products_qty}
                    sx={{
                      py: 1,
                      whiteSpace: "wrap",
                      fontSize: "10px !important",
                      textTransform: "capitalize",
                      lineHeight: "15px !important",
                      borderColor: primaryColour,
                      color: primaryColour,
                      "&:hover": {
                        borderColor: primaryColour,
                        color: primaryColour,
                      },
                    }}
                    disabled={
                      !negotiation_request_by_id_data?.validations?.can_accept
                    }
                    onClick={() =>
                      setOpenModal({ ...openModal, acceptedOffer: true })
                    }
                  >
                    Accept offer
                  </Button>

                  <Button
                    variant="outlined"
                    className={css.products_qty}
                    sx={{
                      py: 1,
                      whiteSpace: "wrap",
                      fontSize: "10px !important",
                      textTransform: "capitalize",
                      lineHeight: "15px !important",
                      borderColor: primaryColour,
                      color: primaryColour,
                      "&:hover": {
                        borderColor: primaryColour,
                        color: primaryColour,
                      },
                    }}
                    disabled={
                      !negotiation_request_by_id_data?.validations?.can_reject
                    }
                    onClick={() =>
                      setOpenModal({ ...openModal, rejectedOffer: true })
                    }
                  >
                    Reject offer
                  </Button>

                  <Button
                    variant="outlined"
                    className={css.products_qty}
                    sx={{
                      py: 1,
                      whiteSpace: "wrap",
                      fontSize: "10px !important",
                      textTransform: "capitalize",
                      lineHeight: "15px !important",
                      borderColor: primaryColour,
                      color: primaryColour,
                      "&:hover": {
                        borderColor: primaryColour,
                        color: primaryColour,
                      },
                    }}
                    onClick={() =>
                      setOpenModal({ ...openModal, counterOffer: true })
                    }
                    disabled={
                      !negotiation_request_by_id_data?.validations?.can_counter
                    }
                  >
                    Counter Offer
                  </Button>

                  <Button
                    variant="outlined"
                    className={css.products_qty}
                    sx={{
                      py: 1,
                      whiteSpace: "wrap",
                      fontSize: "10px !important",
                      textTransform: "capitalize",
                      lineHeight: "15px !important",
                      borderColor: primaryColour,
                      display: userData.user_type === "seller" && "none",
                      color: primaryColour,
                      "&:hover": {
                        borderColor: primaryColour,
                        color: primaryColour,
                      },
                    }}
                    onClick={() => {
                      // dispatch(
                      //   place_order_async({
                      //     negotiation_id: negotiation_request_by_id_data?._id,
                      //   }),
                      // )
                      navigate("/checkout")
                    }}
                    disabled={
                      !negotiation_request_by_id_data?.validations?.can_order
                    }
                  >
                    Order Now
                  </Button>
                </Stack>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {/** <!------ COUNTER OFFER MODAL ------!> **/}
      <Custom_Modal
        title={"Offer!"}
        open={openModal.counterOffer}
        handleClose={() => setOpenModal({ ...openModal, counterOffer: false })}
        buttons={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Button
              component="label"
              variant="outlined"
              sx={{
                borderColor: primaryColour,
                color: primaryColour,
                "&:hover": {
                  borderColor: primaryColour,
                  color: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() =>
                setOpenModal({ ...openModal, counterOffer: false })
              }
            >
              Cancel
            </Button>

            <Button
              component="label"
              variant="contained"
              sx={{
                backgroundColor: primaryColour,
                "&:hover": {
                  backgroundColor: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() =>
                _handleCounterOffer(negotiation_request_by_id_data?._id)
              }
              disabled={!offerAmount.amount}
            >
              Send
            </Button>
          </Stack>
        }
      >
        <Stack
          sx={{
            width: {
              xl: "35vh",
              lg: "30vw",
              md: "30vw",
              sm: "35vw",
              xs: "55vw",
            },
          }}
        >
          <Typography
            sx={{ fontSize: "15px", fontWeight: "500", color: "grey" }}
          >
            To perform these actions.
          </Typography>
          <Input
            placeholder={"Enter offer amount"}
            type={"number"}
            value={offerAmount.amount}
            onChange={(e) =>
              setOfferAmount({ ...offerAmount, amount: e.target.value })
            }
          />
        </Stack>
      </Custom_Modal>

      {/** <!------ CONFIRMATION OF ACCEPTED OFFER MODAL ------!> **/}
      <Custom_Modal
        title={"Confirmation"}
        open={openModal.acceptedOffer}
        handleClose={() => setOpenModal({ ...openModal, acceptedOffer: false })}
        buttons={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Button
              component="label"
              variant="outlined"
              sx={{
                borderColor: primaryColour,
                color: primaryColour,
                "&:hover": {
                  borderColor: primaryColour,
                  color: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() =>
                setOpenModal({ ...openModal, acceptedOffer: false })
              }
            >
              No
            </Button>

            <Button
              component="label"
              variant="contained"
              sx={{
                backgroundColor: primaryColour,
                "&:hover": {
                  backgroundColor: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() =>
                _handleAcceptedOffer(negotiation_request_by_id_data?._id)
              }
              disabled={negotiationActionsResponseLoader}
            >
              Yes
            </Button>
          </Stack>
        }
      >
        <Stack
          sx={{
            width: {
              xl: "35vh",
              lg: "30vw",
              md: "30vw",
              sm: "35vw",
              xs: "55vw",
            },
          }}
          alignItems={"flex-start"}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", color: "grey" }}
          >
            Are you sure you want to accept this offer ?
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "500",
              border: `1px solid lightgrey`,
              borderRadius: "10px",
              padding: "10px 10px",
              color: primaryColour,
            }}
          >
            Offer : ${" "}
            {negotiation_request_by_id_data?.counter_offers[
              negotiation_request_by_id_data?.counter_offers?.length - 1
            ]?.amount?.toLocaleString() ??
              negotiation_request_by_id_data?.initial_offered_amount?.toLocaleString()}
          </Typography>
        </Stack>
      </Custom_Modal>

      {/** <!------ CONFIRMATION OF REJECTION OFFER MODAL ------!> **/}
      <Custom_Modal
        title={"Confirmation"}
        open={openModal.rejectedOffer}
        handleClose={() => setOpenModal({ ...openModal, rejectedOffer: false })}
        buttons={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Button
              component="label"
              variant="outlined"
              sx={{
                borderColor: primaryColour,
                color: primaryColour,
                "&:hover": {
                  borderColor: primaryColour,
                  color: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() =>
                setOpenModal({ ...openModal, rejectedOffer: false })
              }
            >
              No
            </Button>

            <Button
              component="label"
              variant="contained"
              sx={{
                backgroundColor: primaryColour,
                "&:hover": {
                  backgroundColor: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() =>
                _handleRejectedOffer(negotiation_request_by_id_data?._id)
              }
              disabled={negotiationActionsResponseLoader}
            >
              Yes
            </Button>
          </Stack>
        }
      >
        <Stack
          sx={{
            width: {
              xl: "35vh",
              lg: "30vw",
              md: "30vw",
              sm: "35vw",
              xs: "55vw",
            },
          }}
          alignItems={"flex-start"}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", color: "grey" }}
          >
            Are you sure you want to reject this offer ?
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "500",
              border: `1px solid lightgrey`,
              borderRadius: "10px",
              padding: "10px 10px",
              color: primaryColour,
            }}
          >
            Offer : ${" "}
            {negotiation_request_by_id_data?.counter_offers[
              negotiation_request_by_id_data?.counter_offers?.length - 1
            ]?.amount?.toLocaleString() ??
              negotiation_request_by_id_data?.initial_offered_amount?.toLocaleString()}
          </Typography>
        </Stack>
      </Custom_Modal>
    </Box>
  )
}

export default Negotiations
