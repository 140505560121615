import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const get_reviews_async = createAsyncThunk(
  type_constants.GET_REVIEWS,
  async (product_data) => {
    try {
      const response = await apiHandle.post("/user/post-review", product_data)
      console.log("response", response)
      //   return response.data
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
