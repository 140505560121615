import {
  applyMiddleware,
  combineReducers,
  compose,
  configureStore,
} from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import auth_slice from "./slices/auth_slice"
import product_slice from "./slices/product_slice"
import categories_manage_slice from "./slices/categories_mange_slice"
import cart_manage_slice from "./slices/add_cart_product"
import business_manage_slice from "./slices/business_mange_slice"
import reviews_slice from "./slices/reviews_slice"
import negotiation_slice from "./slices/negotiation_slice"
import wishlist_slice from "./slices/wishlist_slice"
import orders_slice from "./slices/orders_slice"
import address_slice from "./slices/address_slice"

let reducers = combineReducers({
  auth: auth_slice,
  product: product_slice,
  categoriesManageSlice: categories_manage_slice,
  cartManageSlice: cart_manage_slice,
  businessManageSlice: business_manage_slice,
  reviews_slice: reviews_slice,
  negotiation_slice: negotiation_slice,
  wishlist_slice: wishlist_slice,
  orders_slice: orders_slice,
  address_slice: address_slice,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = configureStore(
  { reducer: reducers },
  composeEnhancers(applyMiddleware(thunk)),
)
export default store
