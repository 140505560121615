import * as React from "react"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Avatar, Stack } from "@mui/material"
import { IoIosArrowDown } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import { exit_session } from "../../config/apiHandle/apiHandle"
import { success_toast_message } from "../../utils/toast_notification"
import { useSelector } from "react-redux"
import { user_type_constant } from "../../utils/constants"

export default function CustomPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const { userAuth, userData } = useSelector((state) => state.auth)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const submit = () => {
    exit_session()
    success_toast_message("Logout successfully")
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  return (
    <div>
      <Stack
        sx={{ cursor: "pointer" }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <IoIosArrowDown size={20} />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Typography
          onClick={() => {
            userData.user_type === user_type_constant.SELLER
              ? navigate("/seller-dashboard")
              : navigate("/dashboard")
          }}
          sx={{ p: 1, cursor: "pointer" }}
        >
          {userData.user_type === user_type_constant.SELLER
            ? "Seller Dashboard"
            : userData.user_type === user_type_constant.BUSINESS_PARTNER
            ? "Partner Dashboard"
            : "Buyer Dashboard"}
        </Typography> */}
        <Typography onClick={submit} sx={{ p: 1, cursor: "pointer" }}>
          Logout
        </Typography>
      </Popover>
    </div>
  )
}
