import React, { useState } from "react"
import { IconButton, Stack, Typography } from "@mui/material"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import css from "../../../screens/addCart/AddtoCart.module.css"
import { primaryColour } from "../../../utils/colorTheme"

const IncreDecrBtn = ({ quantity, handle_Increment, handle_decrement }) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={2}
      sx={{ borderRadius: 1 }}
      p={0.45}
    >
      <IconButton
        sx={{
          border: "1px solid lightgrey",
          color: "#7f7f7f",
          height: "15px",
          width: "15px",
          p: "10px",
          "&:hover": {
            backgroundColor: "white",
            color: primaryColour,
          },
        }}
        onClick={handle_decrement}
      >
        <RemoveIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <Typography
        sx={{ fontSize: "22px !important" }}
        className={css.secondary_txt}
      >
        {quantity}
      </Typography>
      <IconButton
        sx={{
          border: "1px solid lightgrey",
          color: "#7f7f7f",
          height: "15px",
          width: "15px",
          p: "10px",
          "&:hover": {
            backgroundColor: "white",
            color: primaryColour,
          },
        }}
        onClick={handle_Increment}
      >
        <AddIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </Stack>
  )
}

export default IncreDecrBtn
