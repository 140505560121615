const useSecureEmail = (email) => {
  return email?.replace(
    /(.)(.*)(.@.*)/,
    (match, firstChar, middleChars, lastChars) => {
      const maskedMiddle = middleChars.replace(/./g, "*")
      return firstChar + maskedMiddle + lastChars
    },
  )
}
export { useSecureEmail }
