import React from "react"
import css from "./Table.module.css"

const Table = ({ data, columnData }) => {



  return (
    <table className={css.tbl}>
      <thead className={css.tbl_head}>
        <tr>
          {columnData.map((title, index) => (
            <th key={index}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody className={css.tbl_body}>
        {data.map((row, rowIndex) => {
          return (
            <tr key={rowIndex}>
              {columnData.map((column, colIndex) => {
                const cellValue = row[column.toLowerCase().replace(/\s+/g, "")]
                const isComplete = cellValue === "COMPLETED"
                const isCancelled = cellValue === "CANCELED"
                const cellColor = isComplete
                  ? "#2db223"
                  : isCancelled
                  ? "#ee5858"
                  : ""
                return (
                  <td key={colIndex} style={{ color: cellColor }}>
                    {row[column.toLowerCase().replace(" ", "")]}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
