import AuthContainer from "../../../components/common/AuthContainer/AuthContainer"
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material"

import CustomButton from "../../../components/common/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { verify_otp_async } from "../../../store/services/authService"
import { useState } from "react"
import { useEffect } from "react"
import { asyncStatus } from "../../../utils/asyncStatus"
import OTPInput from "../../../components/common/otpInput/OTPInput"

function OTPScreen() {
  const dispatch = useDispatch()
  const { userData, otpVerify, otpStatus } = useSelector((state) => state.auth)

  const navigate = useNavigate()
  const [otpVal, setOtpVal] = useState(null)

  const loading = otpStatus === asyncStatus.LOADING

  const verifyOtpHandler = () => {
    dispatch(
      verify_otp_async({
        OTP: otpVal,
      }),
    )
  }

  useEffect(() => {
    if (otpVerify) {
      if (userData?.user_type === "seller") {
        navigate("/complete-business-profile")
      } else {
        navigate("/")
      }
    }
  }, [, otpVerify])

  return (
    <>
      {!otpVerify && (
        <Container>
          <AuthContainer leftSide={{ md: 8 }} rightSide={{ xs: 12, md: 4 }}>
            <Stack maxWidth={400} mx={"auto"} gap={4}>
              <Stack gap={1}>
                <Stack>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: {
                        xs: 40,
                        md: 40,
                      },
                    }}
                    className="heading"
                  >
                    OTP Verification
                  </Typography>
                </Stack>
                <Stack>
                  <Typography>
                    Enter the OTP you received at Email
                    {/* jo*****@gmail.com */}
                  </Typography>
                </Stack>
              </Stack>
              <Grid container rowGap={5} mt={2}>
                <Grid item xs={12}>
                  <OTPInput value={otpVal} onChange={(e) => setOtpVal(e)} />
                </Grid>

                <Grid item xs={12}>
                  <CustomButton
                    onClick={verifyOtpHandler}
                    disable={loading}
                    style={{ width: "100%", fontWeight: "bold", fontSize: 17 }}
                  >
                    {!loading ? (
                      "Verify"
                    ) : (
                      <CircularProgress color="inherit" size={17} />
                    )}
                  </CustomButton>
                </Grid>
              </Grid>
            </Stack>
          </AuthContainer>
        </Container>
      )}
    </>
  )
}

export default OTPScreen
