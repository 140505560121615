import AuthContainer from "../../../components/common/AuthContainer/AuthContainer"
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material"

import CustomButton from "../../../components/common/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useEffect, useState } from "react"
import { useSecureEmail } from "../../../hooks/CustomHooks"
import { reset_password_otp_verify_async } from "../../../store/services/authService"
import { asyncStatus } from "../../../utils/asyncStatus"
import OTPInput from "../../../components/common/otpInput/OTPInput"

const ResetPasswordOTPScreen = () => {
  const [otpVal, setOtpVal] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { reset_otp_status, resetPasswordData } = useSelector(
    (state) => state.auth,
  )

  const loader = reset_otp_status === asyncStatus.LOADING

  const verifyOtpHandler = () => {
    dispatch(
      reset_password_otp_verify_async({
        otp: otpVal,
        email: resetPasswordData?.data,
      }),
    )
  }
  useEffect(() => {
    if (reset_otp_status === asyncStatus.SUCCEEDED) {
      navigate("/reset-password")
    }
  }, [, reset_otp_status])

  const secureEmail = useSecureEmail(resetPasswordData?.data)
  return (
    <Container>
      <AuthContainer leftSide={{ md: 8 }} rightSide={{ xs: 12, md: 4 }}>
        <Stack maxWidth={400} mx={"auto"} gap={4}>
          <Stack gap={1}>
            <Stack>
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: 40,
                    md: 40,
                  },
                }}
                className="heading"
              >
                OTP Verification
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                Enter the OTP you received at {secureEmail}
              </Typography>
            </Stack>
          </Stack>
          <Grid container rowGap={5} mt={2}>
            <Grid item xs={12}>
              <OTPInput value={otpVal} onChange={(e) => setOtpVal(e)} />
            </Grid>

            <Grid item xs={12}>
              <CustomButton
                onClick={verifyOtpHandler}
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: 17,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: otpVal === null && "lightgrey",
                }}
                disable={otpVal === null}
              >
                {loader ? (
                  <CircularProgress size={22} sx={{ color: "white" }} />
                ) : (
                  "Verify"
                )}
              </CustomButton>
            </Grid>
          </Grid>
        </Stack>
      </AuthContainer>
    </Container>
  )
}

export default ResetPasswordOTPScreen
