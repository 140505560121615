import { Box, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import css from "./ProductsList.module.css"
import CustomButton from "../../../components/common/Button/Button"
import { BiExport } from "react-icons/bi"
import { FiPlus } from "react-icons/fi"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { Input } from "antd"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"
import { useState } from "react"
import { useEffect } from "react"
import { FiTrash2 } from "react-icons/fi"
import { LuPencil } from "react-icons/lu"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { set_product_status } from "../../../store/slices/product_slice"
import {
  delete_product_async,
  get_product_by_product_id_async,
  get_product_by_user_id_async,
  search_product_for_user_async,
} from "../../../store/services/productsService"
import { RxCross1 } from "react-icons/rx"
import { primaryColour } from "../../../utils/colorTheme"
import { asyncStatus } from "../../../utils/asyncStatus"

const ProductComponent = (props) => {
  const { images, title, description, feature_image } = props
  return (
    <Stack spacing={1} direction={"row"} className={css.product_list_wrapper}>
      <Stack
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#EFF0EB",
          height: 44,
          width: 44,
          objectFit: "contain",
          p: "4px",
        }}
      >
        <img
          height={"100%"}
          width={"100%"}
          src={images[feature_image] ?? ""}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>{title ?? ""}</Typography>
        {/* <Typography className={css.image_description}>
          {description ?? ""}
        </Typography> */}
      </Stack>
    </Stack>
  )
}

const ProductsList = () => {
  const dispatch = useDispatch()
  const [searchData, setSearchData] = useState()
  // const [tableParams, setTableParmas] = useState({
  //   pagination: {
  //     current: 1,
  //     pageSize: 10,
  //     total: staticUserData?.length,
  //   },
  // })
  const navigate = useNavigate()

  const {
    get_product_by_user_id_data,
    search_product_for_user_data,
    get_product_by_user_id_status,
    search_product_for_user_status,
    get_product_by_user_id_error,
    search_product_for_user_error,
    delete_product_status,
  } = useSelector((state) => state.product)

  const edit_func = (elm) => {
    navigate(`/seller-dashboard/productslist/editProduct/${elm._id}`)
  }

  const getProductsLoader =
    search_product_for_user_status === asyncStatus.LOADING

  const columns = [
    {
      title: "Product",
      dataIndex: "",
      render: (props) => {
        return (
          <>
            <ProductComponent {...props} />
          </>
        )
      },
      width: "20%",
    },
    {
      title: "Category",
      dataIndex: "category_title",
      render: (category) => {
        return (
          <Typography className={css.category}>{category ?? ""}</Typography>
        )
      },
      width: "10%",
    },
    {
      title: "Stock",
      dataIndex: "in_stock",
      render: (stock) => (
        <Typography className={css.stock}>
          {stock ? "Available" : "Out of Stock" ?? ""}
        </Typography>
      ),
      width: "3%",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => (
        <Typography className={css.price}>{price ?? ""}</Typography>
      ),
      width: "10%",
    },
    {
      title: "Added",
      dataIndex: "updatedAt",
      render: (date) => (
        <Typography className={css.added}>
          {date?.split("T")[0] ?? ""}
        </Typography>
      ),
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "",
      render: (data) => {
        return (
          <Box className={css.actions_css}>
            <MdOutlineRemoveRedEye
              onClick={() => {
                navigate(`/product/${data._id}`)
              }}
              className={css.icon}
            />
            <LuPencil onClick={() => edit_func(data)} className={css.icon} />{" "}
            <FiTrash2
              className={css.icon}
              onClick={() => {
                dispatch(delete_product_async({ product_id: data._id }))
              }}
            />
          </Box>
        )
      },
      width: "15%",
    },
  ]

  useEffect(() => {
    dispatch(set_product_status())
    dispatch(search_product_for_user_async({ title: "" }))
  }, [delete_product_status])

  const clearFunc = () => {
    setSearchData()
    dispatch(search_product_for_user_async({ title: "" }))
  }

  return (
    <Box width={1}>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          alignItems={"center"}
          width={{ xl: 0.7, lg: 0.7, md: 0.7, sm: 0.7, xs: 1 }}
        >
          <Typography className={css.title}>Products</Typography>
        </Grid>
        {/* <!------ EXPORT BUTTON AND ADD PRODUCT ------!> */}
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <CustomButton
              style={{
                backgroundColor: "#FFF8EE",
                border: "1px solid #FFF8EE",
                borderRadius: "8px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <BiExport size={20} color="#FF9900" />
                <Typography className={css.export_add_btn}>Export</Typography>
              </Stack>
            </CustomButton>

            <CustomButton
              style={{
                border: "1px solid #FF9900",
                borderRadius: "8px",
              }}
              onClick={() => {
                navigate("/seller-dashboard/productslist/addproducts")
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FiPlus size={20} />
                <Typography
                  className={css.export_add_btn}
                  sx={{ color: "white" }}
                >
                  Add Product
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>

      {/* ---- search field and select fated btn and filter btn ---- */}

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} alignItems={"center"}>
          <Input
            className={css.input}
            size="large"
            placeholder="Search product. . ."
            onChange={(e) => {
              setSearchData(e.target.value)
            }}
            value={searchData}
            prefix={
              searchData ? (
                <RxCross1
                  style={{ cursor: "pointer" }}
                  onClick={clearFunc}
                  color={primaryColour}
                  size={16}
                />
              ) : (
                ""
              )
            }
            suffix={
              <HiOutlineMagnifyingGlass
                onClick={() => {
                  dispatch(search_product_for_user_async({ title: searchData }))
                }}
                style={{ cursor: "pointer" }}
                size={22}
                color="#FA8232"
              />
            }
          />
        </Grid>
        {/* ---- export button and add product ---- */}
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            {/* <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => { }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MdOutlineDateRange size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Select Dates
                </Typography>
              </Stack>
            </CustomButton> */}

            {/* <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => { }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <BiSlider size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Filters
                </Typography>
              </Stack>
            </CustomButton> */}
          </Stack>
        </Grid>
      </Grid>
      <Box
        className={`${css.table} tabled`}
        width={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 0.95 }}
      >
        <ProductListTable
          // handleTabChange={handleTabChange}
          loading={getProductsLoader}
          // pagination={tableParams.pagination}
          dataSource={search_product_for_user_data}
          columns={columns}
        />
        {/* <CustomModal
          // closeCondition={category}
          Btnlabel={"modalBtnLabel"}
          heading={"Add Category"}
          funcOpen={editModal}
          icon={<RxCross1 style={{ cursor: "pointer", justifyContent: "flex-end" }} onClick={() => setEditModal(false)} color={primaryColour} size={16} />}
        >
          <Stack gap={1}>
            {editData?.description ?
              <TextFieldLabel
                // inputTitle={"Base Price"} 
                placeholder={"Type Description here. . ."}
                // onChange={(e) => onChange_handle("price", e.target.value)}
                disabled={false}
                error={"Please provide price!"}
                type={"text"}
                style={fields_style_obj}

              /> : null
            }
            {editData?.title ?
              <TextFieldLabel
              // inputTitle={"Base Price"} 
              placeholder={"Type Title here. . ."}
              // onChange={(e) => onChange_handle("price", e.target.value)}
              disabled={false}
              error={"Please provide price!"}
              type={"text"}
              style={fields_style_obj}

            /> : null
            }
            {editData?.features ?
              <TextFieldLabel
              // inputTitle={"Base Price"} 
              placeholder={"Type Title here. . ."}
              // onChange={(e) => onChange_handle("price", e.target.value)}
              disabled={false}
              error={"Please provide price!"}
              type={"text"}
              style={fields_style_obj}

            /> : null
            }
            
            <Stack alignItems={"flex-end"}>
              <Button
                onClick={() => setEditModal(false)}
                // disabled={loading}
                color="warning"
                variant="contained"
              >
                Edit
              </Button>
            </Stack>
          </Stack>
        </CustomModal> */}
      </Box>
    </Box>
  )
}

export default ProductsList
