import React, { useState } from "react"
import { Button, Stack, Typography } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Input from "../common/Input/Input"
import RangeInput from "../rangeInput/RangeInput"
import CustomCheckbox from "../common/customCheckbox/CustomCheckbox"
import StarRating from "../StarRating/StarRating"
import { primaryColour } from "../../utils/colorTheme"
import CustomButton from "../common/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { get_all_sub_categories_async } from "../../store/services/categoriesService"
import { get_all_products_async } from "../../store/services/productsService"
import { FaCheck } from "react-icons/fa6"
import { Rate } from "antd"
import { SAVE_CATEGORY_ID } from "../../store/constant"

function BasicAccordion({ label, children }) {
  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          sx={{ fontSize: "16px", fontWeight: "600", color: "#615e5e" }}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

const CustomDrawer = () => {
  const dispatch = useDispatch()
  const [value, setValue] = useState([0, 100])
  const [ratingvalue, setratingValue] = useState()
  const [isCheckedId, setIsCheckedId] = useState([])
  const [isCheckedColor, setIsCheckedColor] = useState([])

  const { data } = useSelector((state) => state.categoriesManageSlice)

  useEffect(() => {
    dispatch(get_all_sub_categories_async())
  }, [])

  const submitValue = (e, val = value) => {
    dispatch(
      get_all_products_async({
        data: { price: { min: val[0], max: val[1] } },
      }),
    )
  }

  const toggleCheckbox = (elm) => {
    const isIdSelected = isCheckedId.includes(elm._id)

    let selected_list = []

    if (isIdSelected) {
      selected_list = isCheckedId.filter((id) => id !== elm._id)
    } else {
      selected_list = [...isCheckedId, elm._id]
    }
    setIsCheckedId([...selected_list])
    dispatch(
      get_all_products_async({ data: { sub_category: [...selected_list] } }),
    )
  }

  // useEffect(() => {
  //   dispatch(get_all_products_async({ data: { sub_category: isCheckedId } }))
  // }, [isCheckedId])

  const handleChange = (event, newValue) => {
    setValue((prev) => newValue)
  }

  const handleChangeForRating = (e) => {
    dispatch(get_all_products_async({ data: { rating: e } }))
    setratingValue(e)
  }

  const handleChangeForColor = (elm, ind) => {
    const isIdSelected = isCheckedColor.includes(ind)

    if (isIdSelected) {
      setIsCheckedColor(isCheckedColor.filter((id) => id !== ind))
    } else {
      setIsCheckedColor((prev) => [...isCheckedColor, ind])
    }

    dispatch(get_all_products_async({ data: { color: [elm] } }))
  }

  const clearFilter = () => {
    setValue([0, 100])
    setIsCheckedColor([])
    setIsCheckedId([])
    setratingValue(0)
    dispatch(get_all_products_async({ page: 1 }))
    localStorage.removeItem(SAVE_CATEGORY_ID)
  }

  const colorArray = [
    "red",
    "yellow",
    "blue",
    "black",
    "grey",
    "pink",
    "purple",
  ]

  const desc = ["One", "Two", "Three", "Four", "Five"]

  return (
    <Stack
      sx={{
        background: "#FFF",
        boxShadow: ` 0px 4px 4px 0px rgba(0, 0, 0, 0.25)`,
      }}
    >
      <Stack mt={2} alignItems={"center"} spacing={1} p={3}>
        <Typography fontWeight={"bold"}>Filters</Typography>
        <Button
          sx={{
            background: "none",
            border: "1px solid black",
            borderRadius: "53.4px",
            color: "gray",
            width: "100%",
          }}
          onClick={clearFilter}
        >
          Clear Filter
        </Button>
      </Stack>

      <Stack>
        <BasicAccordion label={"Sub Categories"}>
          <Stack gap={2}>
            {data?.map((e) => {
              return (
                <Stack key={e._id} gap={1} direction={"row"}>
                  <CustomCheckbox
                    key={e._id}
                    isChecked={isCheckedId.includes(e._id)}
                    toggleCheckbox={() => toggleCheckbox(e)}
                    fill={true}
                  />
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      fontFamily: "inherit",
                      color: "#979797",
                      lineHeight: "17px",
                    }}
                  >
                    {e.sub_category_title}
                  </Typography>
                </Stack>
              )
            })}
          </Stack>
        </BasicAccordion>
      </Stack>
      <Stack spacing={1}>
        <Stack pl={2}>
          <Typography>Price </Typography>
        </Stack>
        <Stack pl={1.8} direction={"row"} alignItems={"center"} gap={1}>
          <Stack direction={"row"} alignItems={"center"} gap={0.2}>
            <Typography>Min</Typography>
            <Input
              style={{ width: "55px", margin: 3 }}
              value={value[0]}
              type={"number"}
              onChange={(e) => {
                setValue((prevValue) => {
                  submitValue("", [e.target.value, prevValue[1]])
                  return [e.target.value, prevValue[1]]
                })
              }}
            />
          </Stack>

          <Stack direction={"row"} alignItems={"center"} gap={0.2}>
            <Typography>Max</Typography>
            <Input
              value={value[1]}
              type={"number"}
              style={{ width: "60px", margin: 3 }}
              onChange={(e) => {
                setValue((prevValue) => {
                  submitValue("", [prevValue[0], e.target.value])
                  return [prevValue[0], e.target.value]
                })
              }}
            />
          </Stack>
        </Stack>
        <Stack px={2}>
          <RangeInput
            onChangeCommitted={submitValue}
            value={value}
            onChange={handleChange}
          />
        </Stack>

        <BasicAccordion label={"Color Family"}>
          <Stack gap={2} direction={"row"} flexWrap={"wrap"}>
            {colorArray.map((e, i) => {
              return (
                <>
                  <Stack
                    onClick={() => handleChangeForColor(e, i)}
                    gap={1}
                    direction={"row"}
                  >
                    {/* <CustomCheckbox
                      isChecked={isCheckedColor.includes(i)}
                      isUnCheckedColor={e}
                      fill={true}
                    /> */}

                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        height: "20px",
                        width: "20px",
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                        backgroundColor: e,
                        cursor: "pointer",

                        overflow: "hidden",
                      }}
                    ></Stack>
                    <Stack alignItems={"center"} justifyContent={"center"}>
                      <FaCheck
                        style={{
                          fontSize: "18px",
                          color: isCheckedColor.includes(i)
                            ? primaryColour
                            : "white",
                        }}
                      />
                    </Stack>
                  </Stack>
                </>
              )
            })}
          </Stack>
        </BasicAccordion>
      </Stack>

      <Stack p={2} mb={2}>
        <Stack mb={2}>
          <Typography>Rating </Typography>
        </Stack>

        <Rate
          tooltips={desc}
          onChange={(event) => handleChangeForRating(event)}
          value={ratingvalue}
          style={{ fontSize: "25px" }}
        />
      </Stack>
      <Stack bgcolor={primaryColour}>
        <Stack p={3}>
          <Typography
            textAlign={"center"}
            fontWeight={"bold"}
            color={"white"}
            variant="p"
          >
            Want to see your stuff here
          </Typography>
          <Typography
            textAlign={"center"}
            color={"white"}
            fontSize={12}
            variant="span"
          >
            Make some extra cash by selling things in your community. Go on,
            it's quick and easy.
          </Typography>
          <Stack my={2}>
            <CustomButton
              style={{ backgroundColor: "white", color: primaryColour }}
              children={"Start Selling"}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CustomDrawer
