import React from "react"
import { Container, Grid, Typography } from "@mui/material"
import visa_img from "../../../assets/visa_logo.png"
import master_img from "../../../assets/master_logo.png"
import css from "./FooterEnd.module.css"

const FooterEnd = () => {
  return (
    <Container  >
      <Grid container className={css.footer_end_wrapper}>
        <Grid
          item
          container
          justifyContent={"start"}
          alignItems={"center"}
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          my={{
            xl: "0",
            lg: "0",
            md: "0",
            xs: "10px",
          }}
        >
          <Typography>© <span>Sokolingo</span> 2023 - All Rights Reserved</Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent={{
            xl: "end",
            lg: "end",
            md: "end",
            sm:"end",
            xs: "start",
          }}
          alignItems={"center"}
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          height={1}
          my={{
            xl: "0",
            lg: "0",
            md: "0",
            xs: "10px",
          }}
        >
          <img
            height="100%"
            object-fit="contain"
            src={visa_img}
            alt="visa_logo"
          />
          <img
            height="100%"
            object-fit="contain"
            src={master_img}
            alt="master_logo"
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default FooterEnd
