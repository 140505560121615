import css from "./Setting.module.css"
import {
  Button,
  CircularProgress,
  Grid,
  Input,
  Stack,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useLayoutEffect, useState } from "react"
import Avatar from "@mui/material/Avatar"
import CustomButton from "../../../components/common/Button/Button"
import TextFieldLabel from "../../../components/common/TextField/TextField"
import SelectField from "../../../components/common/SelectField/SelectField"
import { useDispatch, useSelector } from "react-redux"
import {
  add_buyer_billing_address_async,
  add_buyer_shipping_address_async,
  get_buyer_address_async,
} from "../../../store/services/addressService"
import { asyncStatus } from "../../../utils/asyncStatus"
import { IoIosCamera } from "react-icons/io"
import { primaryColour } from "../../../utils/colorTheme"
import { apiHandle } from "../../../config/apiHandle/apiHandle"
import {
  buyer_avatar_image_async,
  check_auth_async,
} from "../../../store/services/authService"

const Setting = () => {
  const [billingData, setBillingData] = useState({})
  const [shippingData, setShippingData] = useState({})
  const [imageLoader, setImageLoader] = useState(false)
  const [imageUrl, setImageURL] = useState({})

  const dispatch = useDispatch()

  const { userData, check_auth_status } = useSelector((state) => state.auth)
  const { get_buyer_address_data, get_buyer_address_status } = useSelector(
    (state) => state.address_slice,
  )

  const handleAddressChange = (key, id, value) => {
    if (key === "billing_address") {
      setBillingData({ ...billingData, [id]: value })
    } else {
      setShippingData({ ...shippingData, [id]: value })
    }
  }

  // BILLING ADDRESS

  const billingFields = [
    {
      title: "Name",
      value: billingData?.name,
      type: "text",
      onChange: (e) =>
        handleAddressChange("billing_address", "name", e.target.value),
      disabled: true,
    },
    {
      title: (
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ whiteSpace: "nowrap" }}
        >
          Company Name
          <Typography id={css.optional}>&nbsp;(Optional)</Typography>
        </Stack>
      ),
      value: billingData?.company_name,
      type: "text",
      onChange: (e) =>
        handleAddressChange("billing_address", "company_name", e.target.value),
    },
    {
      title: "Address",
      value: billingData?.address,
      type: "text",
      onChange: (e) =>
        handleAddressChange("billing_address", "address", e.target.value),
    },
    {
      title: "Country",
      value: billingData?.country,
      onChange: (e) => handleAddressChange("billing_address", "country", e),
      onSearch: () => {},
      optionArr: [
        { value: "South Africa", label: "South Africa" },
        { value: "USA", label: "USA" },
      ],
    },
    {
      title: "Region/State",
      value: billingData?.region_state,
      onChange: (e) =>
        handleAddressChange("billing_address", "region_state", e),
      onSearch: () => {},
      optionArr: [
        { value: "Cape Town", label: "Cape Town" },
        { value: "Johannes Burg", label: "Johannes Burg" },
        { value: "Pretoria", label: "Pretoria" },
      ],
    },
    {
      zipAddress: [
        {
          title: "City",
          value: billingData?.city,
          onChange: (e) => handleAddressChange("billing_address", "city", e),
          onSearch: () => {},
          optionArr: [
            { value: "Cape Town", label: "Cape Town" },
            { value: "Johannes Burg", label: "Johannes Burg" },
            { value: "Pretoria", label: "Pretoria" },
          ],
        },
        {
          title: (
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ whiteSpace: "nowrap" }}
            >
              Zip Code
              <Typography id={css.optional}>&nbsp;(Optional)</Typography>
            </Stack>
          ),
          value: billingData?.zipcode,
          type: "text",
          onChange: (e) =>
            handleAddressChange("billing_address", "zipcode", e.target.value),
        },
      ],
    },
    {
      title: "Email",
      value: billingData?.email,
      type: "email",
      onChange: (e) =>
        handleAddressChange("billing_address", "email", e.target.value),
      disabled: true,
    },
    {
      title: "Phone Number",
      value: billingData?.phone,
      type: "text",
      onChange: (e) =>
        handleAddressChange("billing_address", "phone", e.target.value),
      disabled: true,
    },
  ]

  // SHIPPING ADDRESS

  const shippingFields = [
    {
      title: "Name",
      value: shippingData?.name,
      type: "text",
      onChange: (e) =>
        handleAddressChange("shipping_address", "name", e.target.value),
    },
    {
      title: (
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ whiteSpace: "nowrap" }}
        >
          Company Name
          <Typography id={css.optional}>&nbsp;(Optional)</Typography>
        </Stack>
      ),
      value: shippingData?.company_name,
      type: "text",
      onChange: (e) =>
        handleAddressChange("shipping_address", "company_name", e.target.value),
    },
    {
      title: "Address",
      value: shippingData?.address,
      type: "text",
      onChange: (e) =>
        handleAddressChange("shipping_address", "address", e.target.value),
    },
    {
      title: "Country",
      value: shippingData?.country,
      onChange: (e) => handleAddressChange("shipping_address", "country", e),
      onSearch: () => {},
      optionArr: [
        { value: "South Africa", label: "South Africa" },
        { value: "USA", label: "USA" },
      ],
    },
    {
      title: "Region/State",
      value: shippingData?.region_state,
      onChange: (e) =>
        handleAddressChange("shipping_address", "region_state", e),
      onSearch: () => {},
      optionArr: [
        { value: "Cape Town", label: "Cape Town" },
        { value: "Johannes Burg", label: "Johannes Burg" },
        { value: "Pretoria", label: "Pretoria" },
      ],
    },
    {
      zipAddress: [
        {
          title: "City",
          value: shippingData?.city,
          onChange: (e) => handleAddressChange("shipping_address", "city", e),
          onSearch: () => {},
          optionArr: [
            { value: "Cape Town", label: "Cape Town" },
            { value: "Johannes Burg", label: "Johannes Burg" },
            { value: "Pretoria", label: "Pretoria" },
          ],
        },
        {
          title: (
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ whiteSpace: "nowrap" }}
            >
              Zip Code
              <Typography id={css.optional}>&nbsp;(Optional)</Typography>
            </Stack>
          ),
          value: shippingData?.zipcode,
          type: "text",
          onChange: (e) =>
            handleAddressChange("shipping_address", "zipcode", e.target.value),
        },
      ],
    },
    {
      title: "Email",
      value: shippingData?.email,
      type: "email",
      onChange: (e) =>
        handleAddressChange("shipping_address", "email", e.target.value),
    },
    {
      title: "Phone Number",
      value: shippingData?.phone,
      type: "text",
      onChange: (e) =>
        handleAddressChange("shipping_address", "phone", e.target.value),
    },
  ]

  const handleAddress = (key) => {
    if (key === "billing_address") {
      dispatch(add_buyer_billing_address_async({ address: { ...billingData } }))
    } else {
      dispatch(
        add_buyer_shipping_address_async({ address: { ...shippingData } }),
      )
    }
  }

  const _handleImage = async (e) => {
    setImageLoader(true)
    const { id, files } = e.target
    if (files.length > 0) {
      try {
        const formData = new FormData()
        formData.append("binary_file", files[0])
        const response = await apiHandle.post("/upload-files", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        setImageURL({ [id]: response?.data?.url })
        if (response && response.data.url) {
          dispatch(
            buyer_avatar_image_async({ avatar_url: response?.data?.url }),
          )
        }
        setImageLoader(false)
      } catch (error) {
        setImageLoader(false)
        console.log("ALL THE IMAGE POSTING ERRORS =====>", error)
      }
    }
  }

  useLayoutEffect(() => {
    dispatch(get_buyer_address_async())
  }, [])

  useEffect(() => {
    if (
      get_buyer_address_data &&
      get_buyer_address_status !== asyncStatus.LOADING
    ) {
      setBillingData(get_buyer_address_data?.billing_address)
      setShippingData(get_buyer_address_data?.shipping_address)
    }
  }, [get_buyer_address_data])

  return (
    <>
      {/** <!--------- USER ACCOUNT SETTINGS ---------!> **/}
      <Grid container className={css.setting_wrapper}>
        {/** ------- setting title -------- **/}

        <Box className={css.title_wrapper}>
          <Typography>ACCOUNT SETTING</Typography>
        </Box>

        {/** <!------- SETTING USER ACCOUNT ---------!> **/}

        <Grid
          container
          gap={2}
          direction={{
            xl: "row",
            lg: "row",
            md: "row",
            sm: "row",
            xs: "column",
          }}
          flexWrap={"nowrap"}
        >
          {/** <!--------- AVATAR GRID ---------!> **/}

          <Grid
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={12}
            alignSelf={{
              xl: "start",
              lg: "start",
              md: "start",
              sm: "start",
              xs: "center",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              sx={{
                borderRadius: "100%",
                border: "1px solid lightgrey",
                width: {
                  xl: "120px",
                  lg: "120px",
                  md: "100px",
                  sm: "120px",
                  xs: "100px",
                },
                height: {
                  xl: "120px",
                  lg: "120px",
                  md: "100px",
                  sm: "120px",
                  xs: "100px",
                },
              }}
            >
              <Button
                component="label"
                sx={{
                  backgroundColor: "#EFF0EB !important",
                  height: 1,
                  width: 1,
                  "&:hover": {
                    backgroundColor: "#EFF0EB !important",
                  },
                }}
              >
                {/* IMAGE */}
                <Input
                  onChange={(e) => _handleImage(e)}
                  type="file"
                  id="avatar_url"
                  sx={{
                    display: "none",
                  }}
                />

                {imageLoader || check_auth_status === asyncStatus.LOADING ? (
                  <CircularProgress
                    size={30}
                    sx={{ margin: 2, color: primaryColour }}
                  />
                ) : imageUrl?.avatar_url || userData?.profile_avatar ? (
                  <img
                    src={imageUrl.avatar_url ?? userData?.profile_avatar}
                    alt="avatar_url"
                    width={"100%"}
                    height={"100%"}
                    style={{ borderRadius: "100%" }}
                  />
                ) : (
                  <IoIosCamera style={{ fontSize: "50px", color: "white" }} />
                )}
              </Button>
            </Avatar>
          </Grid>

          {/*** <!--------- ACCOUNTS FIELDS GRID ---------!> ***/}

          <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
            <Grid
              container
              direction={{
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              }}
              justifyContent={"space-between"}
              columnGap={1}
              flexWrap={"nowrap"}
            >
              {/*** <!--------- LEFT SIDE FIELDS GRID ---------!> ***/}

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextFieldLabel
                  inputTitle={"First Name"}
                  type={"text"}
                  disabled={true}
                  value={userData.first_name}
                />
                <TextFieldLabel
                  inputTitle={"Email"}
                  type={"text"}
                  disabled={true}
                  value={userData.email}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextFieldLabel
                  inputTitle={"Last Name"}
                  type={"text"}
                  disabled={true}
                  value={userData.last_name}
                />
                <TextFieldLabel
                  inputTitle={"Phone Number"}
                  type={"text"}
                  disabled={true}
                  value={userData.contact_number}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/** <!--------- USER BILLING AND SHIPPING ADDRESS  ---------!> **/}
      <Grid
        container
        sx={{
          mb: 2,
        }}
        gap={3}
        direction={{
          xl: "row",
          lg: "row",
          md: "row",
          sm: "row",
          xs: "column",
        }}
        flexWrap={"nowrap"}
      >
        {/** <!--------- BILLING ADDRESS GRID ---------!> **/}

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            border: "1px solid rgb(218 213 213)",
            borderRadius: "3px",
          }}
        >
          <Box className={css.title_wrapper}>
            <Typography>Billing Address</Typography>
          </Box>

          <Stack flexWrap={"wrap"} padding={"25px"}>
            {billingFields.map((field, i) => {
              const countryName =
                field.title === "Country" || field.title === "Region/State"
              return countryName ? (
                <SelectField
                  key={i}
                  selectTitle={field.title}
                  onChange={field.onChange}
                  onSearch={field.onSearch}
                  optionArr={field.optionArr}
                  value={field.value}
                />
              ) : field.zipAddress ? (
                <Grid
                  key={i}
                  justifyContent={"space-between"}
                  container
                  gap={1}
                  flexWrap={{
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "nowrap",
                    sm: "nowrap",
                    xs: "wrap",
                  }}
                >
                  {field.zipAddress.map((zip_add, i) => {
                    const zip_address = zip_add.title === "City"
                    return (
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={i}>
                        {zip_address ? (
                          <SelectField
                            selectTitle={zip_add.title}
                            onChange={zip_add.onChange}
                            onSearch={zip_add.onSearch}
                            optionArr={zip_add.optionArr}
                            value={zip_add.value}
                          />
                        ) : (
                          <TextFieldLabel
                            inputTitle={zip_add.title}
                            error={zip_add.error}
                            type={zip_add.type}
                            onChange={zip_add.onChange}
                            value={zip_add.value}
                          />
                        )}
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <TextFieldLabel
                  key={i}
                  inputTitle={field.title}
                  type={field.type}
                  onChange={field.onChange}
                  value={field.value}
                  disabled={field.disabled}
                />
              )
            })}

            <Box className={css.save_btn}>
              <CustomButton onClick={() => handleAddress("billing_address")}>
                {"SAVE CHANGES"}
              </CustomButton>
            </Box>
          </Stack>
        </Grid>

        {/** <!--------- SHIPPING ADDRESS GRID ---------!> **/}

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            border: "1px solid rgb(218 213 213)",
            borderRadius: "3px",
          }}
        >
          <Box className={css.title_wrapper}>
            <Typography>Shipping Address</Typography>
          </Box>

          <Box padding={"25px"}>
            {shippingFields.map((field, i) => {
              const countryName =
                field.title === "Country" || field.title === "Region/State"
              return countryName ? (
                <SelectField
                  key={i}
                  selectTitle={field.title}
                  onChange={field.onChange}
                  onSearch={field.onSearch}
                  optionArr={field.optionArr}
                  value={field.value}
                />
              ) : field.zipAddress ? (
                <Grid
                  key={i}
                  justifyContent={"space-between"}
                  container
                  gap={1}
                  flexWrap={{
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "nowrap",
                    sm: "nowrap",
                    xs: "wrap",
                  }}
                >
                  {field.zipAddress.map((zip_add, i) => {
                    const zip_address = zip_add.title === "City"
                    return (
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={i}>
                        {zip_address ? (
                          <SelectField
                            selectTitle={zip_add.title}
                            onChange={zip_add.onChange}
                            onSearch={zip_add.onSearch}
                            optionArr={zip_add.optionArr}
                            value={zip_add.value}
                          />
                        ) : (
                          <TextFieldLabel
                            inputTitle={zip_add.title}
                            type={zip_add.type}
                            onChange={zip_add.onChange}
                            value={zip_add.value}
                          />
                        )}
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <TextFieldLabel
                  key={i}
                  inputTitle={field.title}
                  type={field.type}
                  onChange={field.onChange}
                  value={field.value}
                />
              )
            })}

            <Box className={css.save_btn}>
              <CustomButton onClick={() => handleAddress("shipping_address")}>
                {"SAVE CHANGES"}
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Setting
