const user_type_constant = {
  ADMIN: "admin",
  SELLER: "seller",
  BUYER: "buyer",
  BUSINESS_PARTNER: "business_partner",
}

// const CURRENCY = ["USD", "ZAR"]
const CURRENCY = [
  {
    country: "Afghanistan",
    currency: "Afghan Afghani",
    currency_code: "AFN",
  },
  {
    country: "Albania",
    currency: "Albanian Lek",
    currency_code: "ALL",
  },
  {
    country: "Algeria",
    currency: "Algerian Dinar",
    currency_code: "DZD",
  },
  {
    country: "Andorra",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Angola",
    currency: "Kwanza",
    currency_code: "AOA",
  },
  {
    country: "Armenia",
    currency: "Armenian Dram",
    currency_code: "AMD",
  },
  {
    country: "Austria",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Azerbaijan",
    currency: "Azerbaijani Manat",
    currency_code: "AZN",
  },
  {
    country: "Bahrain",
    currency: "Bahraini Dinar",
    currency_code: "BHD",
  },
  {
    country: "Bangladesh",
    currency: "Bangladeshi Taka",
    currency_code: "BDT",
  },
  {
    country: "Belarus",
    currency: "Belarusian Ruble",
    currency_code: "BYN",
  },
  {
    country: "Belgium",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Benin",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Bhutan",
    currency: "Bhutanese Ngultrum",
    currency_code: "BTN",
  },
  {
    country: "Bosnia and Herzegovina",
    currency: "Convertible Mark",
    currency_code: "BAM",
  },
  {
    country: "Botswana",
    currency: "Pula",
    currency_code: "BWP",
  },
  {
    country: "Brunei",
    currency: "Brunei Dollar",
    currency_code: "BND",
  },
  {
    country: "Bulgaria",
    currency: "Bulgarian Lev",
    currency_code: "BGN",
  },
  {
    country: "Burkina Faso",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Burundi",
    currency: "Burundi Franc",
    currency_code: "BIF",
  },
  {
    country: "Cabo Verde",
    currency: "Cabo Verde Escudo",
    currency_code: "CVE",
  },
  {
    country: "Cambodia",
    currency: "Cambodian Riel",
    currency_code: "KHR",
  },
  {
    country: "Cameroon",
    currency: "CFA Franc BEAC",
    currency_code: "XAF",
  },
  {
    country: "Central African Republic",
    currency: "CFA Franc BEAC",
    currency_code: "XAF",
  },
  {
    country: "Chad",
    currency: "CFA Franc BEAC",
    currency_code: "XAF",
  },
  {
    country: "China",
    currency: "Chinese Yuan Renminbi",
    currency_code: "CNY",
  },
  {
    country: "Comoros",
    currency: "Comorian Franc",
    currency_code: "KMF",
  },
  {
    country: "Congo",
    currency: "CFA Franc BEAC",
    currency_code: "XAF",
  },
  {
    country: "Côte d'Ivoire",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Croatia",
    currency: "Croatian Kuna",
    currency_code: "HRK",
  },
  {
    country: "Cyprus",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Czech Republic",
    currency: "Czech Koruna",
    currency_code: "CZK",
  },
  {
    country: "Democratic Republic of the Congo",
    currency: "Congolese Franc",
    currency_code: "CDF",
  },
  {
    country: "Denmark",
    currency: "Danish Krone",
    currency_code: "DKK",
  },
  {
    country: "Djibouti",
    currency: "Djiboutian Franc",
    currency_code: "DJF",
  },
  {
    country: "East Timor",
    currency: "United States Dollar",
    currency_code: "USD",
  },
  {
    country: "Egypt",
    currency: "Egyptian Pound",
    currency_code: "EGP",
  },
  {
    country: "Equatorial Guinea",
    currency: "Central African CFA Franc",
    currency_code: "XAF",
  },
  {
    country: "Eritrea",
    currency: "Nakfa",
    currency_code: "ERN",
  },
  {
    country: "Estonia",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Eswatini",
    currency: "Swazi Lilangeni",
    currency_code: "SZL",
  },
  {
    country: "Ethiopia",
    currency: "Ethiopian Birr",
    currency_code: "ETB",
  },
  {
    country: "Finland",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "France",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Gabon",
    currency: "Central African CFA Franc",
    currency_code: "XAF",
  },
  {
    country: "Georgia",
    currency: "Georgian Lari",
    currency_code: "GEL",
  },
  {
    country: "Germany",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Ghana",
    currency: "Ghanaian Cedi",
    currency_code: "GHS",
  },
  {
    country: "Greece",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Guinea",
    currency: "Guinean Franc",
    currency_code: "GNF",
  },
  {
    country: "Guinea-Bissau",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Hungary",
    currency: "Hungarian Forint",
    currency_code: "HUF",
  },
  {
    country: "Iceland",
    currency: "Icelandic Króna",
    currency_code: "ISK",
  },
  {
    country: "India",
    currency: "Indian Rupee",
    currency_code: "INR",
  },
  {
    country: "Indonesia",
    currency: "Indonesian Rupiah",
    currency_code: "IDR",
  },
  {
    country: "Iran",
    currency: "Iranian Rial",
    currency_code: "IRR",
  },
  {
    country: "Iraq",
    currency: "Iraqi Dinar",
    currency_code: "IQD",
  },
  {
    country: "Ireland",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Israel",
    currency: "Israeli New Shekel",
    currency_code: "ILS",
  },
  {
    country: "Italy",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Japan",
    currency: "Japanese Yen",
    currency_code: "JPY",
  },
  {
    country: "Jordan",
    currency: "Jordanian Dinar",
    currency_code: "JOD",
  },
  {
    country: "Kazakhstan",
    currency: "Kazakhstani Tenge",
    currency_code: "KZT",
  },
  {
    country: "Kenya",
    currency: "Kenyan Shilling",
    currency_code: "KES",
  },
  {
    country: "Korea, North",
    currency: "North Korean Won",
    currency_code: "KPW",
  },
  {
    country: "Korea, South",
    currency: "South Korean Won",
    currency_code: "KRW",
  },
  {
    country: "Kosovo",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Kuwait",
    currency: "Kuwaiti Dinar",
    currency_code: "KWD",
  },
  {
    country: "Kyrgyzstan",
    currency: "Kyrgyzstani Som",
    currency_code: "KGS",
  },
  {
    country: "Laos",
    currency: "Lao Kip",
    currency_code: "LAK",
  },
  {
    country: "Latvia",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Lebanon",
    currency: "Lebanese Pound",
    currency_code: "LBP",
  },
  {
    country: "Lesotho",
    currency: "Lesotho Loti",
    currency_code: "LSL",
  },
  {
    country: "Liberia",
    currency: "Liberian Dollar",
    currency_code: "LRD",
  },
  {
    country: "Libya",
    currency: "Libyan Dinar",
    currency_code: "LYD",
  },
  {
    country: "Liechtenstein",
    currency: "Swiss Franc",
    currency_code: "CHF",
  },
  {
    country: "Lithuania",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Luxembourg",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Madagascar",
    currency: "Malagasy Ariary",
    currency_code: "MGA",
  },
  {
    country: "Malawi",
    currency: "Malawian Kwacha",
    currency_code: "MWK",
  },
  {
    country: "Malaysia",
    currency: "Malaysian Ringgit",
    currency_code: "MYR",
  },
  {
    country: "Maldives",
    currency: "Maldivian Rufiyaa",
    currency_code: "MVR",
  },
  {
    country: "Mali",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Malta",
    currency: "Euro",
    currency_code: "EUR",
  },
  {
    country: "Mauritania",
    currency: "Mauritanian Ouguiya",
    currency_code: "MRU",
  },
  {
    country: "Mauritius",
    currency: "Mauritian Rupee",
    currency_code: "MUR",
  },
  {
    country: "Mongolia",
    currency: "Mongolian Tögrög",
    currency_code: "MNT",
  },
  {
    country: "Morocco",
    currency: "Moroccan Dirham",
    currency_code: "MAD",
  },
  {
    country: "Mozambique",
    currency: "Mozambican Metical",
    currency_code: "MZN",
  },
  {
    country: "Myanmar",
    currency: "Burmese Kyat",
    currency_code: "MMK",
  },
  {
    country: "Namibia",
    currency: "Namibian Dollar",
    currency_code: "NAD",
  },
  {
    country: "Nepal",
    currency: "Nepalese Rupee",
    currency_code: "NPR",
  },
  {
    country: "Niger",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Nigeria",
    currency: "Nigerian Naira",
    currency_code: "NGN",
  },
  {
    country: "Oman",
    currency: "Omani Rial",
    currency_code: "OMR",
  },
  {
    country: "Pakistan",
    currency: "Pakistani Rupee",
    currency_code: "PKR",
  },
  {
    country: "Palestine",
    currency: "Israeli New Shekel",
    currency_code: "ILS",
  },
  {
    country: "Philippines",
    currency: "Philippine Peso",
    currency_code: "PHP",
  },
  {
    country: "Qatar",
    currency: "Qatari Riyal",
    currency_code: "QAR",
  },
  {
    country: "Russia",
    currency: "Russian Ruble",
    currency_code: "RUB",
  },
  {
    country: "Rwanda",
    currency: "Rwandan Franc",
    currency_code: "RWF",
  },
  {
    country: "São Tomé and Príncipe",
    currency: "São Tomé and Príncipe Dobra",
    currency_code: "STN",
  },
  {
    country: "Saudi Arabia",
    currency: "Saudi Riyal",
    currency_code: "SAR",
  },
  {
    country: "Senegal",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Seychelles",
    currency: "Seychellois Rupee",
    currency_code: "SCR",
  },
  {
    country: "Sierra Leone",
    currency: "Sierra Leonean Leone",
    currency_code: "SLL",
  },
  {
    country: "Singapore",
    currency: "Singapore Dollar",
    currency_code: "SGD",
  },
  {
    country: "Somalia",
    currency: "Somali Shilling",
    currency_code: "SOS",
  },
  {
    country: "South Africa",
    currency: "South African Rand",
    currency_code: "ZAR",
  },
  {
    country: "South Sudan",
    currency: "South Sudanese Pound",
    currency_code: "SSP",
  },
  {
    country: "Sri Lanka",
    currency: "Sri Lankan Rupee",
    currency_code: "LKR",
  },
  {
    country: "Sudan",
    currency: "Sudanese Pound",
    currency_code: "SDG",
  },
  {
    country: "Syria",
    currency: "Syrian Pound",
    currency_code: "SYP",
  },
  {
    country: "Tajikistan",
    currency: "Tajikistani Somoni",
    currency_code: "TJS",
  },
  {
    country: "Tanzania",
    currency: "Tanzanian Shilling",
    currency_code: "TZS",
  },
  {
    country: "Thailand",
    currency: "Thai Baht",
    currency_code: "THB",
  },
  {
    country: "The Gambia",
    currency: "Gambian Dalasi",
    currency_code: "GMD",
  },
  {
    country: "Togo",
    currency: "CFA Franc BCEAO",
    currency_code: "XOF",
  },
  {
    country: "Tunisia",
    currency: "Tunisian Dinar",
    currency_code: "TND",
  },
  {
    country: "Turkey",
    currency: "Turkish Lira",
    currency_code: "TRY",
  },
  {
    country: "Turkmenistan",
    currency: "Turkmenistani Manat",
    currency_code: "TMT",
  },
  {
    country: "Uganda",
    currency: "Ugandan Shilling",
    currency_code: "UGX",
  },
  {
    country: "United Arab Emirates",
    currency: "United Arab Emirates Dirham",
    currency_code: "AED",
  },
  {
    country: "Uzbekistan",
    currency: "Uzbekistani Som",
    currency_code: "UZS",
  },
  {
    country: "Vietnam",
    currency: "Vietnamese Đồng",
    currency_code: "VND",
  },
  {
    country: "Yemen",
    currency: "Yemeni Rial",
    currency_code: "YER",
  },
  {
    country: "Zambia",
    currency: "Zambian Kwacha",
    currency_code: "ZMW",
  },
  {
    country: "Zimbabwe",
    currency: "Zimbabwean Dollar",
    currency_code: "ZWD",
  },
]

export { user_type_constant, CURRENCY }
