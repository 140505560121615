import React from "react"
import ChartJS from "chart.js/auto"
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import faker from "faker"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Statistics",
      position: "top",
    },
  },
}

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sep","Oct","Nov","Dec"]

const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Revenue",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: "#FF9900",
      backgroundColor: 'rgba(255, 153, 0, 0.3)',
      
    },
    {
      fill: true,
      label: "Sales",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: "#0d894f",
      backgroundColor: 'rgba(13, 137, 79, 0.3)',
    },
  ],
}

const StatesChart = () => {
  return <Line options={options} data={data} />
}

export default StatesChart
