import React, { useState } from "react"
import OtpInput from "react-otp-input"

function OTPInput({ onChange, value }) {

  // const [otp, setOtp] = useState("")
  return (
    <OtpInput
      inputStyle={{
        padding:`10px 0`,
        width:'2em',
        background: "#F4F4F4",
        border: "1.1px solid #66666659",
        borderRadius: 2,
        fontSize: 20,

      }}
      containerStyle={{
        gap: 10,
        alignItems: "center",
        width: "100%",
      }}
      value={value}
      onChange={onChange}
      numInputs={4}
      renderInput={(props) => {
        return <input {...props}/>
      }}
    />
  )
}

export default OTPInput
