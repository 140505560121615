import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import Rating from "@mui/material/Rating"
import "./product.css"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import { PiCopyLight } from "react-icons/pi"
import { FaFacebook } from "react-icons/fa"
import { AiOutlineTwitter } from "react-icons/ai"
import { BiLogoPinterestAlt } from "react-icons/bi"
import Navbar from "../../components/common/navbar/Navbar"
import CustomButton from "../../components/common/Button/Button"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { get_product_by_product_id_async } from "../../store/services/productsService"
import { asyncStatus } from "../../utils/asyncStatus"
import { add_cart_product_async } from "../../store/services/cartmanageService"
import CustomModal from "../../components/common/CustomModel/CustomModel"
import { primaryColour } from "../../utils/colorTheme"
import Custom_Modal from "../../components/common/CustomModel/CustomModel"
import { add_wishlist_products_async } from "../../store/services/wishlistService"
import { MdFavorite, MdFavoriteBorder } from "react-icons/md"

const Product = () => {
  const params = useParams()

  const [selectedImg, setSelectedImg] = useState(0)
  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState(2)
  const [selectedOption, setSelectedOption] = useState("None")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data_from_product_id_data, data_from_product_id_status } =
    useSelector((state) => state.product)

  const { userData, userAuth } = useSelector((state) => state.auth)

  const handleSelectedImg = (index) => {
    setSelectedImg(index)
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  useEffect(() => {
    dispatch(get_product_by_product_id_async(params))
  }, [])

  const addCart = () => {
    let obj = {
      product_id: data_from_product_id_data[0]?._id,
      quantity: 1,
    }
    dispatch(add_cart_product_async(obj))
  }

  // console.log("add_wish_list_data?.is_add", add_wish_list_data)

  const handleWishListProduct = () => {
    dispatch(
      add_wishlist_products_async({
        product_id: params.id,
        is_add: !data_from_product_id_data[0]?.is_favorite,
      }),
    )
  }
  return (
    <>
      <Navbar />
      <Container maxWidth={false}>
        {data_from_product_id_status === asyncStatus.LOADING ? (
          <>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={"80vh"}
            >
              <CircularProgress color="warning" size={30} />
            </Stack>
          </>
        ) : (
          data_from_product_id_data?.map((e, i) => {
            return (
              <>
                <Grid container mt={10} key={i}>
                  <Grid
                    item
                    lg={2}
                    xl={2}
                    md={2}
                    sm={2}
                    xs={12}
                    className="scroll"
                    display={"flex"}
                    alignItems={"center"}
                    maxHeight={"500px"}
                    flexDirection={{
                      xl: "column",
                      lg: "column",
                      md: "column",
                      sm: "column",
                      xs: "row",
                    }}
                  >
                    {e.images.map((img, i) => {
                      return (
                        <Stack p={1} key={i}>
                          <img
                            onClick={() => handleSelectedImg(i)}
                            className="small-img"
                            src={img}
                            alt={`camera-${i + 1}`}
                          />
                        </Stack>
                      )
                    })}
                  </Grid>
                  <Grid item lg={5} xl={5} md={5} sm={12} xs={12}>
                    <Box
                      justifyContent={"center"}
                      sx={{ borderBottom: "1px solid grey" }}
                    >
                      <img
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          height: "500px",
                        }}
                        src={e.images[selectedImg]}
                        alt="camera-1"
                      />
                    </Box>
                  </Grid>
                  {/* </Grid> */}
                  <Grid item lg={4} xl={4} md={4} sm={12} xs={12} mx={"20px"}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                    >
                      {e.average_rating && (
                        <Rating
                          sx={{
                            fontSize: "20px",
                          }}
                          name="simple-controlled"
                          value={e.average_rating ? e.average_rating : value}
                          onChange={(event, newValue) => {
                            setValue(newValue)
                          }}
                        />
                      )}
                      {e.total_reviews > 0 && (
                        <Typography
                          display={"flex"}
                          sx={{
                            color: "#191c1e",
                            fontSize: "14px",
                            fontWeight: "600",
                            letterSpacing: "0",
                            lineHeight: "20px",
                          }}
                          variant="h6"
                        >
                          {Math.round(e.average_rating)} Star Rating
                          <Typography className="prim-grey" variant="body1">
                            &nbsp; ({e.total_reviews} User feedback)
                          </Typography>
                        </Typography>
                      )}
                    </Box>
                    {e.title && (
                      <Typography
                        mt={"8px"}
                        sx={{
                          color: "#191c1e",
                          fontSize: "20px",
                          fontWeight: "400",
                          letterSpacing: "0",
                          lineHeight: "28px",
                        }}
                        variant="h4"
                      >
                        {e.title}
                      </Typography>
                    )}
                    <Stack
                      mt={"16px"}
                      width={"100%"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Typography
                          display={"flex"}
                          alignItems={"baseline"}
                          className="prim-grey"
                          variant="h4"
                          mt={{
                            xl: "12px",
                            lg: "12px",
                            md: "12px",
                            sm: "12px",
                            xs: "12px",
                          }}
                        >
                          Sku:&nbsp;
                          <Typography className="sec-grey">A264671</Typography>
                        </Typography>
                        {/* <Typography
                        mt={"8px"}
                        className="prim-grey"
                        variant="h4"
                        display={"flex"}
                        alignItems={"baseline"}
                      >
                        Brand:&nbsp;
                        <Typography className="sec-grey">Apple</Typography>
                      </Typography> */}
                        {e.sub_category_title && (
                          <Typography
                            mt={"8px"}
                            className="prim-grey"
                            variant="h4"
                            display={"flex"}
                            alignItems={"baseline"}
                          >
                            Category: &nbsp;
                            <Typography className="sec-grey">
                              {e.sub_category_title}
                            </Typography>
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        {e.in_stock && (
                          <Typography
                            className="prim-grey"
                            variant="h4"
                            display={"flex"}
                            alignItems={"baseline"}
                            mt={{
                              xl: "12px",
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "12px",
                            }}
                          >
                            Availability:&nbsp;
                            <Typography
                              color={e.in_stock ? "#2db223" : "red"}
                              sx={{
                                // color: "#2db223",
                                fontSize: "14px",
                                fontWeight: "600",
                                letterSpacing: "0",
                                lineHeight: "20px",
                              }}
                            >
                              {e.in_stock ? "In Stock" : "Out of stock"}
                            </Typography>
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      flexWrap={"wrap"}
                      my={"24px"}
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      {e.price && (
                        <Typography className="blue-price">
                          ${e.price}
                        </Typography>
                      )}
                      {/* <Typography className="price-grey">$1999.00</Typography>
                    <Typography className="off-yellow">21% OFF</Typography> */}
                    </Stack>
                    <Divider />
                    {/* ...Colors and size dropdown */}
                    <Stack
                      display={"flex"}
                      flexDirection={{
                        xl: "row",
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                      justifyContent={{
                        xl: "space-between",
                        lg: "space-between",
                        md: "space-between",
                        sm: "space-between",
                        xs: "start",
                      }}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                      mt={"24px"}
                    >
                      {e?.color?.length > 0 && (
                        <Box
                          textAlign={"start"}
                          width={{
                            xl: "auto",
                            lg: "auto",
                            md: "auto",
                            sm: "auto",
                            xs: "100%",
                          }}
                        >
                          <Typography>Color</Typography>
                          <Box className="colors-containers">
                            {e.color?.map((e, i) => {
                              return (
                                  <Box key={i}>
                                    <Box sx={{ bgcolor: e }}></Box>
                                  </Box>
                              )
                            })}
                          </Box>
                        </Box>
                      )}
                      {e?.size?.length > 0 && (
                        <Box
                          textAlign={"start"}
                          width={{
                            xl: "48%",
                            lg: "48%",
                            md: "48%",
                            sm: "48%",
                            xs: "100%",
                          }}
                        >
                          <Typography
                            mt={{
                              xl: "0px",
                              lg: "0px",
                              md: "0px",
                              sm: "0px",
                              xs: "10px",
                            }}
                          >
                            Size
                          </Typography>
                          <FormControl
                            sx={{ mt: 1, minWidth: "100%" }}
                            size="small"
                          >
                            <Select
                              id="demo-select-small"
                              value={selectedOption}
                              onChange={handleSelectChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              renderValue={(selected) => {
                                // Truncate the displayed text here (e.g., limit to 15 characters)
                                const truncatedText =
                                  selected.length > 15
                                    ? `${selected.slice(0, 15)} ...`
                                    : selected
                                return <div>{truncatedText}</div>
                              }}
                            >
                              {e.size?.map((option, i) => {
                                return (
                                  <MenuItem value={option} key={i}>
                                    {option}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                    </Stack>

                    {/* add to cart buttons*/}
                    {userData?.user_type !== "seller" && (
                      <Stack
                        direction={"row"}
                        my={"24px"}
                        alignItems={"center"}
                        // gap={5}
                        justifyContent={"space-between"}
                      >
                        <CustomButton
                          style={{
                            padding: "10px 5px",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() =>
                            !userAuth ? setOpen(true) : navigate("/chat")
                          }
                          children={"Request for qoute"}
                        />
                        <CustomButton
                          style={{ padding: "10px 9px", whiteSpace: "nowrap" }}
                          onClick={() =>
                            !userAuth ? setOpen(true) : addCart()
                          }
                          children={`Add to cart`}
                        />
                        <Button
                          color="warning"
                          variant="outlined"
                          sx={{
                            textTransform: "capitalize",
                            whiteSpace: "nowrap",
                            py: "6px",
                          }}
                          onClick={() => (!userAuth ? setOpen(true) : "")}
                        >
                          Buy now
                        </Button>
                      </Stack>
                    )}

                    {/* wishlist and  share icon */}
                    {userData?.user_type !== "seller" && (
                      <Stack className="wishShare-wrapper">
                        <Box container={true} direction={"row"}>
                          <IconButton
                            className="icon"
                            sx={{ color: `${primaryColour} !important` }}
                            onClick={() => handleWishListProduct()}
                          >
                            {e?.is_favorite ? (
                              <MdFavorite />
                            ) : (
                              <MdFavoriteBorder />
                            )}
                          </IconButton>

                          <Typography>Add to Wishlist</Typography>
                        </Box>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography>Share product:</Typography>
                          <IconButton className="icon">
                            <PiCopyLight />
                          </IconButton>

                          <IconButton className="icon">
                            <FaFacebook />
                          </IconButton>
                          <IconButton className="icon">
                            <AiOutlineTwitter />
                          </IconButton>
                          <IconButton className="icon">
                            <BiLogoPinterestAlt />
                          </IconButton>
                        </Stack>
                      </Stack>
                    )}
                    {/* payment methods card */}
                    {/* <Stack className="paymentMethodCard">
                      <Box>
                        <Typography>100% Guarantee Safe Checkout</Typography>
                      </Box>
                      <Box>
                        <img
                          src={paymentcards}
                          width={"312px"}
                          height={"18px"}
                          alt=""
                        />
                      </Box>
                    </Stack> */}
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12} pl={10} py={2}>
                    <Box
                      sx={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Typography variant="h4">Description</Typography>
                      <Typography variant="h6">{e.description}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12} pl={10} py={2}>
                    <Box
                      sx={{ justifyContent: "center", alignItems: "center" }}
                      mb={4}
                    >
                      <Typography variant="h4">Features</Typography>
                      {e.features?.map((e, i) => {
                        return <Typography key={i}>{e}</Typography>
                      })}
                      <Typography>
                        {/* <Typography color={"lightgray"}>
                        20.1 effective megapixels of pure imaging power
                      </Typography> */}
                      </Typography>
                    </Box>
                    {/* <Stack justifyContent={"center"} alignItems={"center"}>
                      <CustomButton
                        style={{ padding: "10px 100px" }}
                        children="View more"
                      />
                    </Stack> */}
                  </Grid>

                  {/* <Grid
                    container
                    flexWrap={{
                      xl: "nowrap",
                      lg: "nowrap",
                      md: "nowrap",
                      sm: "wrap",
                      xs: "wrap",
                    }}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={5}
                      xs={12}
                      mb={2}
                      mx={0.5}
                    >
                      <Typography sx={{ whiteSpace: "nowrap" }}>
                        RELATED PRODUCT
                      </Typography>
                      {obj.map((e, i) => {
                        return (
                          <SmallProductCard
                            pic={e.picture}
                            caption={e.caption}
                            price={`${e.price}`}
                          />
                        )
                      })}
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={5}
                      xs={12}
                      mb={2}
                      mx={0.5}
                    >
                      <Typography sx={{ whiteSpace: "nowrap" }}>
                        PRODUCT ACCESSORIES
                      </Typography>
                      {obj.map((e, i) => {
                        return (
                          <SmallProductCard
                            pic={e.picture}
                            caption={e.caption}
                            price={`${e.price}`}
                          />
                        )
                      })}
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={5}
                      xs={12}
                      mb={2}
                      mx={0.5}
                    >
                      <Typography sx={{ whiteSpace: "nowrap" }}>
                        APPLE PRODUCT
                      </Typography>
                      {obj.map((e, i) => {
                        return (
                          <SmallProductCard
                            pic={e.picture}
                            caption={e.caption}
                            price={`${e.price}`}
                          />
                        )
                      })}
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={5}
                      xs={12}
                      mb={2}
                      mx={0.5}
                    >
                      <Typography sx={{ whiteSpace: "nowrap" }}>
                        FEATURED PRODUCTS
                      </Typography>
                      {obj.map((e, i) => {
                        return (
                          <SmallProductCard
                            pic={e.picture}
                            caption={e.caption}
                            price={`${e.price}`}
                          />
                        )
                      })}
                    </Grid>
                  </Grid> */}
                  {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} p={1}>
                    <Stack gap={1}>
                      {e.reviews.map((e, i) => {
                        return (
                          <>
                            <UsersReviews
                              name={e.user_info?.first_name}
                              description={e.reviews}
                              rating={e.rating}
                              date={timeAgo(e.createdAt)}
                            />
                          </>
                        )
                      })}
                    </Stack>
                  </Grid> */}
                </Grid>
              </>
            )
          })
        )}
      </Container>
      <Custom_Modal
        title={"Alert!"}
        open={open}
        handleClose={() => setOpen(false)}
        buttons={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Button
              component="label"
              variant="outlined"
              sx={{
                borderColor: primaryColour,
                color: primaryColour,
                "&:hover": {
                  borderColor: primaryColour,
                  color: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>

            <Button
              component="label"
              variant="contained"
              sx={{
                backgroundColor: primaryColour,
                "&:hover": {
                  backgroundColor: primaryColour,
                },
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() => {
                navigate("/login")
                setOpen(false)
              }}
              // startIcon={<CloudUploadIcon />}
            >
              Login Now
            </Button>
          </Stack>
        }
      >
        <Stack
          gap={2}
          sx={{
            width: {
              xl: "30vw",
              lg: "30vw",
              md: "30vw",
              sm: "35vw",
              xs: "55vw",
            },
          }}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
            To perform these actions you have to login first.
          </Typography>
        </Stack>
      </Custom_Modal>
    </>
  )
}

export default Product
