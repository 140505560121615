import React from "react"
import css from "./OrderDetails.module.css"
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material"
import CustomButton from "../../../components/common/Button/Button"
import { BiReceipt } from "react-icons/bi"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import SelectField from "../../../components/common/SelectField/SelectField"
import { FaRegUser } from "react-icons/fa"
import { FaRegEnvelope } from "react-icons/fa"
import { HiDevicePhoneMobile } from "react-icons/hi2"
import { PiMapPinBold } from "react-icons/pi"
import { FaRegCalendarCheck } from "react-icons/fa6"
import { BiCreditCard } from "react-icons/bi"
import { useParams } from "react-router-dom"
import { get_order_detail_by_id_async } from "../../../store/services/ordersService"
import { asyncStatus } from "../../../utils/asyncStatus"
import { primaryColour } from "../../../utils/colorTheme"

const ProductComponent = (props) => {
  const { image_preview, title, description } = props
  return (
    <Stack spacing={1} direction={"row"}>
      <Stack
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#EFF0EB",
          height: 44,
          width: 44,
          objectFit: "contain",
          p: "4px",
        }}
      >
        <img
          height={"100%"}
          width={"100%"}
          src={image_preview ?? ""}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>{title ?? ""}</Typography>
        <Typography className={css.color_name}>
          {description.slice(0, 10) ?? ""}
        </Typography>
      </Stack>
    </Stack>
  )
}

/* <!----- ORDERS LIST TABLE COLUMN -----!> */
const orderlistcolumns = [
  {
    title: "Product",
    dataIndex: "",
    render: (props) => {
      return (
        <>
          <ProductComponent {...props} />
        </>
      )
    },
  },
  {
    title: "QTY",
    dataIndex: "quantity",
    render: (quantity) => (
      <Typography className={css.quantity}>{quantity ?? ""}</Typography>
    ),
  },

  {
    title: "Price",
    dataIndex: "price",
    render: (price) => (
      <Typography className={css.price}>{`$${price ?? "0"}`}</Typography>
    ),
  },
  // {
  //   title: "SKU",
  //   dataIndex: "sku",
  //   render: (sku) => (
  //     <Typography className={css.sku}>{sku ?? "302011"}</Typography>
  //   ),
  // },
  {
    title: "Total",
    dataIndex: "total",
    render: (total) => (
      <Typography className={css.total}>{`$${total ?? "0"}`}</Typography>
    ),
  },
]

const OrderDetails = () => {
  const [inner_width, setCurrentWidth] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const { id } = useParams()

  const { get_orders_detail_by_id_status, get_orders_detail_by_id_data } =
    useSelector((state) => state.orders_slice)

  const {
    buyer_details: { email, full_name } = {},

    billing_address_details: {
      address: bill_address,
      city: bill_city,
      company_name: bill_company_name,
      country: bill_country,
      email: bill_email,
      name: bill_name,
      phone: bill_phone,
      region_state: bill_region_state,
      zipcode: bill_zipcode,
    } = {},
    shipping_address_details: {
      address: shipp_address,
      city: shipp_city,
      company_name: shipp_company_name,
      country: shipp_country,
      email: shipp_email,
      name: shipp_name,
      phone: shipp_phone,
      region_state: shipp_region_state,
      zipcode: shipp_zipcode,
    } = {},
    createdAt,
    delivered_orders_count,
    negotiation_id,
    order_no,
    payment_confirmed,
    order_details,
    total_amount,
  } = get_orders_detail_by_id_data || {}

  const getOrderDetailLoader =
    get_orders_detail_by_id_status === asyncStatus.LOADING

  const duration_filterArr = {
    defaultVal: "Processing`",
    onChange: () => {},
    onSearch: () => {},
    durationArr: [
      { value: "Processing", label: "Processing" },
      { value: "12 Months", label: "12 Months" },
      { value: "30 Days", label: "30 Days" },
      { value: "7 Days", label: "7 Days" },
      { value: "24 Hours", label: "24 Hours" },
    ],
  }

  useEffect(() => {
    dispatch(get_order_detail_by_id_async(id))
  }, [])

  useEffect(() => {
    const handleInnerWidth = () => {
      setCurrentWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleInnerWidth)

    return () => window.addEventListener("resize", handleInnerWidth)
  }, [inner_width])
  return (
    <Box width={inner_width < 500 ? "95%" : "100%"}>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          alignItems={"center"}
          width={{ xl: 0.7, lg: 0.7, md: 0.7, sm: 0.7, xs: 1 }}
        >
          <Typography className={css.title}>Order Detail</Typography>
        </Grid>
        {/* ---- export button and add product ---- */}
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Box style={{ borderRadius: "18px" }}>
              <SelectField
                selectTitle={duration_filterArr.tit}
                onChange={() => {}}
                optionArr={duration_filterArr.durationArr}
                style={{
                  marginBottom: "0px",
                  borderRadius: "18px",
                }}
                select_style={{ borderRadius: "18px", borderColor: "blue" }}
                defaultVal={duration_filterArr.defaultVal}
              />
            </Box>
            <CustomButton
              style={{
                border: "1px solid #FF9900",
                borderRadius: "8px",
              }}
              onClick={() => {
                navigate("/seller-dashboard/order-details")
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <BiReceipt size={20} />
                <Typography className={css.invoice_btn} sx={{ color: "white" }}>
                  Invoice
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>

      {getOrderDetailLoader ? (
        <Stack
          width={1}
          height={"50vh"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress size={30} sx={{ color: primaryColour }} />
        </Stack>
      ) : get_orders_detail_by_id_data ? (
        <>
          {/** <!--------- ORDER ,CUSTOMER ,DOCUMENT ---------!> **/}

          <Grid
            container
            gap={2}
            flexWrap={{
              xl: "nowrap",
              lg: "nowrap",
              md: "nowrap",
              sm: "wrap",
              xs: "wrap",
            }}
            justifyContent={"space-around"}
            my={3}
          >
            {/** <!----- CUSTOMER -----!> **/}

            <Grid
              item
              className={css.order_detail_cards}
              xl={4}
              lg={4}
              md={4}
              sm={5.8}
              xs={12}
            >
              <Typography className={css.order_detail_title}>
                Customer
              </Typography>
              <Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  my={0.3}
                  py={1}
                >
                  <Typography className={css.customer_detail_left}>
                    <span className={css.order_detail_icon}>
                      <FaRegUser size={20} />
                    </span>
                    {full_name ?? ""}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  my={0.3}
                  py={1}
                >
                  <Typography className={css.customer_detail_left}>
                    <span className={css.order_detail_icon}>
                      <FaRegEnvelope size={20} />
                    </span>
                    {email ?? ""}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  my={0.3}
                  py={1}
                >
                  <Typography className={css.customer_detail_left}>
                    <span className={css.order_detail_icon}>
                      <HiDevicePhoneMobile size={20} />
                    </span>
                    909 427 2910
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            {/** <!----- ORDER -----!> **/}
            <Grid
              item
              className={css.order_detail_cards}
              xl={4}
              lg={4}
              md={4}
              sm={5.8}
              xs={12}
            >
              <Typography className={css.order_detail_title}>
                Order #{order_no ?? ""}{" "}
                <span className={css.processing}>Processing</span>
              </Typography>
              <Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  my={0.3}
                  py={1}
                >
                  <Typography className={css.customer_detail_left}>
                    <span className={css.order_detail_icon}>
                      <FaRegCalendarCheck size={20} />
                    </span>
                    {order_details?.createdAt?.split("T")[0] ?? ""}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  my={0.3}
                  py={1}
                >
                  <Typography className={css.customer_detail_left}>
                    <span className={css.order_detail_icon}>
                      <BiCreditCard size={20} />
                    </span>
                    Visa
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            {/** <!----- ADDRESS -----!> **/}

            <Grid
              item
              className={css.order_detail_cards}
              xl={4}
              lg={4}
              md={4}
              sm={5.8}
              xs={12}
            >
              <Typography className={css.order_detail_title}>
                Address
              </Typography>
              <Stack>
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  my={0.3}
                  py={1}
                  gap={1}
                >
                  <Box className={css.order_detail_icon}>
                    <PiMapPinBold size={20} />
                  </Box>
                  <Stack
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    gap={0.5}
                  >
                    <Typography className={css.address_detail_title}>
                      Billing
                    </Typography>
                    <Typography className={css.address_detail_description}>
                      {`${bill_address ?? ""} ${bill_city ?? ""} ${
                        bill_region_state ?? ""
                      } ${bill_zipcode ?? ""}, ${bill_country ?? ""}` ?? ""}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  my={0.3}
                  py={1}
                  gap={1}
                >
                  <Box className={css.order_detail_icon}>
                    <PiMapPinBold size={20} />
                  </Box>
                  <Stack
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    gap={0.5}
                  >
                    <Typography className={css.address_detail_title}>
                      Shipping
                    </Typography>
                    <Typography className={css.address_detail_description}>
                    {`${shipp_address ?? ""} ${shipp_city ?? ""} ${
                        shipp_region_state ?? ""
                      } ${shipp_zipcode ?? ""}, ${shipp_country ?? ""}` ?? ""}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          {/** <!---------ORDER LIST TABLE ,ORDER STATUS ---------!> **/}
          <Grid
            container
            gap={1}
            flexWrap={{
              xl: "nowrap",
              lg: "nowrap",
              md: "nowrap",
              sm: "nowrap",
              xs: "wrap",
            }}
            justifyContent={"space-around"}
          >
            <Stack width={1} className={`${css.order_list_tble} tabled`}>
              <Stack px={2} gap={1}>
                <Typography className={css.tbl_title}>
                  Order List{" "}
                  <span className={css.product_qty}>
                    {`${order_details?.products?.length} Products` ??
                      "0 Products"}
                  </span>
                </Typography>
              </Stack>
              <Box className={css.table_wrapper}>
                <ProductListTable
                  // handleTabChange={handleTabChange}
                  loading={getOrderDetailLoader}
                  dataSource={order_details?.products}
                  columns={orderlistcolumns}
                />
              </Box>
            </Stack>
          </Grid>
        </>
      ) : (
        <Stack
          width={1}
          height={"50vh"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography>No Data Found</Typography>
        </Stack>
      )}
    </Box>
  )
}

export default OrderDetails
