import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import { create_business_async } from "../services/businessMangeService"
import { success_toast_message } from "../../utils/toast_notification"

const initialState = {
  business_profile_data: null,
  business_profile_status: asyncStatus.IDLE,
  business_profile_error: null,
}

const business_manage_slice = createSlice({
  name: "businessManageSlice",
  initialState,
  reducers: {
    
  },
  extraReducers(builder) {
    
    // creat business profile
    builder.addCase(create_business_async.pending, (state, { payload }) => {
      state.business_profile_status = asyncStatus.LOADING
    })
    builder.addCase(create_business_async.fulfilled, (state, { payload }) => {
      state.business_profile_data = payload.save_profile_data
      state.business_profile_status = asyncStatus.SUCCEEDED
      state.business_profile_error = null
      success_toast_message(payload.message)
    })
    builder.addCase(create_business_async.rejected, (state, actions) => {
      state.business_profile_data = null
      state.business_profile_status = asyncStatus.ERROR
      state.business_profile_error = actions?.error?.message
      success_toast_message(actions?.error?.message)
    })
  },
})

export default business_manage_slice.reducer
