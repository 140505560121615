import { Stack, Typography } from "@mui/material"
import React from "react"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"

const DeliveryOptions = () => {
  return (
    <Stack>
      <Typography variant="h6" mb={1}>
        Delivery options
      </Typography>

      <Stack
        direction={"row"}
        p={3}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ border: "1px solid black", borderRadius: "4px" }}
      >
        <Stack>
          <Typography>Home delivery</Typography>
          <Typography color={"lightgray"}>Takes 3-5 business days</Typography>
        </Stack>
        <Stack>
          <IoMdCheckmarkCircleOutline color="green" size={30} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DeliveryOptions
