import { Grid, IconButton, Stack, Typography } from "@mui/material"
import React, { useEffect } from "react"
import logo from "../../assets/logo.png"
import { FaFacebookF } from "react-icons/fa"
import { PiInstagramLogoBold } from "react-icons/pi"
import { FaLinkedinIn } from "react-icons/fa6"
import { FaTwitter } from "react-icons/fa"
import { TbBrandYoutubeFilled } from "react-icons/tb"
import css from "./Footer.module.css"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { get_all_categories_async } from "../../store/services/categoriesService"
import { get_all_products_async } from "../../store/services/productsService"
import { primaryColour } from "../../utils/colorTheme"
import { SAVE_CATEGORY_ID } from "../../store/constant"

const Footer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categories_data } = useSelector(
    (state) => state.categoriesManageSlice,
  )

  useEffect(() => {
    dispatch(get_all_categories_async())
  }, [])

  const useful_links = [
    "About",
    "Contact",
    "Wishlist",
    "Compare",
    "FAQ",
    "Terms & Conditions",
    "Privacy Policy",
    "Cookie Policy",
  ]

  const customer_services = [
    "My Account",
    "My Cart",
    "Fastest Delivery",
    "Secure Payment",
    "Support",
  ]

  const click_handle = (id) => {
    dispatch(get_all_products_async({ data: { category_id: id } }))
    localStorage.setItem(SAVE_CATEGORY_ID, id)
    navigate(`/category`)
  }

  return (
    <Grid
      container
      justifyContent={"space-between"}
      mt={"40px"}
      mb={"20px"}
      direction={{
        xl: "row",
        lg: "row",
        md: "row",
        sm: "row",
        xs: "column",
      }}
      flexWrap={{
        xl: "nowrap",
        lg: "nowrap",
        md: "nowrap",
        sm: "wrap",
        xs: "wrap",
      }}
    >
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        sm={6}
        xs={6}
        sx={{ p: 1 }}
        className={`${css.links_div} ${css.links_div_1}`}
      >
        <Stack sx={{ height: "100px", width: "100px" }}>
          <img height={"100%"} width={"100%"} src={logo} alt="" />
        </Stack>
        <Typography className={css.info}>
          Email: <br /> <span>contact@Sokolingo.com</span>
        </Typography>
        <Typography className={css.info}>
          Telephone: <br /> <span>(+40) 987 123 654</span>
        </Typography>
        <Stack className={css.social_links}>
          {[
            <FaFacebookF />,
            <PiInstagramLogoBold />,
            <FaLinkedinIn />,
            <FaTwitter />,
            <TbBrandYoutubeFilled />,
          ].map((icons, i) => (
            <IconButton
              sx={{
                fontSize: 20,
                "&:hover": { color: primaryColour, cursor: "pointer" },
              }}
              key={i}
            >
              {icons}
            </IconButton>
          ))}
        </Stack>
      </Grid>

      {/**
       * ------------- CATEGORIES ------------- *
       **/}

      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        sm={6}
        xs={6}
        sx={{ p: 1 }}
        className={`${css.links_div} ${css.links_wrapper}`}
      >
        <Typography className={css.links_heading}>Categories</Typography>
        <ul>
          {categories_data?.length > 0 &&
            categories_data?.map(({ category_title, _id }) => {
              return (
                <li key={_id}>
                  <a onClick={() => click_handle(_id)}>{category_title}</a>
                </li>
              )
            })}
        </ul>
      </Grid>

      {/**
       * ------------- USEFULL_LINKS ------------- *
       **/}

      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        sm={6}
        xs={6}
        sx={{ p: 1 }}
        className={`${css.links_div} ${css.links_wrapper}`}
      >
        <Typography className={css.links_heading}>Usefull links</Typography>
        <ul>
          {useful_links?.length > 0 &&
            useful_links?.map((links, i) => {
              return (
                <li key={i}>
                  <a href={"path"}>{links}</a>
                </li>
              )
            })}
        </ul>
      </Grid>

      {/**
       * ------------- CUSTOMER_SERVICES ------------- *
       **/}

      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        sm={6}
        xs={6}
        sx={{ p: 1 }}
        className={`${css.links_div} ${css.links_wrapper}`}
      >
        <Typography className={css.links_heading}>Customer services</Typography>
        <ul>
          {customer_services?.length > 0 &&
            customer_services?.map((links, i) => {
              return (
                <li key={i}>
                  <a href={"path"}>{links}</a>
                </li>
              )
            })}
        </ul>
      </Grid>
    </Grid>
  )
}

export default Footer
