import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import {
  add_wishlist_products_async,
  get_wishlist_products_async,
} from "../services/wishlistService"
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification"

const initialState = {
  add_wish_list_data: null,
  add_wish_list_status: asyncStatus.IDLE,
  add_wish_list_error: null,

  get_wish_list_data: null,
  get_wish_list_status: asyncStatus.IDLE,
  get_wish_list_error: null,
}

const wishlist_slice = createSlice({
  name: "wishlistSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**  <!---------- ADD WISHLIST PRODUCTS STATE ----------!>    **/

    builder.addCase(add_wishlist_products_async.pending, (state, actions) => {
      state.add_wish_list_status = asyncStatus.LOADING
    })
    builder.addCase(
      add_wishlist_products_async.fulfilled,
      (state, { payload }) => {
        state.add_wish_list_status = asyncStatus.SUCCEEDED
        state.add_wish_list_error = null
        state.add_wish_list_data = payload.data
        success_toast_message(payload.message)
      },
    )
    builder.addCase(add_wishlist_products_async.rejected, (state, actions) => {
      state.add_wish_list_data = null
      state.add_wish_list_status = asyncStatus.ERROR
      state.add_wish_list_error = actions.error.message
      error_toast_message(actions.error.message)
    })

    /**  <!---------- GET WISHLIST PRODUCTS STATE ----------!>    **/

    builder.addCase(get_wishlist_products_async.pending, (state, actions) => {
      state.get_wish_list_status = asyncStatus.LOADING
    })
    builder.addCase(
      get_wishlist_products_async.fulfilled,
      (state, { payload }) => {
        state.get_wish_list_status = asyncStatus.SUCCEEDED
        state.get_wish_list_data = payload.data
        state.get_wish_list_error = null
      },
    )
    builder.addCase(get_wishlist_products_async.rejected, (state, actions) => {
      state.get_wish_list_status = asyncStatus.ERROR
      state.get_wish_list_data = null
      state.get_wish_list_error = actions.error.message
    })
  },
})

export default wishlist_slice.reducer
