import { createTheme } from "@mui/material/styles"

export const primaryColour = "#FA8232"
export const inputLabel = "#666666"



export const app_mui_theme = createTheme({
    // breakpoints: {
    //   values: {
    //     // xxs: 0,
    //     // xs: 0,
    //     // sm: 400,
    //     // md: 900,
    //     // lg: 1200,
    //     // xl: 1536,
        
    //   },
    // },
    // palette:{
    //     primary:primaryColour,
    //     // secondary:
    // }

  });