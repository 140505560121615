import { Table } from "antd"
import React from "react"



const ProductListTable = ({
  handleTabChange,
  loading,
  pagination,
  dataSource,
  columns,
}) => {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      // pagination={{...pagination}}
      loading={loading}
      // onChange={handleTabChange}
      size={'large'}
    />
  )
}

export default ProductListTable
