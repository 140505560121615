import { Box, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { useState } from "react"
import { LuEye } from "react-icons/lu"
import { LuEyeOff } from "react-icons/lu"
import TextFieldLabel from "../../../components/common/TextField/TextField"
import CustomButton from "../../../components/common/Button/Button"

const Security = () => {
  const [isShow, setIsShow] = useState({
    change_pass: true,
    new_pass: true,
    confirm_pass: true,
  })
  const [changePass, setChangePass] = useState({
    change_pass: "",
    new_pass: "",
    confirm_pass: "",
  })

  const change_password = [
    {
      title: "Current Password",
      placeHolder: "",
      defaultVal: "",
      error: "Please Enter Current Password",
      isValid: false,
      type: isShow.change_pass ? "password" : "text",
      disabled: false,
      onChange: (e) =>
        setChangePass({ ...changePass, change_pass: e.target.value }),
      value: changePass.change_pass,
      suffixIcon: isShow.change_pass ? (
        <LuEye
          color="black"
          size={20}
          sx={{ cursor: "pointer" }}
          onClick={() => setIsShow({ ...isShow, change_pass: false })}
        />
      ) : (
        <LuEyeOff
          color="black"
          size={20}
          sx={{ cursor: "pointer" }}
          onClick={() => setIsShow({ ...isShow, change_pass: true })}
        />
      ),
    },
    {
      title: "New Password",
      placeHolder: "8+ characters",
      defaultVal: "",
      error: "Please Enter New Password",
      isValid: false,
      type: isShow.new_pass ? "password" : "text",
      disabled: false,
      onChange: (e) =>
        setChangePass({ ...changePass, new_pass: e.target.value }),
      value: changePass.new_pass,
      suffixIcon: isShow.new_pass ? (
        <LuEye
          color="black"
          size={20}
          sx={{ cursor: "pointer" }}
          onClick={() => setIsShow({ ...isShow, new_pass: false })}
        />
      ) : (
        <LuEyeOff
          color="black"
          size={20}
          sx={{ cursor: "pointer" }}
          onClick={() => setIsShow({ ...isShow, new_pass: true })}
        />
      ),
    },
    {
      title: "Confirm Password",
      placeHolder: "",
      defaultVal: "",
      error: "Please Enter Current Password",
      isValid: false,
      type: isShow.confirm_pass ? "password" : "text",
      disabled: false,
      onChange: (e) =>
        setChangePass({ ...changePass, confirm_pass: e.target.value }),
      value: changePass.confirm_pass,
      suffixIcon: isShow.confirm_pass ? (
        <LuEye
          color="black"
          size={20}
          sx={{ cursor: "pointer" }}
          onClick={() => setIsShow({ ...isShow, confirm_pass: false })}
        />
      ) : (
        <LuEyeOff
          color="black"
          size={20}
          sx={{ cursor: "pointer" }}
          onClick={() => setIsShow({ ...isShow, confirm_pass: true })}
        />
      ),
    },
  ]
  
  return (
    <Grid
      container
      sx={{
        border: "1px solid rgb(218 213 213)",
        borderRadius: "3px",
      }}
    >
      {/* ------- change password title -------- */}

      <Stack
        sx={{
          px: "27px",
          py: "11px",
          width: "100%",
          borderBottom: "1px solid rgb(218 213 213)",
        }}
        direction={"row"}
        justifyItems={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            color: "#191c1e",
            fontSize: "14px",
            fontWeight: "700",
            letterSpacing: "0.5px",
            lineHeight: "20px",
          }}
          className="security_change_pass"
        >
          CHANGE PASSWORD
        </Typography>
      </Stack>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} p={"25px"}>
        {change_password.map((field, i) => {
          return (
            <TextFieldLabel
              key={i}
              defaultValue={field.defaultVal}
              placeholder={field.placeHolder}
              inputTitle={field.title}
              error={field.error}
              isValid={field.isValid}
              type={field.type}
              onChange={field.onChange}
              disabled={field.disabled}
              value={field.value}
              suffixIcon={field.suffixIcon}
            />
          )
        })}

        <Box
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            borderRadius: "3.5px",
          }}
        >
          <CustomButton>{"CHANGE PASSWORD"}</CustomButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Security
