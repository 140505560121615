import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

const Custom_Modal = ({ open, handleClose, title, children, buttons }) => {
  return (
    <Dialog
      sx={{ zIndex: 100001,overflow:"hidden"}}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontSize: "20px", fontWeight: "600",overflowY:"clip", }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{overflowY:"clip"}}>{children}</DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  )
}

export default Custom_Modal
