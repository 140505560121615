import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const get_all_products_async = createAsyncThunk(
  type_constants.GET_ALL_PRODUCTS,
  async (post_data) => {
    const { page, data } = post_data
    try {
      const response = await apiHandle.post(`search-product/${page ?? 1}`, data)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
export const get_product_by_product_id_async = createAsyncThunk(
  type_constants.GET_PRODUCT_BY_PRODUCT_ID,
  async (post_data) => {
    const { id } = post_data
    try {
      const response = await apiHandle.post(`/get-product-by-id/${id}`)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
export const add_product_async = createAsyncThunk(
  type_constants.ADD_PRODUCT,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/user/add-product`, post_data)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
export const search_product_for_user_async = createAsyncThunk(
  type_constants.SEARCH_PRODUCT_USER,
  async (post_data) => {
    try {
      const response = await apiHandle.post(
        `/user/search-product-seller`,
        post_data,
      )
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
export const get_product_by_user_id_async = createAsyncThunk(
  type_constants.GET_PRODUCT_BY_USER_ID,
  async (post_data) => {
    try {
      const response = await apiHandle.post(
        `/user/get-product-by-user-id`,
        post_data,
      )
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
export const edit_product_async = createAsyncThunk(
  type_constants.EDIT_PRODUCT,
  async (post_data) => {
    try {
      const response = await apiHandle.put(`/user/edit-product`, post_data)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
export const delete_product_async = createAsyncThunk(
  type_constants.DELETE_PRODUCT,
  async (post_data) => {
    const { product_id } = post_data
    try {
      const response = await apiHandle.delete(`/user/del-product/${product_id}`)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
