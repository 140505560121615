import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus.js"

import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification.js"
import { add_buyer_billing_address_async, add_buyer_shipping_address_async, get_buyer_address_async } from "../services/addressService.js"

let initialState = {
  //GET BUYER ADDRESS
  get_buyer_address_status: asyncStatus.IDLE,
  get_buyer_address_data: null,
  get_buyer_address_error: null,

  //ADD BUYER BILLING ADDRESS
  add_buyer_billing_address_status: asyncStatus.IDLE,
  add_buyer_billing_address_data: null,
  add_buyer_billing_address_error: null,

  //ADD BUYER SHIPPING ADDRESS
  add_buyer_shipping_address_status: asyncStatus.IDLE,
  add_buyer_shipping_address_data: null,
  add_buyer_shipping_address_error: null,
}

const address_slice = createSlice({
  name: "addressSlice",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    // GET BUYER ADDRESS
    builder.addCase(get_buyer_address_async.pending, (state, actions) => {
      state.get_buyer_address_status = asyncStatus.LOADING
    })
    builder.addCase(get_buyer_address_async.fulfilled, (state, { payload }) => {
      state.get_buyer_address_status = asyncStatus.SUCCEEDED
      state.get_buyer_address_data = payload.data
      state.get_buyer_address_error = null
    })
    builder.addCase(get_buyer_address_async.rejected, (state, actions) => {
      state.get_buyer_address_status = asyncStatus.ERROR
      state.get_buyer_address_data = null
      state.get_buyer_address_error = actions.error.message
      error_toast_message(actions.error.message)
    })

    // ADD BUYER BILLING ADDRESS
    builder.addCase(
      add_buyer_billing_address_async.pending,
      (state, actions) => {
        state.add_buyer_billing_address_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      add_buyer_billing_address_async.fulfilled,
      (state, { payload }) => {
        state.add_buyer_billing_address_status = asyncStatus.SUCCEEDED
        state.add_buyer_billing_address_data = payload.data
        state.add_buyer_billing_address_error = null
        success_toast_message(payload.message)
      },
    )
    builder.addCase(
      add_buyer_billing_address_async.rejected,
      (state, actions) => {
        state.add_buyer_billing_address_status = asyncStatus.ERROR
        state.add_buyer_billing_address_data = null
        state.add_buyer_billing_address_error = actions.error.message
        error_toast_message(actions.error.message)
      },
    )

    // ADD BUYER SHIPPING ADDRESS
    builder.addCase(
      add_buyer_shipping_address_async.pending,
      (state, actions) => {
        state.add_buyer_shipping_address_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      add_buyer_shipping_address_async.fulfilled,
      (state, { payload }) => {
        state.add_buyer_shipping_address_status = asyncStatus.SUCCEEDED
        state.add_buyer_shipping_address_data = payload.data
        state.add_buyer_shipping_address_error = null
        success_toast_message(payload.message)
      },
    )
    builder.addCase(
      add_buyer_shipping_address_async.rejected,
      (state, actions) => {
        state.add_buyer_shipping_address_status = asyncStatus.ERROR
        state.add_buyer_shipping_address_data = null
        state.add_buyer_shipping_address_error = actions.error.message
        error_toast_message(actions.error.message)
      },
    )
  },
})

export default address_slice.reducer

export const {
  setAuthFalseState,
  setAuthState,
  setResetStatusState,
  setResetOtpStatusState,
  setResetPasswordStatusState,
  setBusinessProfileData,
} = address_slice.actions
