export const session_expired = "@session_expired"

export const save_tokens_constant = "@usertokens"
export const SAVE_CATEGORY_ID = "@category_id"
export const type_constants = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  CHECK_AUTH: "CHECK_AUTH",
  VERIFY_OTP: "VERIFY_OTP",
  CREATE_BUSINESS: "CREATE_BUSINESS",
  RESET_PASSWORD_REQ: "RESET_PASSWORD_REQ",
  RESET_PASSWORD_OTP: "RESET_PASSWORD_OTP",
  RESET_PASSWORD: "RESET_PASSWORD",
  BUYER_AVATAR_IMAGE: "BUYER_AVATAR_IMAGE",
  GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
  GET_PRODUCT_BY_PRODUCT_ID: "GET_PRODUCT_BY_PRODUCT_ID",
  ADD_PRODUCT: "ADD_PRODUCT",
  GET_PRODUCT_BY_USER_ID: "GET_PRODUCT_BY_USER_ID",
  EDIT_PRODUCT: "EDIT_PRODUCT",
  ADD_TO_CART: "ADD_TO_CART",
  GET_CART_PRODUCT: "GET_CART_PRODUCT",
  DELETE_CART_PRODUCT: "DELETE_CART_PRODUCT",
  SEARCH_PRODUCT_USER: "SEARCH_PRODUCT_USER",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_ALL_SUB_CATEGORIES: "GET_ALL_SUB_CATEGORIES",
  GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
  GET_SUB_CATEGORIES_BY_CATEGORY: "GET_SUB_CATEGORIES_BY_CATEGORY",
  GET_REVIEWS: "GET_REVIEWS",
  Add_TO_CART_QTY: "Add_TO_CART_QTY",
  NEGOTIATION: "NEGOTIATION",
  NEGOTIATION_REQUEST_LIST: "NEGOTIATION_REQUEST_LIST",
  NEGOTIATION_REQUEST_BY_ID: "NEGOTIATION_REQUEST_BY_ID",
  ADD_COUNTER_OFFER_BY_BUYER_NEGOTIATION:
    "ADD_COUNTER_OFFER_BY_BUYER_NEGOTIATION",
  NEGOTIATION_COUNTER_OFFER_REFRESH: "NEGOTIATION_COUNTER_OFFER_REFRESH",
  NEGOTIATION_ACTIONS_RESPONSE: "NEGOTIATION_ACTIONS_RESPONSE",
  PLACE_ORDER: "PLACE_ORDER",
  GET_ORDERS: "GET_ORDERS",
  GET_ORDERS_BUYER: "GET_ORDERS_BUYER",
  GET_ORDER_DETAIL_BY_ID: "GET_ORDER_DETAIL_BY_ID",
  GET_ORDER_BUYER_DETAIL_BY_ID: "GET_ORDER_BUYER_DETAIL_BY_ID",
  ADD_BUYER_BILLING_ADDRESS: "ADD_BUYER_BILLING_ADDRESS",
  ADD_BUYER_SHIPPING_ADDRESS: "ADD_BUYER_SHIPPING_ADDRESS",
  GET_BUYER_ADDRESS: "GET_BUYER_ADDRESS",
  ADD_WISHLIST_PRODUCTS: "ADD_WISHLIST_PRODUCTS",
  GET_WISHLIST_PRODUCTS: "GET_WISHLIST_PRODUCTS",
}
