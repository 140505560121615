import AuthContainer from "../../../components/common/AuthContainer/AuthContainer"
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import AuthInput from "../../../components/common/AuthInput/AuthInput"
import CustomButton from "../../../components/common/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { reset_password_req_async } from "../../../store/services/authService"
import { useEffect, useState } from "react"
import { asyncStatus } from "../../../utils/asyncStatus"
import { useNavigate } from "react-router-dom"
import { setResetPasswordStatusState } from "../../../store/slices/auth_slice"

function ForgetPassword() {
  const [email, setEmail] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { resetPasswordData, resetPasswordStatus, resetPasswordError } =
    useSelector((state) => state.auth)
  const loading = resetPasswordStatus === asyncStatus.LOADING
  const status = resetPasswordStatus === asyncStatus.SUCCEEDED
  const submitHandler = () => {
    dispatch(
      reset_password_req_async({
        email,
      }),
    )
  }
  useEffect(() => {
    if (status) {
      navigate("/reset-password-otp")
    }
    dispatch(setResetPasswordStatusState())
  }, [status])

  return (
    <Container>
      <AuthContainer>
        <Stack maxWidth={600} mx={"auto"} gap={2}>
          <Stack>
            <Stack>
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: 40,
                    md: 55,
                  },
                }}
                className="heading"
              >
                Forget Password
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                The Verification Email will be sent to the mailbox. please check
                it !
              </Typography>
            </Stack>
          </Stack>
          <Grid container rowGap={3} mt={2}>
            <Grid item xs={12}>
              <AuthInput
                onChange={(e) => setEmail(e.target.value)}
                label={"Email"}
              />
            </Grid>

            <Grid item xs={12}>
              {resetPasswordError && (
                <Typography>{resetPasswordError}</Typography>
              )}
              {resetPasswordData && (
                <Typography>{resetPasswordData?.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                onClick={submitHandler}
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: 17,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: email === "" && "lightgrey",
                }}
                disable={email === ""}
              >
                {loading ? (
                  <CircularProgress size={22} sx={{ color: "white" }} />
                ) : (
                  " Send"
                )}
              </CustomButton>
            </Grid>
          </Grid>
        </Stack>
      </AuthContainer>
    </Container>
  )
}

export default ForgetPassword
