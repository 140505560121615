import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import { save_tokens_constant } from "../constant"
import {
  buyer_avatar_image_async,
  check_auth_async,
  login_async,
  reset_password_create_async,
  reset_password_otp_verify_async,
  reset_password_req_async,
  signup_async,
  verify_otp_async,
} from "../services/authService.js"
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification"

let initialState = {
  userData: null,
  status: asyncStatus.IDLE,
  userAuth: false,
  check_auth_status: asyncStatus.IDLE,
  check_auth_data: null,
  error: null,
  login_status: asyncStatus.IDLE,

  //BUSINESS PROFILE
  businessData: null,
  business_profile_status: asyncStatus.IDLE,
  business_profile_error: null,

  //OTP
  otpVerify: false,
  otpStatus: asyncStatus.IDLE,
  otpError: null,

  //RESET PASSWORD
  resetPasswordData: null,
  resetPasswordStatus: asyncStatus.IDLE,
  resetPasswordError: null,

  //RESET PASSWORD OTP
  reset_otp_status: asyncStatus.IDLE,
  reset_otp_data: null,
  reset_otp_error: null,

  //RESET PASSWORD
  reset_status: asyncStatus.IDLE,
  reset_data: null,
  reset_error: null,

  //RESET PASSWORD
  buyer_avatar_image: null,
  buyer_avatar_image_status: asyncStatus.IDLE,
  buyer_avatar_image_error: null,
}

const auth_slice = createSlice({
  name: "userAuth",
  initialState,

  reducers: {
    setAuthFalseState(state, actions) {
      state.userAuth = false
    },
    setResetStatusState(state, actions) {
      state.reset_status = asyncStatus.IDLE
    },
    setResetOtpStatusState(state, actions) {
      state.reset_otp_status = asyncStatus.IDLE
    },
    setResetPasswordStatusState(state, actions) {
      state.resetPasswordStatus = asyncStatus.IDLE
    },
    setAuthState(state, { payload }) {
      state.userAuth = payload
      state.check_auth_status = asyncStatus.SUCCEEDED
    },
    setBusinessProfileData(state, { payload }) {
      state.userData = { ...state.userData, seller_business_profile: payload }
    },
  },
  extraReducers: (builder) => {
    // SIGNUP

    builder.addCase(signup_async.pending, (state, action) => {
      state.status = asyncStatus.LOADING
    })
    builder.addCase(signup_async.fulfilled, (state, { payload }) => {
      state.userAuth = true
      state.userData = payload.data
      state.status = asyncStatus.SUCCEEDED
      localStorage.setItem(save_tokens_constant, payload.tokens.access_token)
      success_toast_message(payload.message)
    })
    builder.addCase(signup_async.rejected, (state, actions) => {
      state.status = asyncStatus.ERROR
      state.userAuth = false
      state.error = actions?.error?.message
      error_toast_message(actions?.error?.message)
    })

    //VERIFY OTP

    builder.addCase(verify_otp_async.pending, (state, action) => {
      state.otpStatus = asyncStatus.LOADING
    })
    builder.addCase(verify_otp_async.fulfilled, (state, { payload }) => {
      state.otpStatus = asyncStatus.SUCCEEDED
      state.otpVerify = true
      state.userAuth = true
      success_toast_message("Verify Successfully")
    })
    builder.addCase(verify_otp_async.rejected, (state, actions) => {
      state.otpStatus = asyncStatus.ERROR
      state.otpVerify = false
      state.otpError = actions?.error?.message
      // error_toast_message(actions?.error?.message)
    })

    // LOGIN

    builder.addCase(login_async.pending, (state, action) => {
      state.login_status = asyncStatus.LOADING
    })
    builder.addCase(login_async.fulfilled, (state, { payload }) => {
      state.userAuth = true
      state.userData = payload.data
      state.login_status = asyncStatus.SUCCEEDED
      state.otpVerify = payload.data.verified
      localStorage.setItem(save_tokens_constant, payload.tokens.access_token)
      success_toast_message("login Successfully")
    })
    builder.addCase(login_async.rejected, (state, actions) => {
      state.login_status = asyncStatus.ERROR
      state.userAuth = false
      state.error = actions?.error?.message
      error_toast_message(actions?.error?.message)

      // state.user = null
    })

    //RESET PASSWORD REQUEST

    builder.addCase(reset_password_req_async.pending, (state, action) => {
      state.resetPasswordStatus = asyncStatus.LOADING
    })
    builder.addCase(
      reset_password_req_async.fulfilled,
      (state, { payload }) => {
        state.resetPasswordData = payload
        state.resetPasswordStatus = asyncStatus.SUCCEEDED
      },
    )
    builder.addCase(reset_password_req_async.rejected, (state, actions) => {
      state.resetPasswordStatus = asyncStatus.ERROR
      state.resetPasswordError = actions?.error?.message
      state.resetPasswordData = null
    })

    // CHECK AUTHENTICATION

    builder.addCase(check_auth_async.pending, (state, action) => {
      state.check_auth_status = asyncStatus.LOADING
    })
    builder.addCase(check_auth_async.fulfilled, (state, { payload }) => {
      state.userData = payload.data
      state.check_auth_status = asyncStatus.SUCCEEDED
      state.userAuth = payload.success
      state.otpVerify = payload.data.verified
    })
    builder.addCase(check_auth_async.rejected, (state, actions) => {
      state.check_auth_status = asyncStatus.ERROR
      state.userData = null
    })

    // Reset password otp verify

    builder.addCase(
      reset_password_otp_verify_async.pending,
      (state, action) => {
        state.reset_otp_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      reset_password_otp_verify_async.fulfilled,
      (state, { payload }) => {
        state.reset_otp_status = asyncStatus.SUCCEEDED
        state.reset_otp_data = payload.data
      },
    )
    builder.addCase(
      reset_password_otp_verify_async.rejected,
      (state, actions) => {
        state.reset_otp_status = asyncStatus.ERROR
        error_toast_message(actions.error.message)
      },
    )

    // Reset password

    builder.addCase(reset_password_create_async.pending, (state, action) => {
      state.reset_status = asyncStatus.LOADING
    })
    builder.addCase(
      reset_password_create_async.fulfilled,
      (state, { payload }) => {
        state.reset_status = asyncStatus.SUCCEEDED
        state.reset_data = payload.data
        success_toast_message("Password changed successfully")
      },
    )
    builder.addCase(reset_password_create_async.rejected, (state, actions) => {
      state.reset_status = asyncStatus.ERROR
    })

    // buyer avatar image upload

    builder.addCase(buyer_avatar_image_async.pending, (state, action) => {
      state.buyer_avatar_image_status = asyncStatus.LOADING
    })
    builder.addCase(
      buyer_avatar_image_async.fulfilled,
      (state, { payload }) => {
        state.buyer_avatar_image_status = asyncStatus.SUCCEEDED
        state.buyer_avatar_image = payload.data
        if (state.userData) {
          state.userData = {
            ...state.userData,
            profile_avatar: payload?.data?.profile_avatar,
          }
        }
        success_toast_message(payload?.message)
      },
    )
    builder.addCase(buyer_avatar_image_async.rejected, (state, actions) => {
      state.buyer_avatar_image_status = asyncStatus.ERROR
      state.buyer_avatar_image_error = actions.error.message
      state.buyer_avatar_image = null
      error_toast_message(actions?.error?.message)
    })
  },
})

export default auth_slice.reducer

export const {
  setAuthFalseState,
  setAuthState,
  setResetStatusState,
  setResetOtpStatusState,
  setResetPasswordStatusState,
  setBusinessProfileData,
} = auth_slice.actions
