import css from  "./input.module.css";

const Input = ({
  onChange,
  placeholder,
  style,
  id,
  type,
  defaultValue,
  disabled,
  value
}) => {
  return (
    <input
      className={css.input}
      type={type}
      value={value}
      style={{ ...style }}
      disabled={disabled}
      onChange={onChange}
      defaultValue={defaultValue}
      placeholder={placeholder && placeholder}
      id={id}
    />
  )
}

export default Input
