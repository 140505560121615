import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import { get_reviews_async } from "../services/reviewsService"

const initialState = {
  get_reviews_data: null,
  get_reviews_status: asyncStatus.IDLE,
  get_reviews_error: null,
}

const reviews_slice = createSlice({
  name: "reviews_slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(get_reviews_async.pending, (state, { payload }) => {
      state.get_reviews_status = asyncStatus.LOADING
    })
    builder.addCase(get_reviews_async.fulfilled, (state, { payload }) => {
      state.get_reviews_status = asyncStatus.SUCCEEDED
      state.get_reviews_data = payload
      state.get_reviews_error = null
    })
    builder.addCase(get_reviews_async.rejected, (state, actions) => {
      state.get_reviews_status = asyncStatus.ERROR
      state.get_reviews_error = actions.error.message
    })
  },
})

export default reviews_slice.reducer
