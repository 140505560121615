import React from "react"
import { Select, Typography } from "antd"
import css from "./SelectField.module.css"

const SelectField = ({
  isValid = false,
  value,
  selectTitle,
  placeHolder,
  onChange,
  onSearch,
  optionArr,
  error,
  style,
  defaultVal,
  select_style,
  disabled = false,
}) => {
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  return (
    <div className={css.select_wrapper} style={select_style} >
      {selectTitle ? (
        <Typography.Paragraph className={css.select_title}>
          {selectTitle}
        </Typography.Paragraph>
      ) : (
        ""
      )}
      <Select
        className={css.select_field}
        showSearch
        placeholder={placeHolder}
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={optionArr}
        style={style}
        status={isValid ? "error" : ""}
        disabled={disabled}
        defaultValue={ defaultVal ?? ""}
        value={value}
      />
      {isValid && (
        <Typography.Paragraph
          style={{ color: isValid ? "red" : "black" }}
          level={5}
        >
          {error}
        </Typography.Paragraph>
      )}
    </div>
  )
}

export default SelectField
