import React, { useState, useEffect } from "react"
import {  Container, Grid } from "@mui/material"
import DashboardSideBar from "../../components/dashboardSideBar/DashboardSideBar"
import {  seller_dashboard_routes } from "../../utils/routeList"
import { Route, Routes } from "react-router-dom"
import SellerDashboard from "./Dashboard/Dashboard"
import Navbar from "../../components/common/navbar/Navbar"
import { GoHome } from "react-icons/go"
import Breadcrumb from "../../components/breadCrumb/BreadCrumb"
import DashboardTitle from "../../components/dashboardTitle/DashboardTitle"


    


const SellerDashboardMain = () => {

    const [inner_width, setInner_width] = useState(window.innerWidth)


    const breadCrumbsRoute = [
        {
          name: "Home",
          icon: (
            <GoHome
              sx={{
                fontSize: "15px",
                color: "rgba(95, 108, 114, 1)",
              }}
            />
          ),
          linkTo: "/",
          element: <></>,
        },
    
        ...seller_dashboard_routes,
      ]


    useEffect(() => {
        const handleWidth = () => {
          setInner_width(window.innerWidth)
        }
        window.addEventListener("resize", handleWidth)
    
        return () => window.addEventListener("resize", handleWidth)
      }, [])

    
      return (
        <>
          <Navbar />
         
          <DashboardTitle title={"Seller Dashboard"}/>
          <Breadcrumb breadCrumbsRoutes={breadCrumbsRoute} rootDashboardRout={"seller-dashboard"} />
          <Container sx={{ my: 2 }}>
            <Grid container alignItems={"start"}>
               {/** <!-------- DASHBOARD SIDEBAR  --------!>  **/}
              <Grid
                item
                xl={2.5}
                lg={2.5}
                md={2.5}
                sm={3}
                xs={false}
                sx={{ my: 2 }}
                display={inner_width <= 900 ? "none" : "block"}
              >
                <DashboardSideBar nestedRoutes={seller_dashboard_routes} rootDashboardRout={"seller-dashboard"} />
              </Grid>

              {/** <!-------- DASHBOARD PAGES ROUTES --------!>  **/}
    
              <Grid
                container
                item
                md={9}
                xs={12}
                sm={12}
                m={2}
                flexWrap={"wrap"}
              >
                <Routes>
                  <Route index element={<SellerDashboard/>} />
                  {seller_dashboard_routes.map((screen, i) => {
                    return (
                       <Route
                        key={i}
                        path={screen.linkTo}
                        element={screen.element}
                      />
                    )
                  })}
                </Routes>
              </Grid>
            </Grid>
          </Container>
        </>
      )
}

export default SellerDashboardMain;