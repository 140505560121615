import React, { useEffect } from "react"
import css from "./OrderList.module.css"
import { Box, Grid, Stack, Typography } from "@mui/material"
import CustomButton from "../../../components/common/Button/Button"
import { FiPlus } from "react-icons/fi"
import { MdOutlineDateRange } from "react-icons/md"
import { BiSlider } from "react-icons/bi"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"
import { LuPencil } from "react-icons/lu"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { get_product_by_user_id_async } from "../../../store/services/productsService"
import { set_product_status } from "../../../store/slices/product_slice"
import SelectField from "../../../components/common/SelectField/SelectField"
import { asyncStatus } from "../../../utils/asyncStatus"
import { primaryColour } from "../../../utils/colorTheme"
import { get_orders_async } from "../../../store/services/ordersService"

const ProductComponent = (props) => {
  const {
    product_data: { image, title },
    number_of_items,
  } = props
  return (
    <Stack spacing={1} direction={"row"}>
      <Stack
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#EFF0EB",
          height: 44,
          width: 44,
          objectFit: "contain",
          p: "4px",
        }}
      >
        <img
          height={"100%"}
          width={"100%"}
          src={image ?? ""}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>{title ?? ""}</Typography>
        <Typography className={css.products_qty}>
          {number_of_items > 1 &&
            `+${number_of_items - 1 ?? ""} other products`}
        </Typography>
      </Stack>
    </Stack>
  )
}

const OrderList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { get_orders_status, get_orders_data } =
    useSelector((state) => state.orders_slice)
  const orderListLoading = get_orders_status === asyncStatus.LOADING

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_no",
      render: (order_id) => (
        <Typography className={css.order_id} sx={{ color: primaryColour }}>
          #{order_id ?? ""}
        </Typography>
      ),
    },
    {
      title: "Product",
      dataIndex: "",
      render: (props) => {
        return (
          <>
            <ProductComponent {...props} />
          </>
        )
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (date) => (
        <Typography className={css.date}>{date.split("T")[0] ?? ""}</Typography>
      ),
    },

    {
      title: "Customer",
      dataIndex: "buyer_details",
      render: ({ full_name, email }) => {
        return (
          <>
            <Typography className={css.image_title}>
              {full_name ?? ""}
            </Typography>
            <Typography className={css.products_qty}>{email ?? ""}</Typography>
          </>
        )
      },
    },
    {
      title: "Total",
      dataIndex: "total_amount",
      render: (total) => (
        <Typography className={css.amount}>${total ?? ""}</Typography>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment_method",
      render: (payment) => (
        <Typography className={css.price} sx={{ textTransform: "capitalize" }}>
          {payment ?? ""}
        </Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "order_status",
      render: (status) => {
        const isPending = status === "pending"
        const isShiped = status === "shiped"
        const isDelivered = status === "delivered"
        return (
          <Typography
            className={css.status_css}
            sx={{
              backgroundColor: isPending
                ? "#FDF1E8"
                : isShiped
                ? "#E8F8FD"
                : isDelivered
                ? "#E7F4EE"
                : "#FEEDEC",
              color: isPending
                ? "#E46A11"
                : isShiped
                ? "#13B2E4"
                : isDelivered
                ? "#0D894F"
                : "#F04438",
              textTransform: "capitalize",
            }}
          >
            {status ?? ""}
          </Typography>
        )
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (id) => {
        return (
          <Box className={css.actions_css}>
            <MdOutlineRemoveRedEye
              className={css.icon}
              onClick={() => navigate(`/seller-dashboard/order/order-details/${id}`)}
            />
            {/* <LuPencil className={css.icon} onClick={() => id} /> */}
          </Box>
        )
      },
    },
  ]


  useEffect(() => {
    dispatch(set_product_status())
    dispatch(get_product_by_user_id_async())
    dispatch(get_orders_async())
  }, [])

  const duration_filterArr = {
    defaultVal: "All Time",
    onChange: () => {},
    onSearch: () => {},
    durationArr: [
      { value: "All Time", label: "All Time" },
      { value: "12 Months", label: "12 Months" },
      { value: "30 Days", label: "30 Days" },
      { value: "7 Days", label: "7 Days" },
      { value: "24 Hours", label: "24 Hours" },
    ],
  }

  return (
    <Box width={1}>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          alignItems={"center"}
          width={{ xl: 0.7, lg: 0.7, md: 0.7, sm: 0.7, xs: 1 }}
        >
          <Typography className={css.title}>Order</Typography>
        </Grid>
        {/* ---- export button and add product ---- */}
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <CustomButton
              style={{
                border: "1px solid #FF9900",
                borderRadius: "8px",
              }}
              onClick={() => {
                navigate("/seller-dashboard/order-details")
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FiPlus size={20} />
                <Typography
                  className={css.export_add_btn}
                  sx={{ color: "white" }}
                >
                  Add Order
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>

      {/* ---- search filter and select Date btn and filter btn ---- */}

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Grid item xl={3} lg={3} md={3} sm={3} xs={6} alignItems={"center"}>
          <SelectField
            selectTitle={duration_filterArr.tit}
            onChange={() => {}}
            optionArr={duration_filterArr.durationArr}
            style={{ height: "43px", borderRadius: "8px", borderColor: "red" }}
            defaultVal={duration_filterArr.defaultVal}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MdOutlineDateRange size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Select Dates
                </Typography>
              </Stack>
            </CustomButton>

            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <BiSlider size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Filters
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>

      <Box
        className={`${css.table_wrapper} tabled`}
        width={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 0.95 }}
      >
        <ProductListTable
          // handleTabChange={handleTabChange}
          loading={orderListLoading}
          // pagination={tableParams.pagination}
          dataSource={get_orders_data}
          columns={columns}
        />
      </Box>
    </Box>
  )
}

export default OrderList
