import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import {
  get_all_categories_async,
  get_all_sub_categories_async,
  get_sub_categories_by_category_id_async,
} from "../services/categoriesService"

const initialState = {
  data: null,
  categories_data: [],
  get_sub_categories_by_category_id_data: [],

  status: asyncStatus.IDLE,
  categories_status: asyncStatus.IDLE,
  get_sub_categories_by_category_id_status: asyncStatus.IDLE,

  error: null,
  categories_error: null,
  get_sub_categories_by_category_id_error: null,
}

const categories_manage_slice = createSlice({
  name: "categoriesManageSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {

    // get all sub categories

    builder.addCase(
      get_all_sub_categories_async.pending,
      (state, { payload }) => {
        state.status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      get_all_sub_categories_async.fulfilled,
      (state, { payload }) => {
        state.data = payload.data
        state.status = asyncStatus.SUCCEEDED
        state.error = null
      },
    )
    builder.addCase(get_all_sub_categories_async.rejected, (state, actions) => {
      state.data = null
      state.status = asyncStatus.ERROR
      state.error = actions?.error?.message
    })

    // get all categories

    builder.addCase(get_all_categories_async.pending, (state, { payload }) => {
      state.categories_status = asyncStatus.LOADING
    })
    builder.addCase(
      get_all_categories_async.fulfilled,
      (state, { payload }) => {
        state.categories_data = payload.data
        state.categories_status = asyncStatus.SUCCEEDED
        state.categories_error = null
      },
    )
    builder.addCase(get_all_categories_async.rejected, (state, actions) => {
      state.data = null
      state.categories_status = asyncStatus.ERROR
      state.categories_error = actions?.error?.message
    })

    // get sub-category by category id

    builder.addCase(get_sub_categories_by_category_id_async.pending, (state, { payload }) => {
      state.get_sub_categories_by_category_id_status = asyncStatus.LOADING
    })
    builder.addCase(
      get_sub_categories_by_category_id_async.fulfilled,
      (state, { payload }) => {
        state.get_sub_categories_by_category_id_data = payload.data
        state.get_sub_categories_by_category_id_status = asyncStatus.SUCCEEDED
        state.get_sub_categories_by_category_id_error = null
      },
    )
    builder.addCase(get_sub_categories_by_category_id_async.rejected, (state, actions) => {
      state.get_sub_categories_by_category_id_data = null
      state.get_sub_categories_by_category_id_status = asyncStatus.ERROR
      state.get_sub_categories_by_category_id_error = actions?.error?.message
    })
  },
})

export default categories_manage_slice.reducer
