import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const add_wishlist_products_async = createAsyncThunk(
  type_constants.ADD_WISHLIST_PRODUCTS,
  async (wishlist_data) => {
    try {
      const response = await apiHandle.post(`/user/wishlist`, wishlist_data)
      return response.data
    } catch (error) {
      console.log("THE ALL ADD WISHLIST ERRORS ====>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_wishlist_products_async = createAsyncThunk(
  type_constants.GET_WISHLIST_PRODUCTS,
  async () => {
    try {
      const response = await apiHandle.get(`/user/wishlist`)
      return response.data
    } catch (error) {
      console.log("THE ALL GET WISHLIST ERRORS ====>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
