import React, { useEffect, useState } from "react"
import { Stack } from "@mui/material"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useLocation, useNavigate } from "react-router-dom"
import css from "./DashboardSideBar.module.css"
import { exit_session } from "../../config/apiHandle/apiHandle"
import { success_toast_message } from "../../utils/toast_notification"

const DashboardSideBar = ({ nestedRoutes, rootDashboardRout }) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    // handle width
    const handleResize = () => {
      setInnerWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.addEventListener("resize", handleResize)
    }
  }, [])

  const navigate = useNavigate()

  const location = useLocation() // Get the current URL location
  const currentPath = location.pathname // Get the current path from the URL

  const openPage = (page) => {
    navigate(`/${rootDashboardRout}/${page}`)
  }

  const submit = () => {
    exit_session()
    success_toast_message("Logout successfully")
  }

  return (
    <Stack
      sx={{
        background: "#FFF",
        boxShadow: "0px 0px 24px -14px grey",
        border: "1px solid rgb(223 226 227)",
        borderRadius: "7px",
      }}
    >
      {innerWidth <= 800 ? (
        ""
      ) : (
        <List>
          {nestedRoutes?.map((routes, i) => {
            let current_path_active = currentPath.split("/")[2] || ""
            let isCurrentPath = current_path_active === routes.linkTo
            let isLogout = routes.name === "Log-out"
            return (
              routes.menu_item && (
                <ListItem
                  key={i}
                  onClick={() =>
                    isLogout ? submit() : openPage(routes.linkTo)
                  }
                  sx={{
                    backgroundColor: isLogout
                      ? "white"
                      : isCurrentPath
                      ? "rgba(250, 130, 50, 1)"
                      : "white",
                    color: isLogout ? "rgba(95, 108, 114, 1)" : isCurrentPath ? "white" : "rgba(95, 108, 114, 1)",
                  }}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        color: isLogout ? "rgba(95, 108, 114, 1)" : isCurrentPath
                          ? "white"
                          : "rgba(95, 108, 114, 1)",
                        fontSize: "20px",
                      }}
                    >
                      {routes.icon}
                    </ListItemIcon>
                    <ListItemText
                      className={css.rout_name}
                      primary={routes.name}
                    />
                  </ListItemButton>
                </ListItem>
              )
            )
          })}
        </List>
      )}
    </Stack>
  )
}

export default DashboardSideBar
