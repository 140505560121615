import React, { useState } from "react"
import css from "./AddProducts.module.css"
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import TextFieldLabel from "../../../components/common/TextField/TextField"
import { BiDollar } from "react-icons/bi"
import { Upload_image_component } from "../../../components/UploadFile/UploadFile"
import axios from "axios"
import { baseURL } from "../../../config/apiHandle/apiHandle"
import { ImImages } from "react-icons/im"
import ClearIcon from "@mui/icons-material/Clear"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import circle from "../../../assets/circle-image.png"
import { useDispatch, useSelector } from "react-redux"
import SelectField from "../../../components/common/SelectField/SelectField"
import CustomCheckbox from "../../../components/common/customCheckbox/CustomCheckbox"
import CustomButton from "../../../components/common/Button/Button"
import { RxCross2 } from "react-icons/rx"
import { FiPlus } from "react-icons/fi"
import {
  get_all_categories_async,
  get_all_sub_categories_async,
  get_sub_categories_by_category_id_async,
} from "../../../store/services/categoriesService"
import { useEffect } from "react"
import { asyncStatus } from "../../../utils/asyncStatus"
import { add_product_async } from "../../../store/services/productsService"
import { ChromePicker, SketchPicker } from "react-color"
import { useNavigate } from "react-router-dom"

const AddProducts = () => {
  const dispatch = useDispatch()

  const [submitData, setSubmitData] = useState({})
  const [postImage, setPostImage] = useState([])
  const [categories, setCategories] = useState("")
  const [Subcategories, setSubcategories] = useState({})
  const [Color, setColor] = useState([])
  const [selectedImg, setSelectedImg] = useState()
  const [features, setFeatures] = useState([])
  const [addFeaturesData, setAddFeaturesData] = useState("")
  const [allSizes, setAllSizes] = useState([])
  const [addSizesData, setAddSizesData] = useState("")
  const [renderingObject, setRenderingObject] = useState({})

  const navigate = useNavigate()

  const fields_style_obj = {
    backgroundColor: "#F9F9FC",
    borderRadius: "8px",
    border: "1px solid #E0E2E7",
  }
  const categoryFields = [
    {
      title: "Product Category",
      placeHolder: "Select a category",
      error: "Please provide a category",
      isValid: false,
      disabled: false,
      onChange: () => {},
      onSearch: () => {},
      optionArr: [
        { value: "Bangladesh", label: "Bangladesh" },
        { value: "India", label: "India" },
        { value: "Srilanka", label: "Srilanka" },
        { value: "Pakistan", label: "Pakistan" },
      ],
    },
    {
      title: "Product Sub Category",
      placeHolder: "Select a sub category",
      error: "Please provide a sub category",
      isValid: false,
      disabled: false,
      onChange: () => {},
      onSearch: () => {},
      optionArr: [
        { value: "Bangladesh", label: "Bangladesh" },
        { value: "India", label: "India" },
        { value: "Srilanka", label: "Srilanka" },
        { value: "Pakistan", label: "Pakistan" },
      ],
    },
  ]
  useEffect(() => {
    dispatch(get_all_categories_async())
    dispatch(get_all_sub_categories_async())
  }, [])

  const {
    categories_data,
    data,
    status,
    categories_status,
    get_sub_categories_by_category_id_data,
    get_sub_categories_by_category_id_status,
  } = useSelector((state) => state.categoriesManageSlice)

  const { add_product_data, add_product_data_status, add_product_data_error } =
    useSelector((state) => state.product)

  const { userData, userAuth } = useSelector((state) => state.auth)

  const upload_image = async (files) => {
    try {
      let uploadedUrlArray = []
      await Promise.all(
        Array.from(files).map(async (file) => {
          const formData = new FormData()
          formData.append("binary_file", file)
          try {
            const response = await axios.post(
              `${baseURL}upload-files`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            )
            uploadedUrlArray.push(response.data.url)
          } catch (err) {
            console.error(err)
          }
        }),
      )
      setPostImage((prevPostImages) => [...prevPostImages, ...uploadedUrlArray])
    } catch (error) {
      console.error(error)
    }
  }
  const removeImage = (ind) => {
    let a = [...postImage]
    a.splice(ind, 1)
    setPostImage([...a])
  }
  const removeColor = (ind) => {
    let update = [...Color]
    update.splice(ind, 1)
    setColor([...update])
  }

  const onChange_handle = (key, value) => {
    setSubmitData({ ...submitData, [key]: value })
  }

  const onChange_select = (id, type) => {
    if (type === "category") {
      dispatch(get_sub_categories_by_category_id_async({ category_id: id }))
    } else {
      dispatch(
        get_sub_categories_by_category_id_async({
          category_id: Subcategories.category_id,
        }),
      )
      let selected =
        get_sub_categories_by_category_id_data[0]?.sub_categories?.find(
          (x) => x._id === id,
        )
      setRenderingObject(selected)
      setSubcategories({ ...Subcategories, sub_category_id: id })
    }
  }

  const selectImage = (ind) => {
    setSelectedImg(ind)
  }

  const addFeatures = () => {
    setFeatures([...features, addFeaturesData])
    setAddFeaturesData("")
  }
  const removefeature = (ind) => {
    let update = [...features]
    update.splice(ind, 1)
    setFeatures([...update])
  }

  const addSize = () => {
    setAllSizes([...allSizes, addSizesData])
    setAddSizesData("")
  }
  const removeSize = (ind) => {
    let update = [...allSizes]
    update.splice(ind, 1)
    setAllSizes([...update])
  }

  const add_product_func = () => {
    let obj = {
      ...Subcategories,
      ...submitData,
      color: [...Color],
      images: [...postImage],
      feature_image: selectedImg,
      features: features,
      size: allSizes,
      user_id: userData._id,
      sub_category_title: renderingObject.sub_category_title,
      in_stock: true,
    }
    dispatch(add_product_async(obj))
  }

  if (add_product_data_status === asyncStatus.SUCCEEDED) {
    navigate("/seller-dashboard/productslist")
  }

  return (
    <Grid width={1}>
      <Typography className={css.title}>Add Product</Typography>

      {/* ---------general_info_section----- */}

      {renderingObject?.title?.is_applicable ? (
        <Grid className={css.general_info_wrapper}>
          <Box className={css.general_title}>
            <Typography>General Information</Typography>
          </Box>

          <TextFieldLabel
            inputTitle={"Product Name"}
            placeholder={"Type product name here. . ."}
            // value={""}
            onChange={(e) => onChange_handle("title", e.target.value)}
            disabled={false}
            error={"Please provide product name"}
            type={"text"}
            style={fields_style_obj}
            title_style={{
              color: "#4D5464",
              fontSize: "14px",
              fontWeight: "500",
            }}
          />
        </Grid>
      ) : (
        ""
      )}
      {renderingObject?.description?.is_applicable ? (
        <Grid className={css.general_info_wrapper}>
          <TextFieldLabel
            inputTitle={"Description"}
            placeholder={"Type product description here. . ."}
            // value={""}
            onChange={(e) => onChange_handle("description", e.target.value)}
            disabled={false}
            error={"Please provide a product description"}
            type={"textarea"}
            style={fields_style_obj}
            title_style={{
              color: "#4D5464",
              fontSize: "12px",
              fontWeight: "500",
            }}
          />
        </Grid>
      ) : (
        ""
      )}

      {/* ---------media_section----- */}
      {renderingObject?.images?.is_applicable ? (
        <>
          <Grid item className={css.general_info_wrapper}>
            <Box className={css.general_title}>
              <Typography>Media</Typography>
            </Box>
            <Box>
              <Stack bgcolor={"#F9F9FC"} width={"100%"}>
                <Grid
                  container
                  gap={2}
                  alignItems={"center"}
                  px={2}
                  py={1}
                  flexWrap={{
                    xl: "wrap",
                    lg: "wrap",
                    md: "wrap",
                    sm: "wrap",
                    xs: "wrap",
                  }}
                >
                  {postImage.map((e, i) => {
                    return (
                      <Grid
                        item
                        xl={2}
                        lg={2.2}
                        md={2.4}
                        sm={3}
                        xs={5.7}
                        sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#E8E7E3",
                          borderRadius: "10px",
                        }}
                        px={"5px"}
                        py={"2px"}
                        key={i}
                      >
                        <img
                          style={{
                            height: 110,
                            width: 110,
                            objectFit: "contain",
                          }}
                          src={e}
                        />
                        <IconButton
                          sx={{
                            color: "white",
                            backgroundColor: "grey",
                            position: "absolute",
                            top: 5,
                            right: 5,
                            cursor: "pointer",
                            height: "25px",
                            width: "25px",
                          }}
                        >
                          <ClearIcon
                            onClick={() => removeImage(i)}
                            color="black"
                          />
                        </IconButton>
                      </Grid>
                    )
                  })}
                  <IconButton
                    component="label"
                    sx={{
                      color: "black",
                      padding: 1,
                      borderRadius: 2,
                      ml: 1,
                    }}
                  >
                    <Stack alignItems={"center"} justifyContent={"center"}>
                      <img width={60} src={circle} />
                      <input
                        type="file"
                        onChange={(e) => upload_image(e.target.files)}
                        multiple
                        hidden
                      />
                      <Typography color={"lightgray"}>
                        Click to add images
                      </Typography>
                    </Stack>
                  </IconButton>
                </Grid>
              </Stack>
              <Box></Box>
            </Box>
          </Grid>
          {/* ---------feature_image_section----- */}
          <Grid item className={css.general_info_wrapper}>
            <Box className={css.general_title}>
              <Typography>Feature Image</Typography>
              <Typography sx={{ fontSize: "15px", my: 0.5 }}>
                Select a feature image
              </Typography>
            </Box>
            <Box>
              <Stack bgcolor={"#F9F9FC"} width={"100%"}>
                <Grid container gap={2} alignItems={"center"} p={1}>
                  {postImage.map((e, i) => {
                    return (
                      <Grid
                        item
                        xl={2}
                        lg={2.2}
                        md={2.4}
                        sm={3}
                        xs={5.7}
                        sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#E8E7E3",
                          borderRadius: "10px",
                        }}
                        px={"5px"}
                        py={"2px"}
                        key={i}
                      >
                        <img
                          style={{
                            height: 110,
                            width: 110,
                            objectFit: "contain",
                          }}
                          src={e}
                        />
                        <CheckCircleIcon
                          onClick={() => selectImage(i)}
                          color={selectedImg === i ? "success" : "disabled"}
                          sx={{
                            fontSize: 30,
                            position: "absolute",
                            zIndex: 101,
                            top: 5,
                            right: 5,
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Stack>
            </Box>
          </Grid>
        </>
      ) : (
        ""
      )}

      {/* ---------Category_section----- */}
      <Grid
        container
        alignItems={"center"}
        className={css.general_info_wrapper}
      >
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={css.general_title}
        >
          <Typography>Category</Typography>
        </Grid>

        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={{
            xl: "nowrap",
            lg: "nowrap",
            md: "nowrap",
            sm: "wrap",
            xs: "wrap",
          }}
          gap={2}
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <SelectField
              selectTitle={"Categories"}
              placeHolder={"Choose category"}
              onChange={(e) => onChange_select(e, "category")}
              optionArr={categories_data.map((elem) => ({
                value: elem._id,
                label: elem.category_title,
              }))}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <SelectField
              selectTitle={"Sub-categories"}
              placeHolder={"Choose sub category"}
              onChange={(e) => onChange_select(e, "sub-category")}
              optionArr={get_sub_categories_by_category_id_data[0]?.sub_categories.map(
                ({ sub_category_title, _id }) => ({
                  value: _id,
                  label: sub_category_title,
                }),
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* ---------color section----- */}

      {renderingObject?.color?.is_applicable ? (
        <Grid item className={css.general_info_wrapper}>
          <Box className={css.general_title}>
            <Typography>Color</Typography>
          </Box>
          <Stack
            flexWrap={"wrap"}
            sx={{ overflowX: "auto" }}
            direction={"row"}
            gap={1.5}
            my={3}
          >
            {Color?.map((e, i) => {
              return (
                <>
                  <Stack>
                    <Stack position={"relative"}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: 100,
                          backgroundColor: e,
                        }}
                      ></Box>
                    </Stack>
                    <Stack position={"absolute"}>
                      <ClearIcon
                        onClick={() => removeColor(i)}
                        color="error"
                        sx={{
                          fontSize: 20,
                          bgcolor: "white",
                          position: "absolute",
                          // zIndex: 101,
                          top: -7,
                          left: 20,
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      />
                    </Stack>
                  </Stack>
                </>
              )
            })}
          </Stack>
          <Box>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <label for="Select Color">Add color</label>
              <input
                type="color"
                id="select_color"
                onChange={(e) => setColor([...Color, e.target.value])}
              />
            </Stack>
          </Box>
        </Grid>
      ) : (
        ""
      )}

      {/* ---------Pricing_section----- */}
      {renderingObject?.description?.is_applicable ? (
        <Grid item className={css.general_info_wrapper}>
          <Box className={css.general_title}>
            <Typography>Pricing</Typography>
          </Box>
          <Box>
            <Grid>
              <TextFieldLabel
                inputTitle={"Base Price"}
                placeholder={"Type Base Price here. . ."}
                onChange={(e) => onChange_handle("price", e.target.value)}
                disabled={false}
                error={"Please provide price!"}
                type={"text"}
                style={fields_style_obj}
              />
            </Grid>
            <Box></Box>
          </Box>
        </Grid>
      ) : (
        ""
      )}
      {/* ---------Feature_section----- */}
      {renderingObject?.features?.is_applicable ? (
        <Grid item className={css.general_info_wrapper}>
          <Box className={css.general_title}>
            <Typography>Features</Typography>
          </Box>
          <Box>
            <Grid>
              {features?.map((e, i) => {
                return (
                  <>
                    <Stack
                      direction={"row"}
                      my={2}
                      gap={2}
                      alignItems={"center"}
                    >
                      {/* <Typography>Features</Typography> */}
                      <Typography alignItems={"center"}>
                        {i + 1} {e}
                      </Typography>
                      {/* <CustomButton  children={"del"}/> */}
                      <ClearIcon
                        onClick={() => removefeature(i)}
                        color="error"
                        sx={{
                          fontSize: 20,
                          bgcolor: "white",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      />
                    </Stack>
                  </>
                )
              })}
              <TextFieldLabel
                // inputTitle={"Features"}
                placeholder={"Type Features here. . ."}
                onChange={(e) => setAddFeaturesData(e.target.value)}
                disabled={false}
                value={addFeaturesData}
                error={"Please provide Features!"}
                type={"text"}
                style={fields_style_obj}
              />
              <CustomButton
                disable={addFeaturesData === ""}
                onClick={addFeatures}
                children={"Add feature"}
                style={{
                  backgroundColor: addFeaturesData === "" && "lightgrey",
                }}
              />
            </Grid>
            <Box></Box>
          </Box>
        </Grid>
      ) : (
        ""
      )}

      {/* Size Section */}

      {renderingObject?.size?.is_applicable ? (
        <Grid item className={css.general_info_wrapper}>
          <Box className={css.general_title}>
            <Typography>Size</Typography>
          </Box>
          <Box>
            <Grid>
              {allSizes?.map((e, i) => {
                return (
                  <>
                    <Stack
                      direction={"row"}
                      my={2}
                      gap={2}
                      alignItems={"center"}
                    >
                      {/* <Typography>Features</Typography> */}
                      <Typography alignItems={"center"}>
                        {i + 1} {e}
                      </Typography>
                      {/* <CustomButton  children={"del"}/> */}
                      <ClearIcon
                        onClick={() => removeSize(i)}
                        color="error"
                        sx={{
                          fontSize: 20,
                          bgcolor: "white",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      />
                    </Stack>
                  </>
                )
              })}
              <TextFieldLabel
                // inputTitle={"Features"}
                placeholder={"Type Size here. . ."}
                onChange={(e) => setAddSizesData(e.target.value)}
                disabled={false}
                value={addSizesData}
                error={"Please provide Sizes!"}
                type={"text"}
                style={fields_style_obj}
              />
              <CustomButton
                disable={addSizesData === ""}
                style={{
                  backgroundColor: addSizesData === "" && "lightgrey",
                }}
                onClick={addSize}
                children={"Add Size"}
              />
            </Grid>
            <Box></Box>
          </Box>
        </Grid>
      ) : (
        ""
      )}

      {/* ---------Shipping_section----- */}

      {/* ---- add product and cancel button ---- */}
      <Stack direction={"row"} spacing={2}>
        <CustomButton
          style={{
            backgroundColor: "white",
            border: "1px solid #858D9D",
            borderRadius: "8px",
          }}
          children={
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <RxCross2 size={20} color="#858D9D" />
              <Typography className={css.cancel_btn}>Cancel</Typography>
            </Stack>
          }
          onClick={() => navigate("")}
        />
        <CustomButton
          style={{
            border: "1px solid #FF9900",
            borderRadius: "8px",
          }}
          children={
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {add_product_data_status === asyncStatus.LOADING ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                <FiPlus size={20} />
              )}
              <Typography
                className={`${css.cancel_btn} ${css.add_product_btn}`}
              >
                Add Product
              </Typography>
            </Stack>
          }
          onClick={add_product_func}
        />
      </Stack>
    </Grid>
  )
}

export default AddProducts
