import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const get_all_sub_categories_async = createAsyncThunk(
  type_constants.GET_ALL_SUB_CATEGORIES,
  async () => {
    // const { page } = post_data
    try {
      const response = await apiHandle.get(`/get-sub-categories`)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL PRODUCTS ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
export const get_all_categories_async = createAsyncThunk(
  type_constants.GET_ALL_CATEGORIES,
  async () => {
    // const { page } = post_data
    try {
      const response = await apiHandle.get(`/get-categories`)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL Cateories ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_sub_categories_by_category_id_async = createAsyncThunk(
  type_constants.GET_SUB_CATEGORIES_BY_CATEGORY,
  async (post_data) => {
    // const { page } = post_data
    try {
      const response = await apiHandle.post(`/get-sub-category-by-category-id`, post_data)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the ALL Cateories ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
