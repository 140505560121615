import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { mainRoutes } from "../../utils/routeList"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { check_auth_async } from "../../store/services/authService"
import { save_tokens_constant } from "../../store/constant"
import { setAuthState } from "../../store/slices/auth_slice"
import { LinearProgress, Stack, Typography } from "@mui/material"
import { asyncStatus } from "../../utils/asyncStatus"
import { PrivateRoutes } from "./PrivateRoutes/PrivateRoutes"
import { PublicRoutes } from "./PublicRoutes/PublicRoutes"

function AppRouter() {
  const dispatch = useDispatch()

  const { check_auth_status } = useSelector((state) => state.auth)
  // const user_type = userData.user_type === "seller"
  useEffect(() => {
    if (check_auth_status === asyncStatus.IDLE) {
      const authTokens = localStorage.getItem(save_tokens_constant) || null
      if (!authTokens) {
        dispatch(setAuthState(false))
      } else {
        dispatch(check_auth_async())
      }
    }
  }, [])

  if (
    check_auth_status === asyncStatus.IDLE ||
    check_auth_status === asyncStatus.LOADING
  ) {
    return (
      <Stack>
        <LinearProgress />
      </Stack>
    )
  }

  if (check_auth_status === asyncStatus.ERROR) {
    return (
      <Stack py={2}>
        <Typography align="center" color="red">
          Something went wrong!
        </Typography>
      </Stack>
    )
  }

  return (
    <Router>
      {/* {userAuth && <Navbar />} */}
      <Routes>
        <Route element={<PublicRoutes />}>
          {React.Children.toArray(
            mainRoutes
              .filter((e) => e.check_cond)
              .map((route, i) => {
                const { linkTo, element, authRequired, check_cond } = route
                return (
                  !authRequired && (
                    <Route key={i} element={element} path={linkTo} />
                  )
                )
              }),
          )}
        </Route>
        <Route element={<PrivateRoutes />}>
          {React.Children.toArray(
            mainRoutes
              .filter((e) => e.check_cond)
              .map((route, i) => {
                const { linkTo, element, authRequired, home } = route
                return (
                  authRequired && (
                    <Route key={i} element={element} path={linkTo} />
                  )
                )
              }),
          )}
        </Route>
        {React.Children.toArray(
          mainRoutes
            .filter((e) => !e.check_cond)
            .map((route, i) => {
              const { linkTo, element, check_cond } = route
              return <Route key={i} element={element} path={linkTo} />
            }),
        )}
      </Routes>
    </Router>
  )
}

export default AppRouter
