import React from "react"
import css from "./TextField.module.css"
import { Input, Typography } from "antd"
import { FaDollarSign } from "react-icons/fa6"

const TextFieldLabel = ({
  onChange,
  placeholder,
  style,
  id,
  type,
  defaultValue,
  disabled = false,
  value,
  error,
  isValid = false,
  prefixIcon,
  suffixIcon,
  inputTitle,
  title_style,
}) => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Typography.Paragraph style={title_style} className={css.input_title}>
        {inputTitle}
      </Typography.Paragraph>
      {type === "textarea" ? (
        <Input.TextArea
          className={`${css.input_field} ${css.text_area_field}`}
          id={id}
          type={type}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          status={isValid ? "error" : ""}
          style={style}
        />
      ) : (
        <Input
          className={css.input_field}
          id={id}
          type={type}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          status={isValid ? "error" : ""}
          style={style}
          prefix={prefixIcon ?? ""}
          suffix={suffixIcon ?? ""}
        />
      )}
      {isValid && (
        <Typography.Paragraph
          style={{ color: isValid ? "red" : "black" }}
          level={5}
        >
          {error}
        </Typography.Paragraph>
      )}
    </div>
  )
}

export default TextFieldLabel
