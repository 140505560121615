import "./password_input.css"

const PasswordInput = ({ onChange, placeholder, style, showPassword, id }) => {
  return (
    <input
      className="pass-input"
      style={{ ...style }}
      type={showPassword ? "text" : "password"}
      onChange={onChange}
      id={id}
      placeholder={placeholder && placeholder}
    />
  )
}

export default PasswordInput
