import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material"
import AuthContainer from "../../../components/common/AuthContainer/AuthContainer"
import { primaryColour } from "../../../utils/colorTheme"
import { user_type_constant } from "../../../utils/constants"
import AuthInput from "../../../components/common/AuthInput/AuthInput"
import CustomButton from "../../../components/common/Button/Button"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { signup_async } from "../../../store/services/authService"
import { asyncStatus } from "../../../utils/asyncStatus"

const BUYER_LABEL = <span style={{ fontWeight: "bold" }}>Buyer</span>
const SELLER_LABEL = <span style={{ fontWeight: "bold" }}>Seller</span>
function Signup() {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { status, userData } = useSelector((state) => state.auth)
  const loading = status === asyncStatus.LOADING

  const [signUpData, setSignUpData] = useState("")
  const [termsAndConditions, setTermsAndConditions] = useState(false)

  const InputHandler = (e, forPhone, forRadio) => {
    setSignUpData((prev) => {
      if (forPhone) {
        return { ...prev, contact_number: e }
      }
      if (forRadio) {
        return { ...prev, user_type: e.target.value }
      } else {
        return { ...prev, [e.target.id]: e.target.value }
      }
    })
  }

  const submitHandler = () => {
    dispatch(signup_async(signUpData))
  }

  useEffect(() => {
    if (status === asyncStatus.SUCCEEDED) {
      navigate("/otp")
    }
  }, [status])

  return (
    <Container>
      <AuthContainer>
        <Stack maxWidth={600} mx={"auto"}>
          <Stack>
            <Typography variant="h2" className="heading">
              Sign Up
            </Typography>
          </Stack>

          <Stack>
            <Stack mt={1}>
              <Typography>
                Get started by choosing your user type and creating an account.
              </Typography>
            </Stack>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{
                fontWeight: "bold",
              }}
              onChange={(e) => InputHandler(e, false, true)}
            >
              <FormControlLabel
                value={user_type_constant.BUYER}
                control={
                  <Radio
                    sx={{
                      color: primaryColour,
                      "&.Mui-checked": {
                        color: primaryColour,
                      },
                    }}
                  />
                }
                labelPlacement="end"
                label={BUYER_LABEL}
              />
              <FormControlLabel
                labelPlacement="end"
                value={user_type_constant.SELLER}
                control={
                  <Radio
                    sx={{
                      color: primaryColour,
                      "&.Mui-checked": {
                        color: primaryColour,
                      },
                      fontWeight: "bold",
                    }}
                  />
                }
                color={primaryColour}
                label={SELLER_LABEL}
                sx={{ fontWeight: "bold" }}
              />
            </RadioGroup>
          </Stack>

          <Grid container rowGap={2} mt={2}>
            <Grid item xs={6} pr={2}>
              <AuthInput
                onChange={InputHandler}
                id="first_name"
                label={"First Name"}
              />
            </Grid>
            <Grid item xs={6}>
              <AuthInput
                id={"last_name"}
                onChange={InputHandler}
                label={"Last Name"}
              />
            </Grid>
            <Grid item xs={12}>
              <AuthInput
                id={"email"}
                onChange={InputHandler}
                label={"Email Address"}
              />
            </Grid>
            <Grid item xs={12}>
              <AuthInput
                onChange={(e) => InputHandler(e, true)}
                label={"Phone Number"}
                forPhone
              />
            </Grid>
            <Grid item xs={12}>
              <AuthInput
                label={"Password"}
                showPasswordLabel
                forPassword
                showPassHiddenIcon
                id={"password"}
                onChange={InputHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                flexWrap={"nowrap"}
                alignItems={"center"}
              >
                <Checkbox
                  sx={{
                    m: 0,
                    p: 0,
                    color: primaryColour,
                    "&.Mui-checked": {
                      color: primaryColour,
                    },
                  }}
                  onChange={(e) => setTermsAndConditions(e.target.checked)}
                />
                <Typography pl={1} fontSize={12}>
                  By creating an account, I agree to our Terms of use and
                  Privacy Policy
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <CustomButton
                disable={!termsAndConditions && loading}
                onClick={termsAndConditions && submitHandler}
                style={{ width: "100%", fontSize: 17, fontWeight: "bold",backgroundColor: !termsAndConditions && 'lightgrey' }}
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <CircularProgress color="inherit" size={17} />
                )}
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={12} textAlign={"center"}>
                Already have an Account ?{" "}
                <span className="span-link" onClick={() => navigate("/login")}>
                  Log in
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </AuthContainer>
    </Container>
  )
}

export default Signup
