import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification"
import {
  add_counter_offer_negotiation_async,
  get_negotiation_request_by_id_async,
  negotiation_actions_response_async,
  negotiation_async,
  negotiation_counter_offer_refresh_async,
  negotiation_request_list_async,
} from "../services/negotiationServices"

const initialState = {
  negotiation_data: null,
  negotiation_status: asyncStatus.IDLE,
  negotiation_error: null,

  negotiation_request_list_data: null,
  negotiation_request_list_status: asyncStatus.IDLE,
  negotiation_request_list_error: null,

  negotiation_request_by_id_data: null,
  negotiation_request_by_id_status: asyncStatus.IDLE,
  negotiation_request_by_id_error: null,

  counter_offer_by_buyer_negotiation_data: null,
  counter_offer_by_buyer_negotiation_status: asyncStatus.IDLE,
  counter_offer_by_buyer_negotiation_error: null,

  negotiation_counter_offer_refresh_status: asyncStatus.IDLE,

  negotiation_actions_response_status: asyncStatus.IDLE,
  negotiation_actions_response_data: null,
  negotiation_actions_response_error: null,

 
}

const negotiation_slice = createSlice({
  name: "negotiationSlice",
  initialState,
  reducers: {
    negotiationState: (state, actions) => {
      state.negotiation_status = asyncStatus.IDLE
    },
  },
  extraReducers(builder) {
    // negotiation request

    builder.addCase(negotiation_async.pending, (state, { payload }) => {
      state.negotiation_status = asyncStatus.LOADING
    })
    builder.addCase(negotiation_async.fulfilled, (state, { payload }) => {
      state.negotiation_data = payload.data
      state.negotiation_status = asyncStatus.SUCCEEDED
      state.negotiation_error = null
      success_toast_message(payload.message)
    })
    builder.addCase(negotiation_async.rejected, (state, actions) => {
      state.negotiation_data = null
      state.negotiation_status = asyncStatus.ERROR
      state.negotiation_error = actions?.error?.message
      error_toast_message(actions?.error?.message)
    })

    // negotiation request list data

    builder.addCase(
      negotiation_request_list_async.pending,
      (state, { payload }) => {
        state.negotiation_request_list_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      negotiation_request_list_async.fulfilled,
      (state, { payload }) => {
        state.negotiation_request_list_data = payload.data
        state.negotiation_request_list_status = asyncStatus.SUCCEEDED
        state.negotiation_request_list_error = null
      },
    )
    builder.addCase(
      negotiation_request_list_async.rejected,
      (state, actions) => {
        state.negotiation_request_list_data = null
        state.negotiation_request_list_status = asyncStatus.ERROR
        state.negotiation_request_list_error = actions?.error?.message
      },
    )

    // negotiation request by id products details

    builder.addCase(
      get_negotiation_request_by_id_async.pending,
      (state, { payload }) => {
        state.negotiation_request_by_id_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      get_negotiation_request_by_id_async.fulfilled,
      (state, { payload }) => {
        state.negotiation_request_by_id_data = payload.data
        state.negotiation_request_by_id_status = asyncStatus.SUCCEEDED
        state.negotiation_request_by_id_error = null
      },
    )
    builder.addCase(
      get_negotiation_request_by_id_async.rejected,
      (state, actions) => {
        state.negotiation_request_by_id_data = null
        state.negotiation_request_by_id_status = asyncStatus.ERROR
        state.negotiation_request_by_id_error = actions?.error?.message
      },
    )

    // counter offer by buyer negotiation

    builder.addCase(
      add_counter_offer_negotiation_async.pending,
      (state, { payload }) => {
        state.counter_offer_by_buyer_negotiation_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      add_counter_offer_negotiation_async.fulfilled,
      (state, { payload }) => {
        const { new_counter_data, validations } = payload.data
        state.negotiation_request_by_id_data.counter_offers.push(
          new_counter_data,
        )
        state.negotiation_request_by_id_data.validations = validations
        state.counter_offer_by_buyer_negotiation_status = asyncStatus.SUCCEEDED
        state.counter_offer_by_buyer_negotiation_error = null
      },
    )
    builder.addCase(
      add_counter_offer_negotiation_async.rejected,
      (state, actions) => {
        state.counter_offer_by_buyer_negotiation_status = asyncStatus.ERROR
        state.counter_offer_by_buyer_negotiation_error = actions?.error?.message
        error_toast_message(actions?.error?.message)
      },
    )

    // negotiation counter offer refresh async

    builder.addCase(
      negotiation_counter_offer_refresh_async.pending,
      (state, { payload }) => {
        state.negotiation_counter_offer_refresh_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      negotiation_counter_offer_refresh_async.fulfilled,
      (state, { payload }) => {
        const { offers_data, validations } = payload.data
        state.negotiation_counter_offer_refresh_status = asyncStatus.SUCCEEDED
        state.negotiation_request_by_id_data.counter_offers = offers_data
        state.negotiation_request_by_id_data.validations = validations
      },
    )
    builder.addCase(
      negotiation_counter_offer_refresh_async.rejected,
      (state, actions) => {
        state.negotiation_counter_offer_refresh_status = asyncStatus.ERROR
      },
    )

    // negotiation actions response async

    builder.addCase(
      negotiation_actions_response_async.pending,
      (state, { payload }) => {
        state.negotiation_actions_response_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      negotiation_actions_response_async.fulfilled,
      (state, { payload }) => {
        const { new_counter_data, validations } = payload.data
        state.negotiation_actions_response_status = asyncStatus.SUCCEEDED
        state.negotiation_request_by_id_data.counter_offers.push(
          new_counter_data,
        )
        state.negotiation_request_by_id_data.validations = validations
        state.negotiation_actions_response_error = null
        success_toast_message(payload.message)
      },
    )
    builder.addCase(
      negotiation_actions_response_async.rejected,
      (state, actions) => {
        state.negotiation_actions_response_status = asyncStatus.ERROR
        state.negotiation_actions_response_error = actions.error.message
        error_toast_message(actions.error.message)
      },
    )

    
  },
})

export const { negotiationState } = negotiation_slice.actions
export default negotiation_slice.reducer
