import { Stack, Typography, Grid, Container, Divider } from "@mui/material"
import banner from "../../assets/banner.png"
import { GoPackage } from "react-icons/go"
import { BsTrophy } from "react-icons/bs"
import { AiOutlineCreditCard } from "react-icons/ai"
import "./Banner.css"

const Services = ({ icon, firstLabel, secondLabel }) => {
  return (
    <Stack width={"100%"} direction={"row"} alignItems={"center"} spacing={1.2}>
      <Stack flex={0.4}>{icon}</Stack>
      <Stack flex={2}>
        <Typography color={"black"} fontWeight={"bold"}>
          {firstLabel}
        </Typography>
        <Typography color={"gray"} fontWeight={"bold"}>
          {secondLabel}
        </Typography>
      </Stack>
    </Stack>
  )
}

function Banner() {
  return (
    <Stack>
      <Stack>
        <img src={banner} height={"100%"} width={"100%"} />
      </Stack>
      <Container>
        <Grid
          mt={2}
          container
          border={"1px solid lightgray"}
          borderRadius={"6px"}
          alignItems={"center"}
        >
          <Grid item md={4} xs={12} sm={4}>
            <Stack
              p={"16px"}
              direction={"row"}
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Services
                icon={<GoPackage size={40} />}
                firstLabel={"Fastest Delivery"}
                secondLabel={"Delivery in 24/hrs."}
              />
              <Divider
                orientation="vertical"
                sx={{
                  height: "50px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <Stack
              p={"16px"}
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Services
                icon={<BsTrophy size={40} />}
                firstLabel={"24 Hours Return"}
                secondLabel={"100% money-back guarantee"}
              />
              <Divider
                orientation="vertical"
                sx={{
                  height: "50px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <Stack
              p={"16px"}
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Services
                icon={<AiOutlineCreditCard size={40} />}
                firstLabel={"Secure Payment"}
                secondLabel={"Your money is safe"}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  )
}

export default Banner
