import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const add_cart_product_async = createAsyncThunk(
  type_constants.ADD_TO_CART,
  async (post_data) => {
    // const { id} = post_data
    try {
      const response = await apiHandle.post(`/user/add-to-cart`, post_data)
      const res_data = await response.data
      return res_data
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_cart_product_async = createAsyncThunk(
  type_constants.GET_CART_PRODUCT,
  async () => {
    try {
      const response = await apiHandle.get(`/user/get-grouped-user-cart`)
      const res_data = await response.data
      return res_data
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)


export const add_to_cart_qty_async = createAsyncThunk(
  type_constants.Add_TO_CART_QTY,
  async (product_qty) => {
    try {
      const response = await apiHandle.post(`/user/add-to-cart`, product_qty)
      const res_data = await response.data
      console.log("res_data", res_data)
      return res_data
    } catch (error) {
      console.error("ADD TO CART QTY ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
