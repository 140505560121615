import {
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import React, { useEffect } from "react"
import Input from "../../components/common/Input/Input"
import Navbar from "../../components/common/navbar/Navbar"
import CustomCheckbox from "../../components/common/customCheckbox/CustomCheckbox"
import DeliveryOptions from "../../components/deliveryOptions/DeliveryOptions"
import checkoutCamera from "../../assets/checkout-camera.png"
import CustomRadio from "../../components/common/CustomRadio/CustomRadio"
import visa from "../../assets/visa.png"
import maestro from "../../assets/maestro.png"
import american from "../../assets/american.png"
import CustomButton from "../../components/common/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { get_buyer_address_async } from "../../store/services/addressService"
import { useNavigate } from "react-router-dom"

const CheckoutScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userData } = useSelector((state) => state.auth)
  const { get_buyer_address_data } = useSelector((state) => state.address_slice)
  const billingAddressData = get_buyer_address_data?.billing_address ?? {}
  const shippingAddressData = get_buyer_address_data?.shipping_address ?? {}

  const {
    address: bill_address = "",
    country: bill_country = "",
    city: bill_city = "",
    name: bill_name = "",
    phone: bill_phone = "",
    region_state: bill_region_state = "",
    zipcode: bill_zipcode = "",
  } = billingAddressData

  const {
    address: ship_address = "",
    country: ship_country = "",
    city: ship_city = "",
    name: ship_name = "",
    phone: ship_phone = "",
    region_state: ship_region_state = "",
    zipcode: ship_zipcode = "",
  } = shippingAddressData

  useEffect(() => {
    dispatch(get_buyer_address_async())
  }, [])

  return (
    <>
      <Navbar />

      <Container>
        <Grid container>
          {/** <!----------- ORDER SUMMARY -----------!>  **/}

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
            <Stack my={1} p={2}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography>Order Summary(1)</Typography>
                <Button variant="text" sx={{ color: "#FA8232" }}>
                  Edit details
                </Button>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <img src={checkoutCamera} width={"100px"} />
                <Typography fontSize={14}>
                  Plastic useless plugs and tubes <br /> for high-fidelity
                  prtotyping. Fit & Eat!
                </Typography>
                <Typography>$12.00</Typography>
              </Stack>
              <Stack alignItems={"center"}>
                <Typography>Qty: 1</Typography>
              </Stack>
              <Divider sx={{ mt: 1 }} />
            </Stack>

            <Stack p={2}>
              {/* <Typography variant="h6">Payment options</Typography>

              <CustomRadio
                label={"Cash On Delivery"}
                extra={"Pay with cash upon delivery."}
                value={"CashOnDelivery"}
              />
              <CustomRadio
                label={"Direct bank transfer"}
                extra={"Make payment directly through bank account."}
                value={"DirectBankTransfer"}
              /> */}
              {/* <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={""}
              >
                <CustomRadio
                  label={
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <Typography fontWeight={"600"}>
                        Credit/Debit card
                      </Typography>
                      <Stack direction={"row"} gap={1}>
                        <img width={"20px"} height={"20px"} src={american} />
                        <img width={"20px"} height={"20px"} src={maestro} />
                        <img width={"20px"} height={"20px"} src={visa} />
                      </Stack>
                    </Stack>
                  }
                  extra={"Pay with your Visa, American Express or Mastercard."}
                  value={"CreditDebitCard"}
                />
              </Stack> */}

              {/* <Stack gap={2} py={2}>
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Card holder name"}
                />
                <Input style={{ width: "100%" }} placeholder={"Card number"} />
                <Input style={{ width: "100%" }} placeholder={"Expiry date"} />
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Security code(CVV) "}
                />
              </Stack> */}

              <Stack>
                <Typography variant="h6">Review & Place Order</Typography>
                <Typography color={"lightgray"}>
                  Please review the order details and payment details before
                  proceeding to confirm your order
                </Typography>
                <Stack direction={"row"} mt={1} alignItems={"center"}>
                  <CustomCheckbox
                    fill={false}
                    defaultChecked={
                      get_buyer_address_data?.shipping_same_as_billing ?? ""
                    }
                  />
                  <Typography>
                    I agree to the&nbsp;
                    <span style={{ color: "#7047EB" }}>Terms & conditions</span>
                    ,<span style={{ color: "#7047EB" }}> Privacy policy </span>&
                    <span style={{ color: "#7047EB" }}> Return policy</span>
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <CustomButton children={"Place Order"} />
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          {/** <!----------- CHECKOUT DETAIL -----------!>  **/}

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
            <Typography variant="h4">Checkout</Typography>
            <Typography variant="h6" mt={2} mb={1}>
              Customer information
            </Typography>
            <Stack>
              <Input
                placeholder={"Email for order conformation"}
                value={userData?.email}
                disabled={true}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              mt={2}
              mb={1}
            >
              <Typography variant="h6">Billing address</Typography>
              <Button
                variant="text"
                sx={{ color: "#FA8232" }}
                onClick={() => navigate("/dashboard/setting")}
              >
                Edit details
              </Button>
            </Stack>

            <Stack gap={1.5}>
              <Stack
                direction={"row"}
                width={1}
                gap={2}
                flexWrap={{
                  xl: "nowrap",
                  lg: "nowrap",
                  md: "nowrap",
                  sm: "nowrap",
                  xs: "wrap",
                }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={"First name"}
                  value={bill_name.split(" ")[0]}
                  disabled={true}
                />
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Last name"}
                  value={bill_name.split(" ")[1]}
                  disabled={true}
                />
              </Stack>
              <Input
                style={{ width: "100%" }}
                placeholder={"Address"}
                value={bill_address ?? ""}
                disabled={true}
              />
              <Input
                style={{ width: "100%" }}
                placeholder={"City"}
                value={bill_city ?? ""}
                disabled={true}
              />
              <Input
                style={{ width: "100%" }}
                placeholder={"Country"}
                value={bill_country ?? ""}
                disabled={true}
              />
              <Input
                style={{ width: "100%" }}
                placeholder={"State"}
                value={bill_region_state ?? ""}
                disabled={true}
              />
              <Stack
                direction={"row"}
                width={1}
                gap={2}
                flexWrap={{
                  xl: "nowrap",
                  lg: "nowrap",
                  md: "nowrap",
                  sm: "nowrap",
                  xs: "wrap",
                }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Zip"}
                  value={bill_zipcode ?? ""}
                  disabled={true}
                />
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Phone"}
                  value={bill_phone ?? ""}
                  disabled={true}
                />
              </Stack>
            </Stack>

            <Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                mt={2}
                mb={1}
              >
                <Typography variant="h6">Shipping address</Typography>
                <Button
                  variant="text"
                  sx={{ color: "#FA8232" }}
                  onClick={() => navigate("/dashboard/setting")}
                >
                  Edit details
                </Button>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} mb={2}>
                <CustomCheckbox fill={false} isChecked={true} />

                <Typography px={1}>Same as billing address</Typography>
              </Stack>
              <Stack gap={1.5}>
                <Stack
                  direction={"row"}
                  width={1}
                  gap={2}
                  flexWrap={{
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "nowrap",
                    sm: "nowrap",
                    xs: "wrap",
                  }}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"First name"}
                    value={ship_name.split(" ")[0] ?? ""}
                    disabled={true}
                  />
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"Last name"}
                    value={ship_name.split(" ")[1] ?? ""}
                    disabled={true}
                  />
                </Stack>
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Address"}
                  value={ship_address ?? ""}
                  disabled={true}
                />

                <Input
                  style={{ width: "100%" }}
                  placeholder={"City"}
                  value={ship_city ?? ""}
                  disabled={true}
                />
                <Stack
                  direction={"row"}
                  width={1}
                  gap={2}
                  flexWrap={{
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "nowrap",
                    sm: "nowrap",
                    xs: "wrap",
                  }}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"Country"}
                    value={ship_country ?? ""}
                    disabled={true}
                  />
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"State"}
                    value={ship_region_state ?? ""}
                    disabled={true}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  width={1}
                  gap={2}
                  flexWrap={{
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "nowrap",
                    sm: "nowrap",
                    xs: "wrap",
                  }}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"Zip"}
                    value={ship_zipcode ?? ""}
                    disabled={true}
                  />
                  <Input
                    style={{ width: "100%" }}
                    placeholder={"Phone"}
                    value={ship_phone ?? ""}
                    disabled={true}
                  />
                </Stack>
              </Stack>
              <Stack my={2}>
                <DeliveryOptions />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default CheckoutScreen
