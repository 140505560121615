import { Box, Button, Typography } from "@mui/material"
import css from "./CreditCardModal.module.css"
import React, { useState } from "react"
import Modal from "@mui/material/Modal"
import { RxCross1 } from "react-icons/rx"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "start",
}


const CreditCardModal = ({openModal,closeModal}) => {
  const [card, setCard] = useState({
    cardname: "",
    cardno: "",
    cardtype: "far fa-credit-card",
    expirydt: "",
  })


  const onChange = (e) => {
    var cartype_new = cardnumber(e.target.value)
    setCard({
      ...card,
      cardno: e.target.value,
      cardtype: cartype_new,
    })
  }

  const cardnumber = (inputtxt) => {
    var matches = inputtxt.match(/(\d+)/)
    var cardno = ""
    console.log(matches)
    if (matches) {
      cardno = inputtxt.split(" - ").join("")
    }
    console.log(cardno)
    var cardtype1 = card.cardtype
    //var visa = /^(?:4[0-9]{16}(?:[0-9]{3})?)$/;
    var visa = /^(?:4[0-9]{2}?)$/
    var mastercardRegEx = /^(?:5[1-5][0-9]{3})$/
    var amexpRegEx = /^(?:3[47][0-9]{3})$/
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{5})$/
    console.log(visa.test(cardno))
    if (visa.test(cardno) === true) {
      //eg:4651970022334445
      cardtype1 = "far fa fa-3x fa-cc-visa  carddetail-cardtype"
    } else if (mastercardRegEx.test(cardno) === true) {
      cardtype1 = "far fa fa-3x fa-cc-mastercard carddetail-cardtype"
    } else if (amexpRegEx.test(cardno) === true) {
      cardtype1 = "far fa fa-3x fa-cc-amex carddetail-cardtype"
    } else if (discovRegEx.test(cardno) === true) {
      cardtype1 = "far fa fa-3x fa-cc-discover carddetail-cardtype"
    }
    return cardtype1
  }

  const cc_format = (value) => {
    const v = value.replace(/[^0-9]/gi, "").substr(0, 16)

    const parts = []
    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4))
    }
    return parts.length > 1 ? parts.join(" - ") : value
  }

  const expriy_format = (value) => {
    const expdate = value
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4)

    return expDateFormatter
  }

  const onChangeExp = (e) => {
    setCard({
      ...card,
      expirydt: e.target.value,
    })
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={css.modal_header}>
            <Typography variant="h6">ADD NEW CARD</Typography>
            <RxCross1
              onClick={closeModal}
              size={20}
              color="#929FA5"
            />
          </Box>

          <div className={css.card_form_wrapper}>
            <div>
              <label className={css.card_label}>Name on Card</label>
              <input
                type="text"
                className={css.credit_card_input}
                value={card.cardname}
                onChange={(e) => setCard({ ...card, cardname: e.target.value })}
                autoComplete="off"
              />
            </div>

            <div>
              <label className={css.card_label}>Card Number</label>
              <input
                type="text"
                className={css.credit_card_input}
                data-mask="0000 0000 0000 0000"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                value={cc_format(card.cardno)}
                onChange={onChange}
                onClick={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                autoComplete="off"
              />
            </div>

            <div className={css.card_expiry_cvv_wrapper}>
              <div className={css.card_exp_cvv_item}>
                <label className={css.card_label}>Expiry date</label>

                <input
                  type="text"
                  name="expiry-data"
                  className={css.credit_card_input}
                  placeholder="mm / yy"
                  onChange={onChangeExp}
                  value={expriy_format(card.expirydt)}
                  autoComplete="off"
                />
                <i className="far fa-calendar-alt"></i>
              </div>
              <div className={css.card_exp_cvv_item}>
                <label className={css.card_label}>CVV</label>
                <input
                  type="password"
                  className={css.credit_card_input}
                  data-mask="000"
                  placeholder="000"
                  maxlength="3"
                  pattern="[0-9][0-9][0-9]"
                  onClick={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>
            </div>

            <Button variant="contained">ADD CARD</Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default CreditCardModal
