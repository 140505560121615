import React, { useState } from "react"
import css from "./Chat.module.css"
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material"
import ImageIcon from "@mui/icons-material/Image"
import ChatPerson from "../../components/chatPerson/ChatPerson"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ClearIcon from "@mui/icons-material/Clear"
import Avatar from "@mui/material/Avatar"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { FaHandHolding } from "react-icons/fa6"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import Navbar from "../../components/common/navbar/Navbar"

const ChatScreen = () => {
  const [count, setCount] = useState(200)
  const [chatOpen, setChatOpen] = useState(false)

  const handleIncrement = () => {
    setCount(count + 100)
  }
  const handleDecrement = () => {
    if (count === 0) {
      setCount(0)
    } else {
      setCount(count - 100)
    }
  }

  const userDataArr = [
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "300",
      time: "4:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "400",
      time: "5:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
    {
      profileImg: <ImageIcon />,
      userName: "John Doe",
      price: "700",
      time: "6:12pm",
    },
  ]

  const userChatHeader = {
    profileImg: <ImageIcon />,
    userName: "John Doe",
    price: "300",
    time: "4:12pm",
  }

  const handleChatOpen = (isOpen) => {
    setChatOpen(isOpen)
  }

  return (
    <>
      <Navbar />
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid
          item
          xl={!chatOpen ? 8 : 2.5}
          lg={!chatOpen ? 8 : 2.5}
          md={!chatOpen ? 10 : 3}
          sm={!chatOpen ? 10 : 3.5}
          xs={12}
          mr={{ xs: "0px", sm: "12px", md: "12px", xl: "12px", lg: "12px" }}
          display={{
            xl: "block",
            lg: "block",
            md: "block",
            sm: "block",
            xs: chatOpen ? "none" : "block",
          }}
        >
          <Box className={`${css.message_heading}`}>
            <Typography variant="h6">Messages</Typography>
          </Box>
          <ChatPerson userData={userDataArr} chatOpen={handleChatOpen} />
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={8}
          xs={12}
          display={
            chatOpen ? { xl: "block", lg: "block", sm: "block" } : "none"
          }
        >
          {/* navbar of chat */}
          <Box className={css.chat_Header}>
            <List className={css.list_wrapper}>
              <ListItem disablePadding>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      borderRadius: "14px",
                      height: "43px",
                      width: "43px",
                      ml: "34px",
                      mr: "18px",
                    }}
                    alt="Remy Sharp"
                    src={userChatHeader.profileImg}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={css.title}
                  primary={userChatHeader.userName}
                />

                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="more"
                    onClick={() => setChatOpen(false)}
                  >
                    <ClearIcon sx={{ fontSize: "25px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Box>
          <Box className={css.chat_wrapper}>
            <Box className={css.chat_container}>
              <List>
                {userDataArr.map((chat, i) => {
                  return (
                    <ListItem key={i} disablePadding>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            borderRadius: "14px",
                            height: "43px",
                            width: "43px",
                            mr: "18px",
                          }}
                          alt="Remy Sharp"
                          src={chat.profileImg}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        className={css.chat_title}
                        primary={
                          <span>
                            <span>{chat.userName}</span> <p>{chat.time}</p>
                          </span>
                        }
                        secondary={`$${chat.price}`}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Box>
            <Box className={css.send_btn}>
              <Button variant="outlined" startIcon={<FaHandHolding />}>
                Offer
              </Button>

              <Box className={css.price_increase_btn}>
                <RemoveIcon onClick={handleDecrement} />
                <Typography>${count}</Typography>
                <AddIcon onClick={handleIncrement} />
              </Box>
              <Divider />
              <Box className={css.offer_send_btn}>
                Very good offer! <Button variant="contained">Send</Button>{" "}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ChatScreen
