import React, { useState, useEffect } from "react"
import Navbar from "../../components/common/navbar/Navbar"
import { Container, Grid } from "@mui/material"
import Breadcrumb from "../../components/breadCrumb/BreadCrumb"
import DashboardSideBar from "../../components/dashboardSideBar/DashboardSideBar"
import Dashboard from "./Dashboard/Dashboard"
import { nestedRoutes_dashboard } from "../../utils/routeList"
import { Route, Routes } from "react-router-dom"
import { GoHome } from "react-icons/go"
import DashboardTitle from "../../components/dashboardTitle/DashboardTitle"


const DashboardMain = () => {
  const [inner_width, setInner_width] = useState(window.innerWidth)


  const breadCrumbsRoute = [
    {
      name: "Home",
      icon: (
        <GoHome
          sx={{
            fontSize: "15px",
            color: "rgba(95, 108, 114, 1)",
          }}
        />
      ),
      linkTo: "/",
      element: <></>,
    },

    ...nestedRoutes_dashboard,
  ]



  useEffect(() => {
    const handleWidth = () => {
      setInner_width(window.innerWidth)
    }
    window.addEventListener("resize", handleWidth)

    return () => window.addEventListener("resize", handleWidth)
  }, [])

  return (
    <>
      <Navbar />
      <DashboardTitle title={"Buyer Dashboard"}/>
      <Breadcrumb breadCrumbsRoutes={breadCrumbsRoute} rootDashboardRout={"dashboard"} />
      <Container sx={{ my: 2 }}>
        <Grid container alignItems={"start"}>
          <Grid
            item
            xl={2.5}
            lg={2.5}
            md={2.5}
            sm={3}
            xs={false}
            sx={{ my: 2 }}
            display={inner_width <= 900 ? "none" : "block"}
          >
            <DashboardSideBar nestedRoutes={nestedRoutes_dashboard} rootDashboardRout={"dashboard"} />
          </Grid>

          <Grid
            container
            item
            md={9}
            xs={12}
            sm={12}
            m={2}
            flexWrap={"wrap"}
          >
            <Routes>
              <Route index element={<Dashboard />} />
              {nestedRoutes_dashboard.map((screen, i) => {
                return (
                  <Route
                    key={i}
                    path={screen.linkTo}
                    element={screen.element}
                  />
                )
              })}
            </Routes>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default DashboardMain
