import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import {
  add_product_async,
  delete_product_async,
  edit_product_async,
  get_all_products_async,
  get_product_by_product_id_async,
  get_product_by_user_id_async,
  search_product_for_user_async,
} from "../services/productsService"
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification"
import { add_wishlist_products_async } from "../services/wishlistService"

const initialState = {
  // data state
  data: null,
  data_from_product_id_data: null,
  add_product_data: null,
  get_product_by_user_id_data: null,
  delete_product_data: null,
  search_product_for_user_data: null,
  edit_product_data: null,

  // status state
  status: asyncStatus.IDLE,
  data_from_product_id_status: asyncStatus.IDLE,
  add_product_data_status: asyncStatus.IDLE,
  get_product_by_user_id_status: asyncStatus.IDLE,
  delete_product_status: asyncStatus.IDLE,
  search_product_for_user_status: asyncStatus.IDLE,
  edit_product_status: asyncStatus.IDLE,

  // error state
  error: null,
  data_from_product_id_error: null,
  add_product_data_error: null,
  get_product_by_user_id_error: null,
  delete_product_error: null,
  search_product_for_user_error: null,
  edit_product_error: null,

  // pagination
  total_products_count: 0,
  total_pages: 0,
}
const product_slice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    set_product_status(state, action) {
      state.add_product_data_status = asyncStatus.IDLE
    },
    set_edit_product_status(state, action) {
      state.edit_product_status = asyncStatus.IDLE
    },
  },
  extraReducers(builder) {
    builder.addCase(get_all_products_async.pending, (state, { payload }) => {
      state.status = asyncStatus.LOADING
    })
    builder.addCase(get_all_products_async.fulfilled, (state, { payload }) => {
      state.data = payload
      state.total_pages = payload.total_pages
      state.total_products_count = payload.total_products_count
      state.status = asyncStatus.SUCCEEDED
      state.error = null
    })
    builder.addCase(get_all_products_async.rejected, (state, actions) => {
      state.data = null
      state.status = asyncStatus.ERROR
      state.error = actions?.error?.message
    })

    // get product by product id
    builder.addCase(
      get_product_by_product_id_async.pending,
      (state, { payload }) => {
        state.data_from_product_id_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      get_product_by_product_id_async.fulfilled,
      (state, { payload }) => {
        state.data_from_product_id_data = payload.data
        state.data_from_product_id_status = asyncStatus.SUCCEEDED
        state.data_from_product_id_error = null
      },
    )
    builder.addCase(
      get_product_by_product_id_async.rejected,
      (state, actions) => {
        state.data = null
        state.data_from_product_id_status = asyncStatus.ERROR
        state.data_from_product_id_error = actions?.error?.message
      },
    )

    // add product
    builder.addCase(add_product_async.pending, (state, { payload }) => {
      state.add_product_data_status = asyncStatus.LOADING
    })
    builder.addCase(add_product_async.fulfilled, (state, { payload }) => {
      state.add_product_data = payload.data
      state.add_product_data_status = asyncStatus.SUCCEEDED
      state.add_product_data_error = null
      success_toast_message(payload.message)
    })
    builder.addCase(add_product_async.rejected, (state, actions) => {
      state.data = null
      state.add_product_data_status = asyncStatus.ERROR
      state.add_product_data_error = actions?.error?.message
      error_toast_message(actions?.error?.message)
    })

    // get product by user_id
    builder.addCase(
      get_product_by_user_id_async.pending,
      (state, { payload }) => {
        state.get_product_by_user_id_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      get_product_by_user_id_async.fulfilled,
      (state, { payload }) => {
        state.get_product_by_user_id_data = payload.data
        state.get_product_by_user_id_status = asyncStatus.SUCCEEDED
        // success_toast_message(payload.message)
        state.get_product_by_user_id_error = null
      },
    )
    builder.addCase(get_product_by_user_id_async.rejected, (state, actions) => {
      state.get_product_by_user_id_data = null
      state.get_product_by_user_id_status = asyncStatus.ERROR
      state.get_product_by_user_id_error = actions?.error?.message
      error_toast_message(actions?.error?.message)
    })

    // delete product
    builder.addCase(delete_product_async.pending, (state, { payload }) => {
      state.delete_product_status = asyncStatus.LOADING
    })
    builder.addCase(delete_product_async.fulfilled, (state, { payload }) => {
      state.delete_product_data = payload.data
      state.delete_product_status = asyncStatus.SUCCEEDED
      success_toast_message(payload.message)
      state.delete_product_error = null
    })
    builder.addCase(delete_product_async.rejected, (state, actions) => {
      state.delete_product_data = null
      state.delete_product_status = asyncStatus.ERROR
      state.delete_product_error = actions?.error?.message
      error_toast_message(actions?.error?.message)
    })

    // search product for user
    builder.addCase(
      search_product_for_user_async.pending,
      (state, { payload }) => {
        state.search_product_for_user_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      search_product_for_user_async.fulfilled,
      (state, { payload }) => {
        state.search_product_for_user_data = payload.data
        state.search_product_for_user_status = asyncStatus.SUCCEEDED
        // success_toast_message(payload.message)
        state.search_product_for_user_error = null
      },
    )
    builder.addCase(
      search_product_for_user_async.rejected,
      (state, actions) => {
        state.search_product_for_user_data = null
        state.search_product_for_user_status = asyncStatus.ERROR
        state.search_product_for_user_error = actions?.error?.message
        error_toast_message(actions?.error?.message)
      },
    )

    // edit product
    builder.addCase(edit_product_async.pending, (state, { payload }) => {
      state.edit_product_status = asyncStatus.LOADING
    })
    builder.addCase(edit_product_async.fulfilled, (state, { payload }) => {
      state.edit_product_data = payload.data
      state.edit_product_status = asyncStatus.SUCCEEDED
      success_toast_message(payload.message)
      state.search_product_for_user_error = null
    })
    builder.addCase(edit_product_async.rejected, (state, actions) => {
      state.edit_product_data = null
      state.edit_product_status = asyncStatus.ERROR
      state.edit_product_error = actions?.error?.message
      error_toast_message(actions?.error?.message)
    })

    // WISHLIST UPDATED REALTIME

    builder.addCase(
      add_wishlist_products_async.fulfilled,
      (state, { payload }) => {
        if (state.data_from_product_id_data) {
          const newData = state.data_from_product_id_data.map((product) => {
            if (product._id === payload.data.product_id) {
              return {
                ...product,
                is_favorite: payload.data.is_add,
              }
            }
            return product
          })
          state.data_from_product_id_data = newData
        }
      },
    )
  },
})
export default product_slice.reducer
export const { set_product_status, set_edit_product_status } =
  product_slice.actions
