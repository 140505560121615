import React, { useEffect, useRef, useState } from "react"
import css from "./SemiProgressBar.module.css"
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { Stack } from "@mui/material"

const CustomSemiCircleProgressBar = ({ percentValue }) => {
  const percentage = 66

  return (
    <Stack  width={{xl:250,lg:250, md:210,sm:280,xs:250}} height={120} className={css.progress_bar_wrapper}>
      <CircularProgressbarWithChildren
        className="circular_percentage_style"
        value={percentage}
        styles={buildStyles({
          rotation: 0.75,
          strokeWidth: 5,
          pathColor: `orange`,
        })} // Rotate by 180 degrees (0.5 * 360)
         // Adjust the width of the trailing path
        circleRatio={0.5}
      >
        <strong className={css.percent}>{`${percentage}%`}</strong>
        <strong className={css.change}>{"+10%"}</strong>
      </CircularProgressbarWithChildren>
    </Stack>
  )
}

export default CustomSemiCircleProgressBar
