import { Grid } from "@mui/material"
import React from "react"
import css from "./PaymentCard.module.css"
import { RiFileCopyLine } from "react-icons/ri"
import { Stack, Typography } from "@mui/joy"

const PaymentCard = ({ card_number, card_company_icon, card_user_name }) => {
  let maskedString = ""

  for (let i = 0; i < card_number.length; i++) {
    if (card_number[i] !== " " && i < 14) {
      maskedString += "*"
    } else {
      maskedString += card_number[i]
    }
  }

  return (
    <Grid className={css.payment_card}>
      <Stack>
        <Typography>CARD NUMBER</Typography>
      </Stack>
      <Stack gap={1}>
        <Typography>{maskedString}</Typography>
        <RiFileCopyLine />
      </Stack>
      <Stack>
        {card_company_icon}
        <Typography>{card_user_name}</Typography>
      </Stack>
    </Grid>
  )
}

export default PaymentCard
