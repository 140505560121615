import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import css from "./ChatPerson.module.css";

const ChatPerson = ({ userData,chatOpen }) => {

    const [selectedIndex,setSelectedIndex] = React.useState(null);

    const handleClick = (i,isOpen)=>{
      setSelectedIndex(i)
      chatOpen(isOpen);
    }
    

  return (
    <List className={css.list_wrapper} >
      {userData.map((user, i) => {
        return (
          <ListItem
          key={i}
            sx={{
              backgroundColor: selectedIndex === i  ? "rgba(0, 0, 0, 0.04)" : 'white',
              height: "90px",
              px: "16px",
              py: "20px",
            }}
            secondaryAction={user.time}
            disablePadding
            onClick={()=>handleClick(i,true)}
          >
            <ListItemAvatar>
              {/* <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: "#44b700", 
                    border: "2px solid #fff",
                    height:'15px',
                    width:'15px',
                    borderRadius:'100%'
                  },
                }}
              > */}
                <Avatar
                  sx={{ borderRadius: "14px", height: "51px", width: "49px" }}
                  alt="Remy Sharp"
                  src={user.profileImg}
                />
              {/* </Badge> */}
            </ListItemAvatar>
            <ListItemText
              className={css.title}
              primary={user.userName}
              secondary={`$${user.price}`}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ChatPerson;

