import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import React, { useEffect } from "react"
import css from "./WishList.module.css"
import { Input } from "antd"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { ProductCard_Grid } from "../../../components/Cards/ProductCard/ProductCard"
import { PiSpinnerBold } from "react-icons/pi"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { get_wishlist_products_async } from "../../../store/services/wishlistService"
import { asyncStatus } from "../../../utils/asyncStatus"
import { primaryColour } from "../../../utils/colorTheme"

const WishList = () => {
  const { get_wish_list_data, get_wish_list_status } = useSelector(
    (state) => state.wishlist_slice,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(get_wishlist_products_async())
  }, [])

  return (
    <Grid item className={css.wishlist_wrapper} width={1}>
      <Box className={css.wish_title}>
        <Typography>Wishlist</Typography>
        <Input
          size="large"
          placeholder="large size"
          prefix={<HiOutlineMagnifyingGlass size={22} color="#FA8232" />}
        />
      </Box>

      <Grid container spacing={1}>
        {get_wish_list_status === asyncStatus.LOADING ? (
          <Stack
            width={1}
            height={"30vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={30} sx={{ color: primaryColour }} />
          </Stack>
        ) : get_wish_list_data?.length > 0 ? (
          get_wish_list_data?.map(
            ({
              _id,
              average_rating,
              description,
              feature_image,
              price,
              title,
              total_reviews,
            }) => (
              <ProductCard_Grid
                key={_id}
                Rating={total_reviews}
                productImg={feature_image}
                price={price}
                description={description}
                title={title}
                onClick={() => navigate(`/product/${_id}`)}
                avgRating={average_rating}
              />
            ),
          )
        ) : (
          <Stack
            width={1}
            height={"30vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>No data found</Typography>
          </Stack>
        )}
      </Grid>

      <Button
        className={css.load_btn}
        variant="outlined"
        startIcon={<PiSpinnerBold size={22} color="#FA8232" />}
      >
        LOAD MORE
      </Button>
    </Grid>
  )
}

export default WishList
