import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const negotiation_async = createAsyncThunk(
  type_constants.NEGOTIATION,
  async (negotiation_data) => {
    try {
      const res = await apiHandle.post(
        `/user/negotiation-request`,
        negotiation_data,
      )
      const data = await res.data
      return data
    } catch (error) {
      console.error("THE ALL NEGOTIATION ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const negotiation_request_list_async = createAsyncThunk(
  type_constants.NEGOTIATION_REQUEST_LIST,
  async () => {
    try {
      const res = await apiHandle.get(
        `/user/get-negotiation-request-list/active`,
      )
      const data = await res.data
      return data
    } catch (error) {
      console.error("THE ALL NEGOTIATION REQUEST LIST ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_negotiation_request_by_id_async = createAsyncThunk(
  type_constants.NEGOTIATION_REQUEST_BY_ID,
  async (id) => {
    try {
      const res = await apiHandle.get(
        `/user/get-negotiation-request-by-id/${id ?? ""}`,
      )
      const data = await res.data
      return data
    } catch (error) {
      console.error("THE ALL NEGOTIATION REQUEST BY ID ERROR ===>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const add_counter_offer_negotiation_async = createAsyncThunk(
  type_constants.ADD_COUNTER_OFFER_BY_BUYER_NEGOTIATION,
  async (buyer_offer) => {
    try {
      const res = await apiHandle.post(
        `/user/negotiation-counter-offer`,
        buyer_offer,
      )
      const data = await res.data
      return data
    } catch (error) {
      console.log("THE BUYER ADD OFFER NEGOTIATION ====>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const negotiation_counter_offer_refresh_async = createAsyncThunk(
  type_constants.NEGOTIATION_COUNTER_OFFER_REFRESH,
  async (id) => {
    try {
      const res = await apiHandle.get(`/user/get-nego-counters/${id ?? ""}`)
      const data = await res.data
      return data
    } catch (error) {
      console.log("THE NEGOTIATION COUNTER OFFER REFRESH ERROR ====>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const negotiation_actions_response_async = createAsyncThunk(
  type_constants.NEGOTIATION_ACTIONS_RESPONSE,
  async (actions_data) => {
    try {
      const res = await apiHandle.post(`/user/negotiation-action`, actions_data)
      const data = await res.data
      return data
    } catch (error) {
      console.log("THE NEGOTIATION ACTIONS RESPSONSE ERROR ====>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)




