import React, { useState, useEffect } from "react"
import css from "./Dashboard.module.css"
import { Box, Grid, Stack, Typography } from "@mui/material"
import CustomButton from "../../../components/common/Button/Button"
import { MdOutlineDateRange } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import cash_checked from "../../../assets/states_icons/cash checked.png"
import cart from "../../../assets/states_icons/cart.png"
import scan from "../../../assets/states_icons/scan.png"
import wallet from "../../../assets/states_icons/wallet.png"
import SelectField from "../../../components/common/SelectField/SelectField"
import CustomSemiCircleProgressBar from "../../../components/common/SemiProgressBar/SemiProgressbar"
import { MdMoreVert } from "react-icons/md"
import { GoArrowUp } from "react-icons/go"
import { GoArrowDown } from "react-icons/go"
import StatesChart from "../../../components/common/StatesChart/StatesShart"
import { get_product_by_user_id_async } from "../../../store/services/productsService"
import { set_product_status } from "../../../store/slices/product_slice"
import { BiSlider } from "react-icons/bi"
import { FiPlus } from "react-icons/fi"
import watch_pic1 from "../../../assets/productsListImages/watch.png"
import { FiTrash2 } from "react-icons/fi"
import { LuPencil } from "react-icons/lu"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"

const ProductComponent = (props) => {
  const { image_url, title, description } = props
  return (
    <Stack spacing={1} direction={"row"} className={css.product_list_wrapper}>
      <Stack>
        <img
          height={44}
          width={44}
          // src={image_url ?? ""}
          src={watch_pic1}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>{title ?? ""}</Typography>
        <Typography className={css.image_description}>
          {description ?? "SKU: 302012"}
        </Typography>
      </Stack>
    </Stack>
  )
}
/* -----> Top selling product table column */
const sellingPrductcolumns = [
  {
    title: "Product",
    dataIndex: "title",
    render: (title) => {
      return (
        <>
          <ProductComponent title={title} />
        </>
      )
    },
    // width: "20%",
  },
  // {
  //   // title: "Product",
  //   dataIndex: "image_url",
  //   render: (image_url) => {
  //     return (
  //       <>
  //         <ProductComponent image_url={image_url} />
  //       </>
  //     )
  //   },
  //   width: "20%",
  // },
  {
    title: "Sales",
    dataIndex: "sales",
    render: (sales) => (
      <Typography className={css.sale}>{sales ?? "$121.00"}</Typography>
    ),
    // width: "10%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (amount) => (
      <Typography className={css.amount}>{amount ?? "$84,611"}</Typography>
    ),
    // width: "10%",
  },
  {
    title: "Price",
    dataIndex: "price",
    render: (price) => (
      <Typography className={css.price}>{price ?? "$121.00"}</Typography>
    ),
    // width: "10%",
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   render: (status) => {
  //     console.log(status)
  //     return (
  //       <Typography
  //         className={css.status_css}
  //         sx={{
  //           backgroundColor: status?.bgColr ?? "",
  //           color: status?.colr ?? "",
  //         }}
  //       >
  //         {status?.status_val ?? "Low Stock"}
  //       </Typography>
  //     )
  //   },
  //   width: "10%",
  // },
]
/* -----> Recent orders table column */
const recentOrderscolumns = [
  {
    title: "Product",
    dataIndex: "title",
    render: (title) => {
      return (
        <>
          <ProductComponent title={title} />
        </>
      )
    },
  },
  // {
  //   // title: "Product",
  //   dataIndex: "image_url",
  //   render: (image_url) => {
  //     return (
  //       <>
  //         <ProductComponent image_url={image_url} />
  //       </>
  //     )
  //   },
  //   width: "20%",
  // },
  {
    title: "Date",
    dataIndex: "date",
    render: (date) => (
      <Typography className={css.sale}>{date ?? "1 min ago"}</Typography>
    ),
  },

  {
    title: "Customer",
    dataIndex: "customer",
    render: (customer) => {
      return (
        <>
          <Typography className={css.image_title}>
            {customer?.name ?? "John Bushmill"}
          </Typography>
          <Typography className={css.image_description}>
            {customer?.email ?? "Johnb@mail.com"}
          </Typography>
        </>
      )
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    render: (total) => (
      <Typography className={css.amount}>{total ?? "$84,611"}</Typography>
    ),
    width: "15%",
  },
  {
    title: "Payment",
    dataIndex: "payment",
    render: (payment) => (
      <Typography className={css.price}>{payment ?? "Master"}</Typography>
    ),
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   render: (status) => {
  //     console.log(status)
  //     return (
  //       <Typography
  //         className={css.status_css}
  //         sx={{
  //           backgroundColor: status?.bgColr ?? "",
  //           color: status?.colr ?? "",
  //         }}
  //       >
  //         {status?.status_val ?? "Low Stock"}
  //       </Typography>
  //     )
  //   },
  //   width: "10%",
  // },

  {
    title: "Action",
    dataIndex: "action",
    render: () => {
      return (
        <Box className={css.actions_css}>
          <MdOutlineRemoveRedEye className={css.icon} />
          <LuPencil className={css.icon} />
        </Box>
      )
    },
  },
]

const staticUserData = [] // Your static data

const SellerDashboard = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const {
    get_product_by_user_id_data,
    get_product_by_user_id_status,
    get_product_by_user_id_error,
  } = useSelector((state) => state.product)

  const [tableParams, setTableParmas] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
      total: get_product_by_user_id_data?.length,
    },
  })

  const duration_filterArr = {
    defaultVal: "All Time",
    onChange: () => {},
    onSearch: () => {},
    durationArr: [
      { value: "All Time", label: "All Time" },
      { value: "12 Months", label: "12 Months" },
      { value: "30 Days", label: "30 Days" },
      { value: "7 Days", label: "7 Days" },
      { value: "24 Hours", label: "24 Hours" },
    ],
  }

  const states_arr = [
    {
      icon: cash_checked,
      title: "Total Revenue",
      price: "$75,500",
      discount: "+10%",
    },
    {
      icon: cart,
      title: "Total Sales",
      price: "31,500",
      discount: "+15%",
    },
    {
      icon: scan,
      title: "Product SKU",
      price: "247",
      discount: "+10%",
    },
    {
      icon: wallet,
      title: "Balance",
      price: "$24,500",
      discount: "-25%",
    },
  ]

  const navigate = useNavigate()

  const handleTabChange = (pagination) => {
    setTableParmas({
      pagination,
    })

    // Calculate the current page data based on pagination
    const { current, pageSize } = pagination
    const start = (current - 1) * pageSize
    const end = start + pageSize
    setData(staticUserData.slice(start, end))
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(set_product_status())
    dispatch(get_product_by_user_id_async())
  }, [])

  useEffect(() => {
    setLoading(true)
    // Simulating API call delay with setTimeout
    setTimeout(() => {
      const { current, pageSize } = tableParams.pagination
      const start = (current - 1) * pageSize
      const end = start + pageSize
      setData(staticUserData.slice(start, end))
      setLoading(false)
    }, 500) // Simulated API response delay of 500ms
  }, [tableParams])

  return (
    <Grid container>
      {/** <!-------- DURATION FILTER AND SELECT DATE BTN AND ADD PRODUCT BTN --------!> **/}

      <Grid
        container
        justifyContent={"space-between"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "wrap",
          xs: "wrap",
        }}
        direction={{
          xl: "row-reverse",
          lg: "row-reverse",
          md: "row-reverse",
          sm: "row-reverse",
        }}
        gap={1}
      >
        {/** <!-------- SELECT DATE AND ADD PRODUCT --------!> **/}
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MdOutlineDateRange size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Select Dates
                </Typography>
              </Stack>
            </CustomButton>

            {/* <CustomButton
              style={{
                border: "1px solid #FF9900",
                borderRadius: "8px",
              }}
              onClick={() => {
                navigate("/seller-dashboard/addproducts")
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FiPlus size={20} />
                <Typography
                  className={css.export_add_btn}
                  sx={{ color: "white" }}
                >
                  Add Product
                </Typography>
              </Stack>
            </CustomButton> */}
          </Stack>
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={3} xs={8}>
          <SelectField
            selectTitle={duration_filterArr.tit}
            onChange={() => {}}
            optionArr={duration_filterArr.durationArr}
            style={{ height: "43px", borderRadius: "8px", borderColor: "red" }}
            defaultVal={duration_filterArr.defaultVal}
          />
        </Grid>
      </Grid>
      {/** <!-------- STATES CARDS --------!> **/}
      <Grid
        container
        gap={2}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "wrap",
          xs: "wrap",
        }}
        justifyContent={"space-between"}
      >
        {states_arr.map((item, i) => {
          const cash_clr = item.icon === cash_checked
          const cart_clr = item.icon === cart
          const scan_clr = item.icon === scan

          return (
            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={5.8}
              xs={12}
              className={css.states_wrapper}
              key={i}
            >
              <img
                src={item.icon}
                alt="icon"
                style={{
                  backgroundColor: cash_clr
                    ? "#FCE8C4"
                    : cart_clr
                    ? "#CFE7DC"
                    : scan_clr
                    ? "#FCDAD7"
                    : "#FAE1CF",
                  boxShadow: `0px 0px 7px 6px ${
                    cash_clr
                      ? "#FFF9ED"
                      : cart_clr
                      ? "#E7F4EE"
                      : scan_clr
                      ? "#FEEDEC"
                      : "#FDF1E8"
                  }`,
                }}
                className={css.image}
              />
              <Typography className={css.title}>{item.title}</Typography>
              <Stack
                direction={"row"}
                mt={0.5}
                flexWrap={"wrap"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Typography className={css.price}>{item.price}</Typography>
                <Typography className={css.discount}>
                  {item.discount}
                </Typography>
              </Stack>
            </Grid>
          )
        })}
      </Grid>

      {/** <!-------- CHARTS --------!> **/}
      <Grid container direction={"column"} my={2} gap={2}>
        {/** <!-------- CRICULAR CHART --------!> **/}
        <Grid
          container
          direction={"column"}
          className={`${css.states_wrapper} ${css.progressbar}`}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid>
              <Typography variant="h6" className={css.sales_progress}>
                Sales Progress
              </Typography>
              <Typography className={css.quarter}>This Quarter</Typography>
            </Grid>
            <Grid>
              <MdMoreVert id={css.more_vert_icon} />
            </Grid>
          </Grid>

          <Grid
            container
            direction={"row-reverse"}
            justifyContent={"space-around"}
            my={2}
          >
            <Grid item mb={3}>
              <CustomSemiCircleProgressBar percentValue={500} />
            </Grid>
            <Stack>
              <Typography className={css.description}>
                You succeed earn <span>$240</span> today, its higher than
                yesterday
              </Typography>

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>
                  <Typography className={css.description}>Target</Typography>
                  <Typography mt={1} className={css.sales_progress}>
                    $20k <GoArrowDown color="#F36960" size={20} />
                  </Typography>
                </Box>
                <Box>
                  <Typography className={css.description}>Revenue</Typography>
                  <Typography mt={1} className={css.sales_progress}>
                    $16k <GoArrowUp color="#3DA172" size={20} />
                  </Typography>
                </Box>
                <Box>
                  <Typography className={css.description}>Today</Typography>
                  <Typography mt={1} className={css.sales_progress}>
                    $1.5k <GoArrowUp color="#3DA172" size={20} />
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {/** <!-------- STATES OR STRIGHT CHART --------!> **/}
        <Grid container className={css.states_wrapper} sx={{ overflow: "auto" }}>
          <StatesChart />
        </Grid>
      </Grid>

      {/** <!-------- TOP SELLING PRODUCTS TABLE --------!> **/}
      <Box className={`${css.table} tabled`} width={1}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={2}
        >
          <Typography className={css.title}>Top Selling Product</Typography>
          <CustomButton
            style={{
              backgroundColor: "white",
              border: "1px solid #E0E2E7",
              borderRadius: "8px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            onClick={() => {}}
          >
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <BiSlider size={20} color="#667085" />
              <Typography className={css.select_filter_btn}>Filters</Typography>
            </Stack>
          </CustomButton>
        </Stack>
        <Box className={css.table_wrapper}>
          <ProductListTable
            // handleTabChange={handleTabChange}
            loading={loading}
            pagination={tableParams.pagination}
            dataSource={get_product_by_user_id_data}
            columns={sellingPrductcolumns}
          />
        </Box>
      </Box>

      {/** <!-------- RECENT ORDERS TABLE --------!> **/}

      <Box className={`${css.table} tabled`} width={1}>
        <Stack
          direction={{
            xl: "row",
            lg: "row",
            md: "row",
            sm: "row",
            xs: "column",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={2}
          gap={1}
        >
          <Typography
            className={css.title}
            mt={{ xl: 0, lg: 0, md: 0, sm: 0, xs: 2 }}
          >
            Recent Orders <span style={{ fontSize: "12px" }}>+2 Orders</span>
          </Typography>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <BiSlider size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Filters
                </Typography>
              </Stack>
            </CustomButton>

            <CustomButton
              style={{
                border: "1px solid #FF9900",
                borderRadius: "8px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={() => {
                navigate("/seller-dashboard/addproducts")
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  className={css.export_add_btn}
                  sx={{ color: "white" }}
                >
                  See More
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Stack>
        <Box className={css.table_wrapper}>
          <ProductListTable
            // handleTabChange={handleTabChange}
            loading={loading}
            pagination={tableParams.pagination}
            dataSource={get_product_by_user_id_data}
            columns={recentOrderscolumns}
          />
        </Box>
      </Box>
    </Grid>
  )
}

export default SellerDashboard
