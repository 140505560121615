import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import css from "./OrderHistory.module.css"
import { PiNotebookDuotone } from "react-icons/pi"
import { PiPackageDuotone } from "react-icons/pi"
import { PiTruckDuotone } from "react-icons/pi"
import { PiHandshakeDuotone } from "react-icons/pi"
import { BiCheckDouble, BiSlider } from "react-icons/bi"
import { GoPerson } from "react-icons/go"
import { PiMapPinLine } from "react-icons/pi"
import { PiMapTrifold } from "react-icons/pi"
import { FaRegCircleCheck } from "react-icons/fa6"
import { PiNotepad } from "react-icons/pi"
import { capitalize } from "lodash"
import mobileImg from "../../../assets/mobile.png"
import CustomizedSteppers from "../../../components/common/stepper/Stepper"
import ProductTable from "../../../components/table/ProductTable/ProductTable"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { asyncStatus } from "../../../utils/asyncStatus"
import { set_product_status } from "../../../store/slices/product_slice"
import { get_product_by_user_id_async } from "../../../store/services/productsService"
import {
  get_order_buyer_detail_by_id_async,
  get_orders_async,
  get_orders_buyer_async,
} from "../../../store/services/ordersService"
import SelectField from "../../../components/common/SelectField/SelectField"
import CustomButton from "../../../components/common/Button/Button"
import { MdOutlineDateRange, MdOutlineRemoveRedEye } from "react-icons/md"
import { primaryColour } from "../../../utils/colorTheme"
import { DateAndTimeFormat } from "../../../utils/common_functions/date_format"

const ProductComponent = (props) => {
  const {
    product_data: { image, title },
    number_of_items,
  } = props
  return (
    <Stack spacing={1} direction={"row"}>
      <Stack
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#EFF0EB",
          height: 44,
          width: 44,
          objectFit: "contain",
          p: "4px",
        }}
      >
        <img
          height={"100%"}
          width={"100%"}
          src={image ?? ""}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>{title ?? ""}</Typography>
        <Typography className={css.products_qty}>
          {number_of_items > 1 &&
            `+${number_of_items - 1 ?? ""} other products`}
        </Typography>
      </Stack>
    </Stack>
  )
}

const OrderProductsComponent = (props) => {
  const { image_preview, title, quantity, description, number_of_items } = props
  return (
    <Stack spacing={1} direction={"row"}>
      <Stack
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#EFF0EB",
          height: 44,
          width: 44,
          objectFit: "contain",
          p: "4px",
        }}
      >
        <img
          height={"100%"}
          width={"100%"}
          src={image_preview ?? ""}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>{title ?? ""}</Typography>
        <Typography className={css.products_qty}>
          {description.slice(0, 20) ?? ""}...
        </Typography>
      </Stack>
    </Stack>
  )
}

const OrderHistory = () => {
  const [isOrderDetail, setIsOrderDetail] = useState(false)
  const dispatch = useDispatch()
  const {
    get_orders_buyer_status,
    get_orders_buyer_data,
    get_orders_buyer_detail_by_id_data,
    get_orders_buyer_detail_by_id_status,
  } = useSelector((state) => state.orders_slice)
  const orderListLoading = get_orders_buyer_status === asyncStatus.LOADING

  const {
    total_amount = 0,
    order_no = "",
    number_of_items = 0,
    orders = [],
    createdAt = "",
    order_note = "",
    billing_address_details: {
      address: bill_address = "",
      city: bill_city = "",
      region_state: bill_region_state = "",
      country: bill_country = "",
      zipcode: bill_zipcode = "",
      phone: bill_phone = "",
      email: bill_email = "",
      name: bill_name = "",
    } = {},
    shipping_address_details: {
      address: ship_address = "",
      city: ship_city = "",
      region_state: ship_region_state = "",
      country: ship_country = "",
      zipcode: ship_zipcode = "",
      phone: ship_phone = "",
      email: ship_email = "",
      name: ship_name = "",
    } = {},
  } = get_orders_buyer_detail_by_id_data || {}

  const totalProducts = orders?.flatMap(({ products }) => [...products])



  /* const steps = [
    {
      name: "Order Placed",
      icon: <PiNotebookDuotone color="#2DB224" size={"34px"} />,
    },
    {
      name: "Packaging",
      icon: <PiPackageDuotone color="#FA8232" size={"34px"} />,
    },
    {
      name: "On The Road",
      icon: <PiTruckDuotone color="#FFE7D6" size={"34px"} />,
    },
    {
      name: "Delivered",
      icon: <PiHandshakeDuotone color="#FFE7D6" size={"34px"} />,
    },
  ]

  const orderActivityArr = [
    {
      icon: <BiCheckDouble color="#2DB224" size={23} />,
      status:
        "Your order has been delivered. Thank you for shopping at SokoLingo",
      time: "23 Jan, 2021 at 7:32 PM",
      bgcolor: "#EAF7E9",
      brdrcolor: "#D5F0D3",
    },
    {
      icon: <GoPerson color="#2DA5F3" size={23} />,
      status:
        "Our delivery man (John Wick) Has picked-up your order for delvery. ",
      time: "23 Jan, 2021 at 2:00 PM",
      bgcolor: "#EAF6FE",
      brdrcolor: "#D5EDFD",
    },
    {
      icon: <PiMapPinLine color="#2DA5F3" size={23} />,
      status: "Your order has reached at last mile hub.",
      time: "22 Jan, 2021 at 8:00 AM",
      bgcolor: "#EAF6FE",
    },
    {
      icon: <PiMapTrifold color="#2DA5F3" size={23} />,
      status: "Your order on the way to (last mile) hub.",
      time: "21, 2021 at 5:32 AM",
      bgcolor: "#EAF6FE",
      brdrcolor: "#D5EDFD",
    },
    {
      icon: <FaRegCircleCheck color="#2DB224" size={23} />,
      status: "Your order is successfully verified.",
      time: "20 Jan, 2021 at 7:32 PM",
      bgcolor: "#EAF7E9",
      brdrcolor: "#D5F0D3",
    },
    {
      icon: <PiNotepad color="#2DA5F3" size={23} />,
      status: "Your order has been confirmevd.",
      time: "19 Jan, 2021 at 2:61 PM",
      bgcolor: "#EAF6FE",
      brdrcolor: "#D5EDFD",
    },
  ] */

  useEffect(() => {
    dispatch(get_orders_buyer_async())
  }, [])
  /** <!-------------- ORDER LIST TABLE DATA --------------!> **/
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_no",
      render: (order_id) => (
        <Typography className={css.order_id} sx={{ color: primaryColour }}>
          #{order_id ?? ""}
        </Typography>
      ),
    },
    {
      title: "Product",
      dataIndex: "",
      render: (props) => {
        return (
          <>
            <ProductComponent {...props} />
          </>
        )
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (date) => (
        <Typography className={css.date}>{date.split("T")[0] ?? ""}</Typography>
      ),
    },

    {
      title: "Total",
      dataIndex: "total_amount",
      render: (total) => (
        <Typography className={css.amount}>${total ?? ""}</Typography>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment_method",
      render: (payment) => (
        <Typography className={css.price} sx={{ textTransform: "capitalize" }}>
          {payment ?? ""}
        </Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "order_status",
      render: (status) => {
        const isPending = status === "pending"
        const isShiped = status === "shiped"
        const isDelivered = status === "delivered"
        return (
          <Typography
            className={css.status_css}
            sx={{
              backgroundColor: isPending
                ? "#FDF1E8"
                : isShiped
                ? "#E8F8FD"
                : isDelivered
                ? "#E7F4EE"
                : "#FEEDEC",
              color: isPending
                ? "#E46A11"
                : isShiped
                ? "#13B2E4"
                : isDelivered
                ? "#0D894F"
                : "#F04438",
              textTransform: "capitalize",
            }}
          >
            {status ?? ""}
          </Typography>
        )
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (id) => {
        return (
          <Box className={css.actions_css}>
            <MdOutlineRemoveRedEye
              className={css.icon}
              onClick={() => {
                setIsOrderDetail(true)
                dispatch(get_order_buyer_detail_by_id_async(id))
              }}
            />
            {/* <LuPencil className={css.icon} onClick={() => id} /> */}
          </Box>
        )
      },
    },
  ]

  const productsColumns = [
    {
      title: "Product",
      dataIndex: "",
      render: (props) => {
        return (
          <>
            <OrderProductsComponent {...props} />
          </>
        )
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (category) => (
        <Typography className={css.date}>{category.category ?? ""}</Typography>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (total) => (
        <Typography className={css.amount}>
          ${total.toLocaleString() ?? ""}
        </Typography>
      ),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      render: (quantity) => (
        <Typography className={css.price} sx={{ textTransform: "capitalize" }}>
          {quantity ?? ""}
        </Typography>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "",
      render: (data) => {
        console.log("dataaaa", data)
        return (
          <Typography
            className={css.price}
            sx={{ textTransform: "capitalize" }}
          >
            {(data?.quantity * data?.price)?.toLocaleString() ?? ""}
          </Typography>
        )
      },
    },
  ]

  const duration_filterArr = {
    defaultVal: "All Time",
    onChange: () => {},
    onSearch: () => {},
    durationArr: [
      { value: "All Time", label: "All Time" },
      { value: "12 Months", label: "12 Months" },
      { value: "30 Days", label: "30 Days" },
      { value: "7 Days", label: "7 Days" },
      { value: "24 Hours", label: "24 Hours" },
    ],
  }

  return isOrderDetail ? (
    <Grid container>
      <Grid container className={css.orderHistory_wrapper}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyItems={"center"}
          className={css.title_wrapper}
          py={0.5}
          pl={2}
        >
          <IconButton onClick={() => setIsOrderDetail(false)}>
            <ArrowBackIcon sx={{ color: "#191c1e", fontSize: 20 }} />
          </IconButton>
          <Button variant="text" className={css.title_btn}>
            Order Detail
          </Button>
        </Stack>

        {/* <!--------- PRODUCTS QUANTITY ---------!> */}

        <Box>
          <Grid container className={css.product_detail}>
            <Stack>
              <Typography className={css.order_detail_id}>
                #{order_no ?? ""}
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"flex-end"}
                justifyContent={"flex-start"}
                flexWrap={"wrap"}
              >
                <Typography className={css.products_count}>
                  {number_of_items ?? ""} Products
                </Typography>
                <Divider
                  sx={{
                    m: "10px",
                    width: 4,
                    height: 4,
                    borderRadius: "100%",
                    backgroundColor: "#475156",
                  }}
                />
                <Typography
                  className={css.order_place_date_time}
                  sx={{ whiteSpace: "wrap !important" }}
                >
                  Order Placed in {DateAndTimeFormat(createdAt)[0]} at{" "}
                  {DateAndTimeFormat(createdAt)[1]}
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <Typography className={css.order_total_price}>
                ${total_amount?.toLocaleString() ?? ""}
              </Typography>
            </Box>
          </Grid>

          {/* <!--------- ORDER TRACKING RECORD ---------!> */}

          {/* <Grid container className={css.stepper_wrapper}>
            <Typography className={css.order_arrival_date}>
              Order expected arrival{" "}
              <span className={css.arrival_date}>23 Jan, 2021</span>
            </Typography>
            <Box className={css.stepper}>
              <CustomizedSteppers stepperData={steps} />
            </Box>
          </Grid> */}
        </Box>

        {/* <!--------- ORDER ACTIVITY ---------!> */}

        {/* <Stack
          alignItems={"flex-start"}
          width={1}
          sx={{ py: 1, px: 0.5, borderTop: "1px solid rgb(218 213 213)" }}
        >
          <Stack width={1} alignItems={"flex-start"} px={3.5} py={1}>
            <Button
              sx={{ textTransform: "capitalize" }}
              className={css.title_btn}
            >
              Order Activity
            </Button>
          </Stack>
          <Stack spacing={1} p={"11px 27px"}>
            {orderActivityArr.map((delivery_status, i) => {
              return (
                <Stack
                  gap={2}
                  direction={"row"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  key={i}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className={css.icon_box}
                    sx={{
                      border: `1px solid ${delivery_status.brdrcolor}`,
                      backgroundColor: delivery_status.bgcolor,
                    }}
                  >
                    {delivery_status.icon}
                  </Stack>
                  <Stack
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                  >
                    <Typography className={css.delivery_status_title}>
                      {delivery_status.status}
                    </Typography>
                    <Typography className={css.delivery_status_time_date}>
                      {delivery_status.time}
                    </Typography>
                  </Stack>
                </Stack>
              )
            })}
          </Stack>
        </Stack> */}
      </Grid>

      {/* <!--------- PRODUCTS BUYER ADDRESS CARDS ---------!> */}

      <Grid container className={css.product_buyer_card}>
        <Grid item xl={3.8} lg={3.8} md={3.8} sm={5.5} xs={11} px={1}   pb={1}>
          <Typography variant="h6" className={css.card_title}>
            Billing Address
          </Typography>

          <Stack justifyContent={"space-between"} height={"100%"}>
            <Typography className={css.card_label}>
              {bill_name ?? ""}
            </Typography>
            <Typography className={css.card_address}>
              {`${bill_address} ${bill_city} ${bill_region_state} ${bill_country} ${bill_zipcode}` ??
                ""}
            </Typography>
            <Typography className={css.card_label}>
              Phone Number: <span>{bill_phone ?? ""}</span>
            </Typography>

            <Stack
              direction={{
                xl: "row",
                lg: "row",
                md: "column",
                sm: "row",
                xs: "row",
              }}
            >
              <Typography
                className={css.card_label}
                sx={{ margin: "0px !important" }}
              >
                Email:&nbsp;
              </Typography>

              <Typography component="span" className={css.email_txt}>
                {bill_email}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "block",
              xs: "none",
            },
          }}
        />
        <Grid
          item
          xl={3.8}
          lg={3.8}
          md={3.8}
          sm={5.5}
          xs={11}
          px={1}
          pb={1}
          sx={{
            borderTop: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "1px solid rgb(218 213 213)",
            },
            borderBottom: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "1px solid rgb(218 213 213)",
            },
          }}
        >
          <Typography variant="h6" className={css.card_title}>
            Shipping Address
          </Typography>
          <Stack justifyContent={"space-between"} height={"100%"}>
            <Typography className={css.card_label}>
              {ship_name ?? ""}
            </Typography>
            <Typography className={css.card_address}>
              {`${ship_address} ${ship_city} ${ship_region_state} ${ship_country} ${ship_zipcode}`}
            </Typography>
            <Typography className={css.card_label}>
              Phone Number: <span>{ship_phone}</span>
            </Typography>
            <Stack
              direction={{
                xl: "row",
                lg: "row",
                md: "column",
                sm: "row",
                xs: "row",
              }}
            >
              <Typography
                className={css.card_label}
                sx={{ margin: "0 !important" }}
              >
                Email: &nbsp;
              </Typography>

              <Typography component="span" className={css.email_txt}>
                {ship_email}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        />
        <Grid item xl={3.8} lg={3.8} md={3.8} sm={5.5} xs={11} px={1}>
          <Typography variant="h6" className={css.card_title}>
            Order Notes
          </Typography>
          <Box>
            <Typography className={css.card_address}>
              {order_note ?? ""}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* <!--------- PRODUCTS TABLE ---------!> */}
      <Grid container className={css.products}>
        <Box className={css.title_wrapper}>
          <Button
            sx={{ textTransform: "capitalize" }}
            className={css.title_btn}
          >
            Products{" "}
            {totalProducts && (
              <span style={{ color: "#5F6C72" }}>
                &nbsp; ({`${totalProducts?.length}`})
              </span>
            )}
          </Button>
        </Box>

        <Stack width={1}>
          {orders?.length > 0 &&
            orders?.map(
              ({
                products,
                number_of_items,
                number_of_units,
                seller_details: { full_name, email },
              }) => {
                return (
                  <Box width={1} sx={{ overflow: "auto", p: 0 }}>
                    <Stack
                      px={3.5}
                      py={1}
                      justifyContent={"space-between"}
                      direction={"row"}
                      alignItems={"center"}
                      flexWrap={{
                        xl: "nowrap",
                        lg: "nowrap",
                        md: "nowrap",
                        sm: "nowrap",
                        xs: "wrap",
                      }}
                      gap={2}
                    >
                      <Box>
                        <Typography className={css.title_btn}>
                          {full_name}
                        </Typography>
                        <Typography className={css.delivery_status_time_date}>
                          {email}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={css.delivery_status_time_date}>
                          Total Products &nbsp;&nbsp;&nbsp;
                          <Typography
                            component="span"
                            className={css.title_btn}
                          >
                            {number_of_items}
                          </Typography>
                        </Typography>
                        <Typography className={css.delivery_status_time_date}>
                          Total Quantity &nbsp;&nbsp;&nbsp;
                          <Typography
                            component="span"
                            className={css.title_btn}
                          >
                            {number_of_units}
                          </Typography>
                        </Typography>
                      </Box>
                    </Stack>
                    <Box
                      className={`${css.table_wrapper} tabled`}
                      width={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 0.95 }}
                      sx={{ borderRadius: 0, margin: 0 }}
                    >
                      <ProductListTable
                        // handleTabChange={handleTabChange}
                        loading={
                          get_orders_buyer_detail_by_id_status ===
                          asyncStatus.LOADING
                        }
                        // pagination={tableParams.pagination}
                        dataSource={products}
                        columns={productsColumns}
                      />
                    </Box>
                  </Box>
                )
              },
            )}
        </Stack>
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      {/* ---- search filter and select Date btn and filter btn ---- */}

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Grid item xl={3} lg={3} md={3} sm={3} xs={6} alignItems={"center"}>
          <SelectField
            selectTitle={duration_filterArr.tit}
            onChange={() => {}}
            optionArr={duration_filterArr.durationArr}
            style={{ height: "43px", borderRadius: "8px", borderColor: "red" }}
            defaultVal={duration_filterArr.defaultVal}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <MdOutlineDateRange size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Select Dates
                </Typography>
              </Stack>
            </CustomButton>

            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <BiSlider size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Filters
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>

      <Box
        className={`${css.table_wrapper} tabled`}
        width={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 0.95 }}
      >
        <ProductListTable
          // handleTabChange={handleTabChange}
          loading={orderListLoading}
          // pagination={tableParams.pagination}
          dataSource={get_orders_buyer_data}
          columns={columns}
        />
      </Box>
    </Grid>
  )
}

export default OrderHistory
