import { ThemeProvider } from "@mui/material/styles"
import AppRouter from "./config/router/AppRouter"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { app_mui_theme } from "./utils/colorTheme"

const App = () => {
  return (
    // <ThemeProvider theme={app_mui_theme}>
    <>
      <AppRouter />
      <ToastContainer />
    </>
    // </ThemeProvider>
  )
}

export default App
