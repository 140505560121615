import AuthContainer from "../../../components/common/AuthContainer/AuthContainer"
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import AuthInput from "../../../components/common/AuthInput/AuthInput"
import CustomButton from "../../../components/common/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { reset_password_create_async } from "../../../store/services/authService"
import { useState } from "react"
import { useEffect } from "react"
import { asyncStatus } from "../../../utils/asyncStatus"
import { setResetOtpStatusState } from "../../../store/slices/auth_slice"

function ResetPassword() {
  const [data, setdata] = useState("")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { reset_status, reset_data, reset_otp_data } = useSelector(
    (state) => state.auth,
  )

  const loader = reset_status === asyncStatus.LOADING

  const submitHandle = () => {
    let obj = {
      email: reset_otp_data?.email,
      otp: reset_otp_data?.otp,
      ...data,
    }

    dispatch(reset_password_create_async(obj))
  }

  useEffect(() => {
    if (reset_status === asyncStatus.SUCCEEDED) {
      navigate("/login")
    }

    dispatch(setResetOtpStatusState())
  }, [reset_status])

  return (
    <Container>
      <AuthContainer>
        <Stack maxWidth={600} mx={"auto"} gap={2}>
          <Stack>
            <Stack>
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: 40,
                    md: 55,
                  },
                }}
                className="heading"
              >
                Reset Password
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                The Verification Email will be sent to the mailbox. please check
                it !
              </Typography>
            </Stack>
          </Stack>
          <Grid container rowGap={3} mt={2}>
            <Grid item xs={12}>
              <AuthInput
                forPassword
                showPassHiddenIcon
                onChange={(e) =>
                  setdata({ ...data, newPassword: e.target.value })
                }
                label={"New Password"}
              />
            </Grid>
            <Grid item xs={12}>
              <AuthInput
                forPassword
                showPassHiddenIcon
                onChange={(e) =>
                  setdata({ ...data, confirmPassword: e.target.value })
                }
                label={"Confirm Password"}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                onClick={submitHandle}
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: 17,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: data === "" && "lightgrey",
                }}
                disable={data === ""}
              >
                {loader ? (
                  <CircularProgress size={22} sx={{ color: "white" }} />
                ) : (
                  "Send"
                )}
              </CustomButton>
            </Grid>
          </Grid>
        </Stack>
      </AuthContainer>
    </Container>
  )
}

export default ResetPassword
