import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const get_buyer_address_async = createAsyncThunk(
  type_constants.GET_BUYER_ADDRESS,
  async () => {
    try {
      const response = await apiHandle.get(`/user/buyer/get-address-details`)
      return response.data
    } catch (error) {
      console.log("GET BUYER ADDRESS ERROR ===>", error)
      if (error.response.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

/** <!------------ BILLING AND SHIPPING ADDRESS AND BUYER ACCOUNT SETTING METHODS ------------!>  **/

export const add_buyer_billing_address_async = createAsyncThunk(
  type_constants.ADD_BUYER_BILLING_ADDRESS,
  async (billing_address) => {
    try {
      const response = await apiHandle.post(
        `/user/buyer/billing-address-details`,
        billing_address,
      )
      return response.data
    } catch (error) {
      console.log("ADD BUYER BILLING ADDRESS ERROR ===>", error)
      if (error.response.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const add_buyer_shipping_address_async = createAsyncThunk(
  type_constants.ADD_BUYER_SHIPPING_ADDRESS,
  async (shipping_address) => {
    try {
      const response = await apiHandle.post(
        `/user/buyer/shipping-address-details`,
        shipping_address,
      )
      return response.data
    } catch (error) {
      console.log("ADD BUYER SHIPPING ADDRESS ERROR ===>", error)
      if (error.response.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
