import {
  Grid,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Divider,
  Alert,
} from "@mui/material"
import Button from "@mui/material/Button"
import React, { useEffect, useState } from "react"
import Navbar from "../../components/common/navbar/Navbar"
import { useNavigate } from "react-router-dom"
import CardAddCamera from "../../components/Cards/CardAddCamera/CardAddCamera"
import { useDispatch, useSelector } from "react-redux"
import {
  add_to_cart_qty_async,
  get_cart_product_async,
} from "../../store/services/cartmanageService"
import { asyncStatus } from "../../utils/asyncStatus"
import css from "./AddtoCart.module.css"
import Input from "../../components/common/Input/Input"
import { primaryColour } from "../../utils/colorTheme"
import { negotiation_async } from "../../store/services/negotiationServices"
import { negotiationState } from "../../store/slices/negotiation_slice"

const AddCartScreen = () => {
  const [negotiationAmount, setNegotiationAmount] = useState({})

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { get_cart_data, get_cart_status } = useSelector(
    (state) => state.cartManageSlice,
  )
  const { negotiation_status } = useSelector((state) => state.negotiation_slice)

  const loader = get_cart_status === asyncStatus.LOADING

  const delete_product_from_cart = (id) => {
    dispatch(
      add_to_cart_qty_async({
        quantity: 0,
        product_id: id,
      }),
    )
  }

  const handleIncre_Decre = (key, id, qty) => {
    if (key === "inrement") {
      dispatch(
        add_to_cart_qty_async({
          quantity: qty + 1,
          product_id: id,
        }),
      )
    } else {
      if (qty === 0) {
        dispatch(
          add_to_cart_qty_async({
            quantity: 0,
            product_id: id,
          }),
        )
      } else {
        dispatch(
          add_to_cart_qty_async({
            quantity: qty - 1,
            product_id: id,
          }),
        )
      }
    }
  }

  const calculate_total_seller_amount = () => {
    let total = 0

    get_cart_data?.forEach((first_elem) => {
      const { data } = first_elem
      const total_nested = data?.reduce((a, b) => a + +b.price * b.quantity, 0)
      total += total_nested
    })
    return total
  }

  const handleNegotiationChange = (id, amount) => {
    setNegotiationAmount({ ...negotiationAmount, [id]: amount })
  }

  const handleNegotiation = (id) => {
    dispatch(
      negotiation_async({
        seller_id: id,
        offer_amount: negotiationAmount[id] || "",
      }),
    )
    setNegotiationAmount({ ...negotiationAmount, [id]: "" })
  }

  useEffect(() => {
    dispatch(get_cart_product_async())
  }, [])
  return (
    <>
      <Navbar />
      <Box
        sx={{
          width: 1,
          pt: 2,
          px: {
            xl: 10,
            lg: 10,
            md: 10,
            sm: 10,
            xs: 3,
          },
        }}
      >
        <Typography
          sx={{ fontSize: "23px", fontWeight: "700", color: "black", mb: 1 }}
        >
          Your Cart
        </Typography>
      </Box>

      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        m={"auto"}
        py={2}
        px={{
          xl: 10,
          lg: 10,
          md: 3,
          sm: 3,
          xs: 3,
        }}
        gap={2}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "wrap",
          xs: "wrap",
        }}
      >
        {/**
         * ------------- ORDERED CARTS ------------- *
         **/}

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          {/* <Typography className={css.summery_description}>
            Check your item and select your Shipping for better <br />
            experience order item
          </Typography> */}
          {negotiation_status === asyncStatus.SUCCEEDED && (
            <Alert
              color="warning"
              severity="warning"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigate("/dashboard/negotiation")
                    dispatch(negotiationState())
                  }}
                >
                  Click here
                </Button>
              }
            >
              Request has been sent, To check your negotiation requests
            </Alert>
          )}

          {loader ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={"20vh"}
            >
              <CircularProgress
                size={30}
                sx={{ color: "rgba(248, 149, 0, 1)" }}
              />
            </Stack>
          ) : !get_cart_data?.length ? (
            <Box width={0.95} py={5}>
              <Typography textAlign={"center"} variant="h5">
                Your cart is empty.
              </Typography>
            </Box>
          ) : (
            get_cart_data?.map(
              (
                {
                  data,
                  seller_name,
                  // total_due_cost,
                  // total_products_count,
                  seller_id,
                },
                i,
              ) => {
                let total_amount_of_seller = 0
                return (
                  <Stack mb={1} key={i} sx={{ backgroundColor: "#f9f9f9" }}>
                    <Box
                      sx={{
                        borderBottom: "2px solid white",
                        mb: 2,
                        px: 3,
                        py: 1.5,
                      }}
                    >
                      <Typography
                        sx={{ mb: 0.1 }}
                        className={css.secondary_txt}
                      >
                        {seller_name ?? ""}
                      </Typography>
                    </Box>

                    {data?.map(
                      (
                        {
                          product_id,
                          title,
                          quantity,
                          images,
                          feature_image,
                          price,
                          description,
                          // total_amount,
                          is_favorite,
                        },
                        i,
                      ) => {
                        let amount_product = +price * quantity
                        total_amount_of_seller += amount_product

                        return (
                          <CardAddCamera
                            key={i}
                            cartImage={images[feature_image]}
                            price={+price}
                            title={title}
                            description={description}
                            isFavorite={is_favorite}
                            productId={product_id}
                            delete_product={() =>
                              delete_product_from_cart(product_id)
                            }
                            product_quantity={quantity}
                            handle_Increment={() =>
                              handleIncre_Decre(
                                "inrement",
                                product_id,
                                quantity,
                              )
                            }
                            handle_decrement={() =>
                              handleIncre_Decre(
                                "decrement",
                                product_id,
                                quantity,
                              )
                            }
                          />
                        )
                      },
                    )}

                    {/**
                     *
                     * ------------- NEGOTIATION ------------- *
                     *
                     **/}

                    <Stack
                      alignItems={"flex-end"}
                      sx={{ borderTop: "1px solid #C5C5C5" }}
                      px={3}
                      pb={3}
                    >
                      <Stack
                        direction={"column"}
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                        my={1}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px !important",
                            color: "lightgray",
                          }}
                        >
                          Total Amount
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "20px !important",
                            color: primaryColour,
                          }}
                          className={css.secondary_txt}
                        >
                          ${total_amount_of_seller.toLocaleString()}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                        gap={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                        flexWrap={{
                          xl: "nowrap",
                          lg: "nowrap",
                          md: "nowrap",
                          sm: "nowrap",
                          xs: "wrap",
                        }}
                        width={1}
                      >
                        <Box width={1}>
                          <Input
                            onChange={(e) =>
                              handleNegotiationChange(seller_id, e.target.value)
                            }
                            placeholder="Enter amount"
                            type="number"
                            value={negotiationAmount[seller_id]}
                            style={{ width: "100%" }}
                          />
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "#979797",
                            }}
                          >
                            Send a quote for negotiation with seller
                          </Typography>
                        </Box>

                        <Button
                          variant="contained"
                          onClick={() => handleNegotiation(seller_id)}
                          sx={{
                            fontSize: "13px",
                            fontWeight: "600",
                            borderRadius: "4px",
                            letterSpacing: "1px",
                            backgroundColor: "#fa8232",
                            textTransform: "capitalize",
                            whiteSpace: "nowrap",
                            px: 2.5,
                            py: "8px",
                            "&:hover": { backgroundColor: "#fa8232" },
                          }}
                          disabled={!negotiationAmount[seller_id]}
                        >
                          Send Request
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                )
              },
            )
          )}
        </Grid>

        {/**
         * ------------- ORDER SUMMARY ------------- *
         **/}

        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "#f9f9f9",
            height: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: "2px solid white",
              mb: 2,
              px: 2,
              py: 1.5,
            }}
          >
            <Typography
              sx={{
                mb: 0.1,
              }}
              className={css.secondary_txt}
            >
              Summary Order
            </Typography>
          </Box>

          <Box sx={{ px: 2 }}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={1}
            >
              <Typography className={css.primary_txt}>Sub Total</Typography>
              <Typography className={css.primary_txt}>
                {calculate_total_seller_amount().toLocaleString()}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={1}
            >
              <Typography className={css.primary_txt}>Shipping Fee</Typography>
              <Typography className={css.primary_txt}>$200</Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={10}
            >
              <Typography className={css.primary_txt}>
                Shipping Fee Discount
              </Typography>
              <Typography className={css.primary_txt}>$80</Typography>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={1.5}
            >
              <Typography className={css.secondary_txt}>Total</Typography>
              <Typography className={css.secondary_txt}>
                $
                {(
                  calculate_total_seller_amount() +
                  (200 - 80)
                ).toLocaleString()}
              </Typography>
            </Stack>

            <Button
              variant="contained"
              onClick={() => navigate("/checkout")}
              sx={{
                fontSize: "13px",
                fontWeight: "600",
                borderRadius: "10px",
                letterSpacing: "1px",
                backgroundColor: "#fa8232",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                "&:hover": { backgroundColor: "#fa8232" },
                width: 1,
                mb: 2,
              }}
            >
              Checkout Now
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AddCartScreen
