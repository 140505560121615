import React from "react"

const DashboardTitle = ({title}) => {
  const style = {
    backgroundColor: "#fa8232",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
  const style_title = { color: "white", fontSize: "25px", fontWeight: "600" }

  return (
    <div style={style}>
      <h1 style={style_title}>{title}</h1>
    </div>
  )
}

export default DashboardTitle
