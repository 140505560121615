import * as React from "react"
import { styled } from "@mui/material/styles"
import Stack from "@mui/material/Stack"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { TiTick } from "react-icons/ti"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import { Box, Typography } from "@mui/material"
import css from "./Stepper.module.css"

// custom
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#FA8232",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#FA8232",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#FFE7D6",
    borderRadius: 1,
  },
}))

// custom

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "white",
  zIndex: 1,
  color: "#fff",
  width: 25,
  height: 25,
  display: "flex",
  borderRadius: "50%",
  border: "2px solid #FA8232",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#FA8232",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#FA8232",
  }),
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <TiTick size={20} />,
    2: "",
    3: "",
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

const steps = ["Select campaign settings", "Create an ad group", "Create an ad"]

const CustomizedSteppers = ({ stepperData }) => {
  return (
    <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
      {stepperData.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Box className={css.stepper_txt_icon}>
              {label.icon}
              <Typography>{label.name}</Typography>
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default CustomizedSteppers
