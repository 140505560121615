import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const signup_async = createAsyncThunk(
  type_constants.SIGNUP,
  async (signup_data) => {
    try {
      const res = await apiHandle.post(`register`, signup_data)
      const data = await res.data
      return data
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const login_async = createAsyncThunk(
  type_constants.LOGIN,
  async (login_data) => {
    try {
      const res = await apiHandle.post(`login`, login_data)
      const data = await res.data
      return data
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const check_auth_async = createAsyncThunk(
  type_constants.CHECK_AUTH,
  async () => {
    try {
      const response = await apiHandle.get("/check-auth")
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the big ERROR is here", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const verify_otp_async = createAsyncThunk(
  type_constants.VERIFY_OTP,
  async (otpData) => {
    try {
      const response = await apiHandle.post("/user/verify-otp", otpData)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the big ERROR is here", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const reset_password_req_async = createAsyncThunk(
  type_constants.RESET_PASSWORD_REQ,
  async (email) => {
    try {
      const response = await apiHandle.post("/reset-password-req", email)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the RESET PASSWORD ERROR is here", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const reset_password_otp_verify_async = createAsyncThunk(
  type_constants.RESET_PASSWORD_OTP,
  async (dataa) => {
    try {
      const response = await apiHandle.post("/reset-password-otp-verify", dataa)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the RESET PASSWORD ERROR is here", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const reset_password_create_async = createAsyncThunk(
  type_constants.RESET_PASSWORD,
  async (dataa) => {
    try {
      const response = await apiHandle.post("/reset-password-create", dataa)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the RESET PASSWORD ERROR is here", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)


export const buyer_avatar_image_async = createAsyncThunk(
  type_constants.BUYER_AVATAR_IMAGE,
  async (avatar_image) => {
    try {
      const response = await apiHandle.put("/change-avatar", avatar_image)
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("THE BUYER AVATAR IMAGE UPLOAD ERROR IS HERE", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
