import { PhoneInput } from "react-international-phone"
import "react-international-phone/style.css"
import "./select.css"

const CountryCodePicker = ({ sethandlePhoneNumber }) => {
  // const [phone, setPhone] = useState("");
  // sethandlePhoneNumber(phone);

  return (
    <PhoneInput
      defaultCountry="pk"
      // value={phone}
      onChange={sethandlePhoneNumber}
    />
  )
}
export default CountryCodePicker
