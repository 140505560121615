export default function timeAgo(utcDate) {
  const date = new Date(utcDate) // Convert UTC string to a Date object
  const now = new Date()
  const seconds = Math.floor((now - date) / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)
  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`
  } else {
    return "just now"
  }
}

export const DateAndTimeFormat = (givenTime) => {
  const timeStamp = new Date(givenTime)
  const pakistanTime = new Date(timeStamp.getTime() + 5 * 60 * 60 * 1000)

  // Extract date and time separately
  const day = ("0" + pakistanTime.getDate()).slice(-2)
  const month = ("0" + (pakistanTime.getMonth() + 1)).slice(-2)
  const year = pakistanTime.getFullYear()
  let hours = pakistanTime.getHours()
  const minutes = ("0" + pakistanTime.getMinutes()).slice(-2)

  const period = hours >= 12 ? "pm" : "am"
  hours = hours % 12 || 12 // Convert 0 to 12 for 12-hour format
  const time12hFormat = `${hours}:${minutes} ${period}`
  const dateFormat = `${day}:${month}:${year}`
  return [dateFormat, time12hFormat]
}
