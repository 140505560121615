import { createSlice } from "@reduxjs/toolkit"
import { asyncStatus } from "../../utils/asyncStatus"
import {
  add_cart_product_async,
  add_to_cart_qty_async,
  get_cart_product_async,
} from "../services/cartmanageService"
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification"
import { negotiation_async } from "../services/negotiationServices"
import { add_wishlist_products_async } from "../services/wishlistService"

const initialState = {
  cart_data: null,
  get_cart_data: null,
  cart_status: asyncStatus.IDLE,
  get_cart_status: asyncStatus.IDLE,
  // total_products_amount:0,

  cart_error: null,
  get_cart_error: null,

  cart_qty_error: null,
  cart_qty_status: null,
}

const cart_manage_slice = createSlice({
  name: "cartManageSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // add product in cart

    builder.addCase(add_cart_product_async.pending, (state, { payload }) => {
      state.cart_status = asyncStatus.LOADING
    })
    builder.addCase(add_cart_product_async.fulfilled, (state, { payload }) => {
      state.cart_data = payload.data
      state.cart_status = asyncStatus.SUCCEEDED
      state.cart_error = null
      success_toast_message(payload.message)
    })
    builder.addCase(add_cart_product_async.rejected, (state, actions) => {
      state.cart_data = null
      state.cart_status = asyncStatus.ERROR
      state.cart_error = actions?.error?.message
    })

    // get product cart

    builder.addCase(get_cart_product_async.pending, (state, { payload }) => {
      state.get_cart_status = asyncStatus.LOADING
    })
    builder.addCase(get_cart_product_async.fulfilled, (state, { payload }) => {
      state.get_cart_data = payload.data
      state.get_cart_status = asyncStatus.SUCCEEDED
      state.get_cart_error = null
    })
    builder.addCase(get_cart_product_async.rejected, (state, actions) => {
      state.get_cart_data = null
      state.get_cart_status = asyncStatus.ERROR
      state.get_cart_error = actions?.error?.message
      error_toast_message(actions?.error?.message)
    })

    // Add to Cart product quantity

    builder.addCase(add_to_cart_qty_async.pending, (state, { payload }) => {
      state.cart_qty_status = asyncStatus.LOADING
    })
    builder.addCase(add_to_cart_qty_async.fulfilled, (state, { payload }) => {
      const { seller_id, product_id, quantity } = payload.data

      const find_main_index = state.get_cart_data.findIndex(
        (e) => e.seller_id === seller_id,
      )

      const nested_find_index = state.get_cart_data[
        find_main_index
      ].data.findIndex((e) => e.product_id === product_id)

      if (quantity === 0) {
        if (state.get_cart_data[find_main_index].data.length === 1) {
          state.get_cart_data.splice(find_main_index, 1)
        } else {
          state.get_cart_data[find_main_index].data.splice(nested_find_index, 1)
        }
      } else {
        state.get_cart_data[find_main_index].data[nested_find_index].quantity =
          quantity
      }

      state.cart_qty_status = asyncStatus.SUCCEEDED
      state.cart_qty_error = null
    })
    builder.addCase(add_to_cart_qty_async.rejected, (state, actions) => {
      state.cart_qty_status = asyncStatus.ERROR
      state.cart_qty_error = actions?.error?.message
    })

    builder.addCase(negotiation_async.fulfilled, (state, { payload }) => {
      state.get_cart_data = state.get_cart_data.filter(
        (elem) => elem.seller_id !== payload.data.seller_id,
      )
    })

    // WISHLIST UPDATED REALTIME

    builder.addCase(
      add_wishlist_products_async.fulfilled,
      (state, { payload }) => {
        const { is_add, seller_id, product_id } = payload.data

        if (state.get_cart_data) {
          const main_index = state.get_cart_data.findIndex(
            (e) => e?.seller_id === seller_id,
          )
          if (main_index !== -1) {
            const nested_index = state.get_cart_data[main_index].data.findIndex(
              (e) => e.product_id === product_id,
            )
            if (nested_index !== -1) {
              state.get_cart_data[main_index].data[nested_index].is_favorite =
                is_add
            }
          }
        }
      },
    )
  },
})

export default cart_manage_slice.reducer
