import React, { useEffect } from "react"
import "./customDropdown.css"
import { IoIosArrowDown } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { get_all_categories_async } from "../../store/services/categoriesService"
import { useNavigate } from "react-router-dom"
import { Stack } from "@mui/material"
import { get_all_products_async } from "../../store/services/productsService"
import { asyncStatus } from "../../utils/asyncStatus"
import { SAVE_CATEGORY_ID } from "../../store/constant"

const CustomDropdown = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categories_data } = useSelector(
    (state) => state.categoriesManageSlice,
  )

  useEffect(() => {
    dispatch(get_all_categories_async())
  }, [])

  const click_handle = (id) => {
    dispatch(get_all_products_async({ data: { category_id: id } }))
    localStorage.setItem(SAVE_CATEGORY_ID, id)
    navigate(`/category`)
  }

  return (
    <div className="dropdown" style={{ width: "100%" }}>
      <Stack
        gap={1}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <button className="dropbtn">Categories</button>
        <IoIosArrowDown size={19} />
      </Stack>
      <div className="dropdown-content">
        {categories_data?.map((e, i) => {
          return (
            <a
              key={i}
              onClick={() => click_handle(e._id)}
              style={{ cursor: "pointer" }}
            >
              {e.category_title}
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default CustomDropdown
