import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"

export const place_order_async = createAsyncThunk(
  type_constants.PLACE_ORDER,
  async (order_id) => {
    try {
      const res = await apiHandle.post(
        `/user/place-negotiation-order`,
        order_id,
      )

      const data = await res?.data
      return data
    } catch (error) {
      console.log("ORDER PLACE NEGOTIATION ERROR ====>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_orders_async = createAsyncThunk(
  type_constants.GET_ORDERS,
  async () => {
    try {
      const res = await apiHandle.get(`/user/seller/get-orders-list`)
      const data = await res?.data
      return data
    } catch (error) {
      console.log("GET ORDERS LIST NEGOTIATION ERROR ====>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_orders_buyer_async = createAsyncThunk(
  type_constants.GET_ORDERS_BUYER,
  async () => {
    try {
      const response = await apiHandle.get(`/user/buyer/get-orders-list`)
      return response.data
    } catch (error) {
      console.log(`GET ORDERS LIST BUYER  ERROR ===>`, error)
      if (error.response.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_order_detail_by_id_async = createAsyncThunk(
  type_constants.GET_ORDER_DETAIL_BY_ID,
  async (order_id) => {
    try {
      const response = await apiHandle.get(
        `/user/seller/get-order-details/${order_id ?? ""}`,
      )
      return response.data
    } catch (error) {
      console.log(`GET ORDER DETAIL BY ID ERROR ===>`, error)
      if (error.response.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)

export const get_order_buyer_detail_by_id_async = createAsyncThunk(
  type_constants.GET_ORDER_BUYER_DETAIL_BY_ID,
  async (order_id) => {
    try {
      const response = await apiHandle.get(
        `/user/buyer/get-order-details/${order_id ?? ""}`,
      )
      return response.data
    } catch (error) {
      console.log(`GET ORDER BUYER DETAIL BY ID ERROR ===>`, error)
      if (error.response.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
