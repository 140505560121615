import React, { useState } from "react"
import css from "./EditProduct.module.css"

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import TextFieldLabel from "../../../components/common/TextField/TextField"
import { BiDollar } from "react-icons/bi"
import { Upload_image_component } from "../../../components/UploadFile/UploadFile"
import axios from "axios"
import { baseURL } from "../../../config/apiHandle/apiHandle"
import ClearIcon from "@mui/icons-material/Clear"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import circle from "../../../assets/circle-image.png"
import { useDispatch, useSelector } from "react-redux"
import SelectField from "../../../components/common/SelectField/SelectField"
import CustomButton from "../../../components/common/Button/Button"
import { RxCross2 } from "react-icons/rx"
import { useEffect } from "react"
import { asyncStatus } from "../../../utils/asyncStatus"
import {
  add_product_async,
  edit_product_async,
  get_product_by_product_id_async,
  get_all_products_async,
} from "../../../store/services/productsService"
import { ChromePicker, SketchPicker } from "react-color"
import { useNavigate, useParams } from "react-router-dom"
import {
  get_all_categories_async,
  get_all_sub_categories_async,
  get_sub_categories_by_category_id_async,
} from "../../../store/services/categoriesService"
import { BiEditAlt } from "react-icons/bi"
import { success_toast_message } from "../../../utils/toast_notification"
import { set_edit_product_status } from "../../../store/slices/product_slice"

const EditProduct = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [submitData, setSubmitData] = useState({})
  const [postImage, setPostImage] = useState([])
  const [Color, setColor] = useState([])
  const [selectedImg, setSelectedImg] = useState()
  const [features, setFeatures] = useState([])
  const [addFeaturesData, setAddFeaturesData] = useState("")
  const [allSizes, setAllSizes] = useState([])
  const [addSizesData, setAddSizesData] = useState("")
  const [renderingObject, setRenderingObject] = useState({})

  const fields_style_obj = {
    backgroundColor: "#F9F9FC",
    borderRadius: "8px",
    border: "1px solid #E0E2E7",
  }

  const {
    data_from_product_id_status,
    data_from_product_id_data,
    edit_product_status,
  } = useSelector((state) => state.product)
  const { userData } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(get_all_categories_async())
    dispatch(get_all_sub_categories_async())
    dispatch(get_product_by_product_id_async(params))
  }, [])

  useEffect(() => {
    if (data_from_product_id_data && data_from_product_id_data.length > 0) {
      setSubmitData((prevData) => ({
        ...prevData,
        title: data_from_product_id_data[0]?.title,
        description: data_from_product_id_data[0]?.description,
        price: data_from_product_id_data[0]?.price,
      }))

      setPostImage((prevImg) => [
        ...data_from_product_id_data[0].images.filter((img) => img !== prevImg),
      ])
      setColor((prevClr) => [
        ...data_from_product_id_data[0].color.filter((clr) => clr !== prevClr),
      ])
      setFeatures((prevFeatur) => [
        ...data_from_product_id_data[0].features.filter(
          (featur) => featur !== prevFeatur,
        ),
      ])
      setAllSizes((prevSize) => [
        ...data_from_product_id_data[0].size.filter(
          (size) => size !== prevSize,
        ),
      ])
      setSelectedImg(data_from_product_id_data[0]?.feature_image)
    }
  }, [data_from_product_id_data])

  const upload_image = async (files) => {
    try {
      let uploadedUrlArray = []
      await Promise.all(
        Array.from(files).map(async (file) => {
          const formData = new FormData()
          formData.append("binary_file", file)
          try {
            const response = await axios.post(
              `${baseURL}upload-files`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            )
            uploadedUrlArray.push(response.data.url)
          } catch (err) {
            console.error(err)
          }
        }),
      )
      setPostImage((prevPostImages) => [...prevPostImages, ...uploadedUrlArray])
    } catch (error) {
      console.error(error)
    }
  }

  const removeImage = (ind) => {
    let a = [...postImage]
    a.splice(ind, 1)
    setPostImage([...a])
  }
  const removeColor = (ind) => {
    let update = [...Color]
    update.splice(ind, 1)
    setColor([...update])
  }

  const onChange_handle = (key, value) => {
    setSubmitData({ ...submitData, [key]: value })
  }

  const addFeatures = () => {
    setFeatures([...features, addFeaturesData])
    setAddFeaturesData("")
  }
  const removefeature = (ind) => {
    let update = [...features]
    update.splice(ind, 1)
    setFeatures([...update])
  }

  const addSize = () => {
    setAllSizes([...allSizes, addSizesData])
    setAddSizesData("")
  }
  const removeSize = (ind) => {
    let update = [...allSizes]
    update.splice(ind, 1)
    setAllSizes([...update])
  }

  const edit_product_func = () => {
    let obj = {
      // ...Subcategories,
      ...submitData,
      product_id: params.id,
      color: [...Color],
      images: [...postImage],
      feature_image: selectedImg,
      features: features,
      size: allSizes,
      user_id: userData._id,
      // sub_category_title: renderingObject.sub_category_title,
      in_stock: true,
    }
    dispatch(edit_product_async(obj))

    // dispatch(add_product_async())
  }

  useEffect(() => {
    if (edit_product_status === asyncStatus.SUCCEEDED) {
      dispatch(get_all_products_async())
      dispatch(set_edit_product_status())
      navigate("/seller-dashboard/productslist")
    }
  }, [edit_product_status])

  return (
    <>
      {data_from_product_id_status === asyncStatus.LOADING ? (
        <Stack
          height={"50vh"}
          width={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress size={30} sx={{ color: "#fa8232" }} />
        </Stack>
      ) : (
        <Grid width={1}>
          <Typography className={css.title}>Edit Product</Typography>

          {/* ---------general_info_section----- */}

          {data_from_product_id_data?.[0]?.title && (
            <Grid className={css.general_info_wrapper}>
              <Box className={css.general_title}>
                <Typography>General Information</Typography>
              </Box>

              <TextFieldLabel
                inputTitle={"Product Name"}
                placeholder={"Type product name here. . ."}
                defaultValue={data_from_product_id_data?.[0]?.title}
                onChange={(e) => onChange_handle("title", e.target.value)}
                disabled={false}
                error={"Please provide product name"}
                type={"text"}
                style={fields_style_obj}
                title_style={{
                  color: "#4D5464",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              />
            </Grid>
          )}
          {data_from_product_id_data?.[0]?.description && (
            <Grid className={css.general_info_wrapper}>
              <TextFieldLabel
                inputTitle={"Description"}
                placeholder={"Type product description here. . ."}
                defaultValue={data_from_product_id_data?.[0]?.description}
                value={submitData.description}
                onChange={(e) => onChange_handle("description", e.target.value)}
                disabled={false}
                error={"Please provide a product description"}
                type={"textarea"}
                style={fields_style_obj}
                title_style={{
                  color: "#4D5464",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              />
            </Grid>
          )}
          {/* ---------media_section----- */}

          {postImage.length > 0 && (
            <Grid item className={css.general_info_wrapper}>
              <Box className={css.general_title}>
                <Typography>Media</Typography>
              </Box>
              <Box>
                <Stack bgcolor={"#F9F9FC"} width={"100%"}>
                  <Grid
                    container
                    gap={2}
                    alignItems={"center"}
                    px={2}
                    py={1}
                    flexWrap={{
                      xl: "wrap",
                      lg: "wrap",
                      md: "wrap",
                      sm: "wrap",
                      xs: "wrap",
                    }}
                  >
                    {postImage.map((e, i) => {
                      return (
                        <Grid
                          item
                          xl={2}
                          lg={2.2}
                          md={2.4}
                          sm={3}
                          xs={5.7}
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#E8E7E3",
                            borderRadius: "10px",
                          }}
                          px={"5px"}
                          py={"2px"}
                          key={i}
                        >
                          <img
                            style={{
                              height: 110,
                              width: 110,
                              objectFit: "contain",
                            }}
                            src={e}
                          />
                          <IconButton
                            sx={{
                              color: "white",
                              backgroundColor: "grey",
                              position: "absolute",
                              top: 5,
                              right: 5,
                              cursor: "pointer",
                              height: "25px",
                              width: "25px",
                            }}
                          >
                            <ClearIcon
                              onClick={() => removeImage(i)}
                              color="black"
                            />
                          </IconButton>
                        </Grid>
                      )
                    })}
                    <IconButton
                      component="label"
                      sx={{
                        color: "black",
                        padding: 1,
                        borderRadius: 2,
                        ml: 1,
                        // ":hover": {
                        //   backgroundColor: "orange",
                        //   color: "orange",
                        // },
                      }}
                    >
                      <Stack alignItems={"center"} justifyContent={"center"}>
                        <img width={60} src={circle} />
                        <input
                          type="file"
                          onChange={(e) => upload_image(e.target.files)}
                          multiple
                          hidden
                        />
                        <Typography color={"lightgray"}>
                          Click to add images
                        </Typography>
                      </Stack>
                    </IconButton>
                  </Grid>
                </Stack>
                <Box></Box>
              </Box>
            </Grid>
          )}

          {/* ---------features image_section----- */}
          {postImage.length > 0 && (
            <Grid item className={css.general_info_wrapper}>
              <Box className={css.general_title}>
                <Typography>Feature Image</Typography>
              </Box>
              <Box>
                <Stack bgcolor={"#F9F9FC"} width={"100%"}>
                  <Grid container gap={2} alignItems={"center"} p={1}>
                    {/* <Stack bgcolor={"lightgray"} width={1}> */}
                    {postImage.map((e, i) => {
                      return (
                        <Grid
                          item
                          xl={2}
                          lg={2.2}
                          md={2.4}
                          sm={3}
                          xs={5.7}
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#E8E7E3",
                            borderRadius: "10px",
                          }}
                          px={"5px"}
                          py={"2px"}
                          key={i}
                        >
                          <img
                            style={{
                              height: 110,
                              width: 110,
                              objectFit: "contain",
                            }}
                            src={e}
                          />
                          <CheckCircleIcon
                            onClick={() => setSelectedImg(i)}
                            color={selectedImg === i ? "success" : "disabled"}
                            sx={{
                              fontSize: 30,
                              position: "absolute",
                              zIndex: 101,
                              top: 5,
                              right: 5,
                              cursor: "pointer",
                            }}
                          />
                        </Grid>
                      )
                    })}
                    {/* </Stack> */}
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          )}

          {/* ---------color section----- */}

          {Color.length > 0 && (
            <Grid item className={css.general_info_wrapper}>
              <Box className={css.general_title}>
                <Typography>Color</Typography>
              </Box>
              <Box>
                <Stack direction={"row"} gap={1.5} my={3}>
                  {Color?.map((e, i) => {
                    return (
                      <Stack key={i}>
                        <Stack position={"relative"}>
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              borderRadius: 100,
                              backgroundColor: e,
                            }}
                          ></Box>
                        </Stack>
                        <Stack position={"absolute"}>
                          <ClearIcon
                            onClick={() => removeColor(i)}
                            color="error"
                            sx={{
                              fontSize: 20,
                              bgcolor: "white",
                              position: "absolute",
                              zIndex: 101,
                              top: -7,
                              left: 20,
                              cursor: "pointer",
                              borderRadius: "20px",
                            }}
                          />
                        </Stack>
                      </Stack>
                    )
                  })}
                </Stack>
              </Box>
              <Box>
                <Grid>
                  <label for="Select Color">Add color</label>
                  <input
                    type="color"
                    id="select_color"
                    onChange={(e) => setColor([...Color, e.target.value])}
                  />
                </Grid>
                <Box></Box>
              </Box>
            </Grid>
          )}
          {/* ---------Pricing_section----- */}

          {data_from_product_id_data?.[0]?.price && (
            <Grid item className={css.general_info_wrapper}>
              <Box className={css.general_title}>
                <Typography>Pricing</Typography>
              </Box>
              <Box>
                <Grid>
                  <TextFieldLabel
                    inputTitle={"Base Price"}
                    placeholder={"Type Base Price here. . ."}
                    defaultValue={data_from_product_id_data?.[0]?.price}
                    value={submitData.price}
                    onChange={(e) => onChange_handle("price", e.target.value)}
                    disabled={false}
                    error={"Please provide price!"}
                    type={"text"}
                    style={fields_style_obj}
                  />
                </Grid>
                <Box></Box>
              </Box>
            </Grid>
          )}

          {/* ---------Features_section----- */}

          {features.length > 0 && (
            <Grid item className={css.general_info_wrapper}>
              <Box className={css.general_title}>
                <Typography>Features</Typography>
              </Box>
              <Box>
                <Grid>
                  {features?.map((e, i) => {
                    return (
                      <Stack
                        direction={"row"}
                        my={2}
                        gap={2}
                        alignItems={"center"}
                        key={i}
                      >
                        <Typography alignItems={"center"}>
                          {i + 1} {e}
                        </Typography>
                        <ClearIcon
                          onClick={() => removefeature(i)}
                          color="error"
                          sx={{
                            fontSize: 20,
                            bgcolor: "white",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        />
                      </Stack>
                    )
                  })}
                  <TextFieldLabel
                    // inputTitle={"Features"}
                    placeholder={"Type Features here. . ."}
                    onChange={(e) => setAddFeaturesData(e.target.value)}
                    disabled={false}
                    value={addFeaturesData}
                    error={"Please provide Features!"}
                    type={"text"}
                    style={fields_style_obj}
                  />
                  <CustomButton
                    disable={addFeaturesData === ""}
                    onClick={addFeatures}
                    children={"Add feature"}
                    style={{
                      backgroundColor: addFeaturesData === "" && "lightgrey",
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
          )}
          {/* Size Section */}

          {allSizes.length > 0 && (
            <Grid item className={css.general_info_wrapper}>
              <Box className={css.general_title}>
                <Typography>Size</Typography>
              </Box>
              <Box>
                <Grid>
                  {allSizes?.map((e, i) => {
                    return (
                      <Stack
                        direction={"row"}
                        my={2}
                        gap={2}
                        alignItems={"center"}
                        key={i}
                      >
                        {/* <Typography>Features</Typography> */}
                        <Typography alignItems={"center"}>
                          {i + 1} {e}
                        </Typography>
                        {/* <CustomButton  children={"del"}/> */}
                        <ClearIcon
                          onClick={() => removeSize(i)}
                          color="error"
                          sx={{
                            fontSize: 20,
                            bgcolor: "white",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        />
                      </Stack>
                    )
                  })}
                  <TextFieldLabel
                    // inputTitle={"Features"}
                    placeholder={"Type Size here. . ."}
                    onChange={(e) => setAddSizesData(e.target.value)}
                    disabled={false}
                    value={addSizesData}
                    error={"Please provide Sizes!"}
                    type={"text"}
                    style={fields_style_obj}
                  />
                  <CustomButton
                    disable={addSizesData === ""}
                    style={{
                      backgroundColor: addSizesData === "" && "lightgrey",
                    }}
                    onClick={addSize}
                    children={"Add Size"}
                  />
                </Grid>
              </Box>
            </Grid>
          )}

          {/* ---- add product and cancel button ---- */}
          <Stack direction={"row"} spacing={2}>
            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #858D9D",
                borderRadius: "8px",
              }}
              children={
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <RxCross2 size={20} color="#858D9D" />
                  <Typography className={css.cancel_btn}>Cancel</Typography>
                </Stack>
              }
              onClick={() => navigate("/seller-dashboard/productslist")}
            />
            <CustomButton
              style={{
                border: "1px solid #FF9900",
                borderRadius: "8px",
              }}
              children={
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {edit_product_status === asyncStatus.LOADING ? (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  ) : (
                    <BiEditAlt size={20} />
                  )}

                  <Typography
                    className={`${css.cancel_btn} ${css.add_product_btn}`}
                  >
                    Update Product
                  </Typography>
                </Stack>
              }
              onClick={edit_product_func}
            />
          </Stack>
        </Grid>
      )}
    </>
  )
}

export default EditProduct
