import { Typography } from "@mui/material"
import React from "react"

export const Error_Routes = () => {
  return (
    <Typography
      color={"red"}
      variant="h4"
      textAlign={"center"}
      fontWeight={"bold"}
      mt={2}
    >
      ERROR 404 PAGE NOT FOUND
    </Typography>
  )
}
