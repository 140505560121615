import React, { useEffect } from "react"
import css from "./Dashboard.module.css"
import { PiRocketDuotone } from "react-icons/pi"
import { PiReceiptDuotone } from "react-icons/pi"
import { PiPackageDuotone } from "react-icons/pi"
import { Box, Grid, Stack, Typography } from "@mui/material"
import { Avatar } from "@mui/joy"
import Button from "@mui/material/Button"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { RiVisaLine } from "react-icons/ri"

import CreditCardModal from "../../../components/CreditCardModal/CreditCardModal"
import { useState } from "react"
import Table from "../../../components/table/Table/Table"
import PaymentCard from "../../../components/Cards/paymentCard/PaymentCard"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { get_buyer_address_async } from "../../../store/services/addressService"
import { get_orders_buyer_async } from "../../../store/services/ordersService"

const Dashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { userData } = useSelector((state) => state.auth)
  const { get_buyer_address_data } = useSelector((state) => state.address_slice)
  const { get_orders_buyer_data } = useSelector((state) => state.orders_slice)

  useEffect(() => {
    dispatch(get_buyer_address_async())
    dispatch(get_orders_buyer_async())
  }, [])

  const { address, city, country, email, name, phone, region_state, zipcode } =
    get_buyer_address_data?.billing_address || {}

  const open = !!anchorEl

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const columnData = ["ORDER ID", "STATUS", "DATE", "TOTAL", "ACTION"]
  const paymentData = [
    {
      orderid: "#71667167",
      status: "COMPLETED",
      date: "Feb 2, 2019 19:28",
      total: "$80 (11 Products)",
      action: (
        <Button
          sx={{
            color: "rgba(250, 130, 50, 1)",
            textTransform: "capitalize",
          }}
          endIcon={<ArrowForwardIcon />}
        >
          View Details
        </Button>
      ),
    },
    {
      orderid: "#95214362",
      status: "CANCELED",
      date: "Feb 2, 2019 19:28",
      total: "$80 (11 Products)",
      action: (
        <Button
          sx={{
            color: "rgba(250, 130, 50, 1)",
            textTransform: "capitalize",
          }}
          endIcon={<ArrowForwardIcon />}
        >
          View Details
        </Button>
      ),
    },
  ]

  const paymentCardDetail = [
    {
      cardUserName: "Kevin Gilbert",
      cardNameIcon: <RiVisaLine />,
      cardNumber: "1234 2456 0987 3814",
    },
    {
      cardUserName: "John Doe",
      cardNameIcon: <RiVisaLine />,
      cardNumber: "6543 2456 0987 3814",
    },
  ]

  return (
    <>
      <Grid item className={css.dashboard_wrapper}>
        <Typography>
          Hello, {userData?.first_name ?? ""} {userData?.last_name ?? ""}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "55%",
          }}
        >
          <Typography width={"100%"}>
            From your account dashboard. you can easily check & view your
            <span
              onClick={() => navigate("/dashboard/orderhistory")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Recent Orders
            </span>{" "}
            , manage your
            <span
              onClick={() => navigate("/dashboard/setting")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Shipping and Billing Addresses
            </span>{" "}
            and edit your
            <span
              onClick={() => navigate("/dashboard/security")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Password
            </span>{" "}
            and{" "}
            <span
              onClick={() => navigate("/dashboard/setting")}
              style={{ cursor: "pointer" }}
            >
              Account Details.
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        item
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        justifyContent={{
          xl: "space-between",
          lg: "space-between",
          md: "space-between",
          sm: "space-around",
          xs: "space-around",
        }}
        flexWrap={"wrap"}
        mb={3}
      >
        {/* account info div */}
        <Grid
          item
          className={`${css.account_billing} ${css.account_info}`}
          xl={3.8}
          lg={3.8}
          md={3.8}
          sm={5.5}
          xs={11}
        >
          <Box>
            <Typography>ACCOUNT INFO</Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <Avatar
                  alt="Travis Howard"
                  src={userData?.profile_avatar ?? ""}
                />
                <Box>
                  <Typography className={css.person_name}>
                    {userData?.first_name ?? ""} {userData?.last_name ?? ""}
                  </Typography>
                  {/* <Typography className={css.description}>
                    Dhaka - 1207, Bangladesh
                  </Typography> */}
                </Box>
              </Box>
              <Box>
                <Typography className={css.label_txt}>
                  Email: &nbsp;
                  <span className={css.description}>
                    {userData?.email ?? ""}
                  </span>
                </Typography>
                <Typography className={css.label_txt}>
                  Phone: &nbsp;
                  <span className={css.description}>
                    {userData?.contact_number ?? ""}
                  </span>
                </Typography>
              </Box>
            </Box>

            <Button variant="outlined" className={css.edt_btn}>
              Edit Account
            </Button>
          </Box>
        </Grid>
        {/* billing address div */}
        <Grid
          item
          className={`${css.account_billing} ${css.billing_address}`}
          xl={3.8}
          lg={3.8}
          md={3.8}
          sm={5.5}
          xs={11}
        >
          <Box>
            <Typography>BILLING ADDRESS</Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <Typography className={css.person_name}>
                  {name ?? ""}
                </Typography>
                <Typography className={css.description}>
                  {`${address ?? ""} ${city ?? ""} ${region_state ?? ""} ${
                    country ?? ""
                  } ${zipcode ?? ""}` ?? ""}
                </Typography>
              </Box>
              <Box>
                <Typography className={css.label_txt}>
                  Phone Number: &nbsp;
                  <span className={css.description}>{phone ?? ""}</span>
                </Typography>
                <Typography className={css.label_txt}>
                  Email: &nbsp;
                  <span className={css.description}>{email ?? ""}</span>
                </Typography>
              </Box>
            </Box>
            <Button
              variant="outlined"
              className={css.edt_btn}
              onClick={() => navigate("/dashboard/setting")}
            >
              Edit Address
            </Button>
          </Box>
        </Grid>

        {/* order detail */}

        <Grid item container xl={3.8} lg={3.8} md={3.8} sm={5.5} xs={11}>
          <Stack className={`${css.orders_Boxes} ${css.orders_Box1}`}>
            <Box>
              <PiRocketDuotone color="rgba(45, 165, 243, 1)" />
            </Box>
            <Box>
              <Typography>{get_orders_buyer_data?.length ?? ""}</Typography>
              <Typography>Total Orders</Typography>
            </Box>
          </Stack>
          <Stack className={`${css.orders_Boxes} ${css.orders_Box2}`}>
            <Box>
              <PiReceiptDuotone color="rgba(250, 130, 50, 1)" />
            </Box>
            <Box>
              <Typography>05</Typography>
              <Typography>Pending Orders</Typography>
            </Box>
          </Stack>
          <Stack className={`${css.orders_Boxes} ${css.orders_Box3}`}>
            <Box>
              <PiPackageDuotone color="rgba(45, 178, 36, 1)" />
            </Box>
            <Box>
              <Typography>149</Typography>
              <Typography>Completed Orders</Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      {/* payment method */}

      <Grid
        container
        item
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        justifyContent={{
          xl: "space-between",
          lg: "space-between",
          md: "space-between",
          sm: "space-around",
          xs: "space-around",
        }}
        flexWrap={"wrap"}
        className={css.payment_method}
      >
        <Box>
          <Typography>PAYMENT OPTION</Typography>
          <Button
            sx={{
              color: "rgba(250, 130, 50, 1)",
              textTransform: "capitalize",
            }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => setIsModalOpen(true)}
          >
            Add Card
          </Button>

          {/*--------- Modal---------- */}
          <CreditCardModal
            openModal={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />

          {/* <MoreVertIcon
            onClick={handleClick}
            sx={{
              color: "rgba(250, 130, 50, 1)",
              fontSize: "20px",
              cursor: "pointer",
            }}
          />

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Edit Card</MenuItem>
            <MenuItem onClick={handleClose}>Delete Card</MenuItem>
          </Menu> */}
        </Box>

        <Grid item container width={1}>
          <Grid
            item
            container
            gap={2}
            direction={"row"}
            width={1}
            justifyContent={{
              xl: "flex-start",
              lg: "flex-start",
              md: "space-around",
              sm: "space-around",
              xs: "space-around",
            }}
          >
            {paymentCardDetail.map((cardDetail, i) => {
              return (
                <PaymentCard
                  key={i}
                  card_number={cardDetail.cardNumber}
                  card_company_icon={cardDetail.cardNameIcon}
                  card_user_name={cardDetail.cardUserName}
                />
              )
            })}
          </Grid>
        </Grid>
      </Grid>

      {/* user payment details */}

      <Grid
        container
        item
        display={"flex"}
        flexDirection={"row"}
        justifyContent={{
          xl: "space-between",
          lg: "space-between",
          md: "space-between",
          sm: "space-around",
          xs: "space-around",
        }}
        flexWrap={"wrap"}
        className={`${css.payment_method} ${css.payment_detail} ${css.payment_detail_table}`}
      >
        <Box>
          <Typography>RECENT ORDER</Typography>
          <Button
            sx={{
              color: "rgba(250, 130, 50, 1)",
              textTransform: "capitalize",
            }}
            endIcon={<ArrowForwardIcon />}
          >
            View All
          </Button>
        </Box>

        <Grid item container width={1}>
          <Table data={paymentData} columnData={columnData} />
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
