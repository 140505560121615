import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import React, { useEffect } from "react"
import css from "./CardsAdress.module.css"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { RiVisaLine } from "react-icons/ri"
import CreditCardModal from "../../../components/CreditCardModal/CreditCardModal"
import { useState } from "react"
import PaymentCard from "../../../components/Cards/paymentCard/PaymentCard"
import { useDispatch, useSelector } from "react-redux"
import { get_buyer_address_async } from "../../../store/services/addressService"
import { useNavigate } from "react-router-dom"

const CardsAdress = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { get_buyer_address_data } = useSelector((state) => state.address_slice)

  const {
    address: bill_address,
    city: bill_city,
    country: bill_country,
    email: bill_email,
    name: bill_name,
    phone: bill_phone,
    region_state: bill_region_state,
    zipcode: bill_zipcode,
  } = get_buyer_address_data?.billing_address || {}
  const {
    address: shipp_address,
    city: shipp_city,
    country: shipp_country,
    email: shipp_email,
    name: shipp_name,
    phone: shipp_phone,
    region_state: shipp_region_state,
    zipcode: shipp_zipcode,
  } = get_buyer_address_data?.shipping_address || {}

  const open = !!anchorEl

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const paymentCardDetail = [
    {
      cardUserName: "Kevin Gilbert",
      cardNameIcon: <RiVisaLine />,
      cardNumber: "1234 2456 0987 3814",
    },
    {
      cardUserName: "John Doe",
      cardNameIcon: <RiVisaLine />,
      cardNumber: "6543 2456 0987 3814",
    },
  ]

  useEffect(() => {
    dispatch(get_buyer_address_async())
  }, [])
  return (
    <Grid item container className={css.card_address_wrapper}>
      {/* <!--------- PAYMENT METHOD ---------!> */}

      <Grid
        container
        item
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        justifyContent={{
          xl: "space-between",
          lg: "space-between",
          md: "space-between",
          sm: "space-around",
          xs: "space-around",
        }}
        flexWrap={"wrap"}
        className={css.payment_method}
      >
        <Box>
          <Typography>PAYMENT OPTION</Typography>
          <Button
            sx={{
              color: "rgba(250, 130, 50, 1)",
              textTransform: "capitalize",
            }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => setIsModalOpen(true)}
          >
            Add Card
          </Button>
          {/* <!--------- MODAL ----------!> */}
          <CreditCardModal
            openModal={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />
          {/* <MoreVertIcon
            onClick={handleClick}
            sx={{
              color: "rgba(250, 130, 50, 1)",
              fontSize: "20px",
              cursor: "pointer",
            }}
          />

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Edit Card</MenuItem>
            <MenuItem onClick={handleClose}>Delete Card</MenuItem>
          </Menu> */}
        </Box>

        <Grid
          item
          container
          gap={2}
          direction={"row"}
          width={1}
          justifyContent={{
            xl: "flex-start",
            lg: "flex-start",
            md: "space-around",
            sm: "space-around",
            xs: "space-around",
          }}
        >
          {paymentCardDetail.map((cardDetail, i) => {
            return (
              <PaymentCard
                key={i}
                card_number={cardDetail.cardNumber}
                card_company_icon={cardDetail.cardNameIcon}
                card_user_name={cardDetail.cardUserName}
              />
            )
          })}
        </Grid>
      </Grid>

      <Grid item container className={css.address}>
        {/* <!--------- BILLING ADDRESS ---------!> */}
        <Grid
          item
          className={css.account_billing}
          xl={5.8}
          lg={5.8}
          md={5.8}
          sm={5.8}
          xs={12}
        >
          <Box>
            <Typography>BILLING ADDRESS</Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <Typography className={css.person_name}>
                  {bill_name ?? ""}
                </Typography>
                <Typography className={css.description}>
                  {`${bill_address ?? ""} ${bill_city ?? ""} ${
                    bill_region_state ?? ""
                  } ${bill_country ?? ""} ${bill_zipcode ?? ""}` ?? ""}
                </Typography>
              </Box>
              <Box>
                <Typography className={css.label_txt}>
                  Phone Number: &nbsp;
                  <span className={css.description}>{bill_phone ?? ""}</span>
                </Typography>
                <Typography className={css.label_txt}>
                  Email: &nbsp;
                  <span className={css.description}>{bill_email ?? ""}</span>
                </Typography>
              </Box>
            </Box>
            <Button
              variant="outlined"
              className={css.edt_btn}
              onClick={() => navigate("/dashboard/setting")}
            >
              Edit Address
            </Button>
          </Box>
        </Grid>

        {/* <!--------- SHIPPING ADDRESS ---------!> */}
        <Grid
          item
          className={css.account_billing}
          xl={5.8}
          lg={5.8}
          md={5.8}
          sm={5.8}
          xs={12}
        >
          <Box>
            <Typography>SHIPPING ADDRESS</Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <Typography className={css.person_name}>
                  {shipp_name ?? ""}
                </Typography>
                <Typography className={css.description}>
                  {`${shipp_address ?? ""} ${shipp_city ?? ""} ${
                    shipp_region_state ?? ""
                  } ${shipp_country ?? ""} ${shipp_zipcode ?? ""}` ?? ""}
                </Typography>
              </Box>
              <Box>
                <Typography className={css.label_txt}>
                  Phone Number: &nbsp;
                  <span className={css.description}>{shipp_phone ?? ""}</span>
                </Typography>
                <Typography className={css.label_txt}>
                  Email: &nbsp;
                  <span className={css.description}>{shipp_email ?? ""}</span>
                </Typography>
              </Box>
            </Box>
            <Button
              variant="outlined"
              className={css.edt_btn}
              onClick={() => navigate("/dashboard/setting")}
            >
              Edit Address
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CardsAdress
