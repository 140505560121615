import { Box, Grid, Stack, Typography } from "@mui/material"
import { AiFillCheckCircle } from "react-icons/ai"
import StarRating from "../../StarRating/StarRating"
import { useState } from "react"
import { useEffect } from "react"

export const ProductCard_Grid = ({
  inStock,
  title,
  description,
  Rating,
  price,
  productImg,
  avgRating,
  onClick,
}) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  const handleDescriptionchars = (desc) =>
    desc.length > 70 ? `${desc.slice(0, 67)}...` : desc

  useEffect(() => {
    const handleInnerWidth = () => setInnerWidth(window.innerWidth)
    window.addEventListener("resize", handleInnerWidth)
    return window.removeEventListener("resize", handleInnerWidth)
  }, [])

  return (
    <Grid
      item
      xs={innerWidth < 350 ? 12 : 6}
      p={1}
      sm={4}
      md={
        window.location.pathname.includes("/category") ||
        window.location.pathname.includes("/dashboard/wishlist")
          ? 3
          : 3
      }
      lg={
        window.location.pathname.includes("/category") ||
        window.location.pathname.includes("/dashboard/wishlist")
          ? 3
          : 2
      }
      xl={
        window.location.pathname.includes("/category") ||
        window.location.pathname.includes("/dashboard/wishlist")
          ? 3
          : 2
      }
      height={"370px"}
    >
      <Stack
        sx={{
          border: "1px solid #efefef",
          borderRadius: "5px",
          overflow: "hidden",
          backgroundColor: "#fff",
          height: "100%",
          maxWidth: {
            sm: "100%",
            md: "100%",
          },
          gap: 1,

          cursor: "pointer",
        }}
        onClick={onClick}
      >
        {/* <Stack>
          <Typography color="#78A962">
            {inStock ? (
              <>
                <AiFillCheckCircle /> in Stock
              </>
            ) : (
              <>Out Of Stock</>
            )}
          </Typography>
        </Stack> */}
        <Box sx={{ minHeight: "200px", backgroundColor: "#E8E7E3", p: 1 }}>
          <img
            src={productImg}
            alt={description?.slice(0, 10)}
            style={{ objectFit: "contain", height: "100%", width: "100%" }}
          />
        </Box>

        <Stack justifyContent={"space-between"} px={1} pb={1} height={1}>
          <Box>
            <Typography
              sx={{
                color: "#565656",
                fontSize: "14px",
                fontWeight: "700",
                // my: 0.9,
              }}
            >
              {title ?? "Title he na"}
            </Typography>
            <Typography
              sx={{
                color: "#565656",
                fontSize: "13px",
                fontWeight: "400",
                lineHeight: "14px",
                // my: 0.9,
              }}
            >
              {handleDescriptionchars(description ?? "")}
            </Typography>
          </Box>
          <Stack gap={1} justifyContent={"space-between"}>
            <StarRating
              row={true}
              averageRating={avgRating}
              totalRatings={Rating}
            />
            <Typography
              sx={{
                color: "black",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "15px",
              }}
            >
              ${price}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  )
}

export const ProductCard_List = ({
  inStock,
  title,
  description,
  Rating,
  price,
  productImg,
  avgRating,
  onClick,
}) => {
  const handleDescriptionchars = (desc) =>
    desc.length > 70 ? `${desc.slice(0, 67)}...` : desc
  return (
    <Grid
      container
      alignItems={"center"}
      mx={2}
      sx={{
        border: "1px solid #efefef",
        borderRadius: "5px",
        overflow: "hidden",
      }}
      onClick={onClick}
    >
      <Grid item xl={3} md={3} lg={3} sm={3} xs={12}>
        <Box sx={{ height: "160px", backgroundColor: "#E8E7E3", p: 1 }}>
          <img
            src={productImg}
            alt={description?.slice(0, 10)}
            style={{ objectFit: "contain" }}
            height={"100%"}
            width={"100%"}
          />
        </Box>
      </Grid>

      <Grid item md={6} lg={6} xs={6} sm={6} xl={6} px={2} height={1}>
        <Stack justifyContent={"space-around"} px={1} pb={1} height={1}>
          <Box>
            <Typography
              sx={{
                color: "#615e5e",
                fontSize: "16px",
                fontWeight: "600",
                my: 0.9,
                textTransform: "capitalize",
              }}
              // className={css.secondary_txt}
            >
              {title ?? ""}
            </Typography>

            <Typography
              sx={{
                color: "#979797",
                fontSize: "13px !important",
                fontWeight: "400 !important",
                lineHeight: "14px",
              }}
            >
              {handleDescriptionchars(description ?? "")}
            </Typography>
          </Box>
          <StarRating
            row={true}
            averageRating={avgRating}
            totalRatings={Rating}
          />
        </Stack>
      </Grid>

      <Grid
        item
        md={3}
        lg={3}
        xs={3}
        sm={3}
        xl={3}
        justifyContent={"flex-end"}
        display={"flex"}
        height={1}
        pr={2}
        py={3}
      >
        <Typography
          sx={{
            color: "#615e5e",
            fontSize: {
              xs: 15,
              sm: 20,
              md: 20,
              lg: 20,
              xl: 20,
            },
            fontWeight: "600",
            lineHeight: "15px",
          }}
        >
          ${price}
        </Typography>
      </Grid>
    </Grid>
  )
}
