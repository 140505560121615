import { Rating, Stack, Typography } from "@mui/material"
import React from "react"
import "./StarRating.css"
import StarIcon from "@mui/icons-material/Star"

const StarRating = ({
  averageRating,
  totalRatings,
  row,
  handleRatingChange,
}) => {
  return (
    <Stack alignItems={"center"} flexDirection="row" flexWrap={"nowrap"}>
      <Typography
        sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}
        fontSize={12}
        variant="body1"
      >
        <Rating
          size="small"
          readOnly
          name="simple-controlled"
          value={averageRating}
          onChange={handleRatingChange}
          onClick={(event) => {
            event.stopPropagation()
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        ({totalRatings})
      </Typography>
    </Stack>
  )
}

export default StarRating
