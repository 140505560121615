import CompleteBusinessProfile from "../screens/AuthScreens/BusinessProfile/BusinessProfile"
import ForgetPassword from "../screens/AuthScreens/ForgetPassword/ForgetPassword"
import Login from "../screens/AuthScreens/LoginScreen/Login"
import OTPScreen from "../screens/AuthScreens/OTPScreen/OTPScreen"
import ResetPasswordOTPScreen from "../screens/AuthScreens/ResetPasswordOTPScreen/ResetPasswordOTPScreen.jsx"
import ResetPassword from "../screens/AuthScreens/ResetPassword/ResetPassword"
import Signup from "../screens/AuthScreens/SignupScreen/Signup"
import Category from "../screens/Category/Category"
import Home from "../screens/Home/Home"
import { Error_Routes } from "../screens/Error_Routes"
import Product from "../screens/Product/Product.jsx"
import AddCartScreen from "../screens/addCart/AddtoCart.jsx"
import ChatScreen from "../screens/chatScreen/ChatScreen.jsx"
import CheckoutScreen from "../screens/CheckoutScreens/CheckoutScreen.jsx"
import DashboardMain from "../screens/dashboard/DashboardMain.jsx"
import Dashboard from "../screens/dashboard/Dashboard/Dashboard.jsx"
import ShoppingCarts from "../screens/dashboard/ShoppingCart/ShoppingCart.jsx"
import WishList from "../screens/dashboard/WishList/WishList.jsx"
import CardsAdress from "../screens/dashboard/CardsAdress/CardsAdress.jsx"
import Setting from "../screens/dashboard/Setting/Setting.jsx"
import SellerDashboard from "../screens/SellerDashboard/Dashboard/Dashboard.jsx"
import AddProducts from "../screens/SellerDashboard/AddProducts/AddProducts.jsx"
import { PiStackBold } from "react-icons/pi"
import { PiStorefront } from "react-icons/pi"
import { PiShoppingCartSimpleBold } from "react-icons/pi"
import { MdOutlineFavoriteBorder } from "react-icons/md"
import { PiNotebookBold } from "react-icons/pi"
import { TbSettings } from "react-icons/tb"
import { MdOutlineLogout } from "react-icons/md"
import { HiOutlineViewGrid } from "react-icons/hi"
import { MdOutlineShoppingBag } from "react-icons/md"
import { FiShoppingCart } from "react-icons/fi"
import { HiOutlineUsers } from "react-icons/hi"
import SellerDashboardMain from "../screens/SellerDashboard/SellerDashboard.jsx"
import Customers from "../screens/SellerDashboard/Customers/Customers.jsx"
import OrderHistory from "../screens/dashboard/OrderHistory/OrderHistory.jsx"
import ProductsList from "../screens/SellerDashboard/ProductsList/ProductsList.jsx"
import OrderList from "../screens/SellerDashboard/Orders/OrderList.jsx"
import OrderDetails from "../screens/SellerDashboard/OrderDetails/OrderDetails.jsx"
import CustomerDetail from "../screens/SellerDashboard/CustomerDetail/CustomerDetail.jsx"
import EditProduct from "../screens/SellerDashboard/EditProduct/EditProduct.jsx"
import { TbLockCancel } from "react-icons/tb"
import Security from "../screens/dashboard/Security/Security.jsx"
import Diversity2TwoToneIcon from "@mui/icons-material/Diversity2TwoTone"
import Negotiations from "../screens/dashboard/MyNegotiationsBuyer/Negotiations.jsx"

export const mainRoutes = [
  {
    linkTo: "/",
    element: <Home />,
    authRequired: false,
    check_cond: false,
  },
  {
    linkTo: "/category",
    element: <Category />,
    authRequired: false,
    check_cond: false,
  },
  {
    linkTo: "/product/:id",
    element: <Product />,
    authRequired: false,
    check_cond: false,
  },
  {
    linkTo: "/checkout",
    element: <CheckoutScreen />,
    authRequired: false,
    check_cond: false,
  },
  {
    linkTo: "/add-cart",
    element: <AddCartScreen />,
    authRequired: false,
    check_cond: false,
  },
  {
    linkTo: "/signup",
    element: <Signup />,
    authRequired: false,
    check_cond: true,
  },
  {
    linkTo: "/login",
    element: <Login />,
    authRequired: false,
    check_cond: true,
  },
  {
    linkTo: "/complete-business-profile",
    element: <CompleteBusinessProfile />,
    authRequired: true,
    check_cond: true,
  },
  {
    linkTo: "/reset-password",
    element: <ResetPassword />,
    authRequired: false,
    check_cond: true,
  },
  {
    linkTo: "/forget-password",
    element: <ForgetPassword />,
    authRequired: false,
    check_cond: true,
  },
  {
    linkTo: "/otp",
    element: <OTPScreen />,
    authRequired: true,
    check_cond: true,
  },
  {
    linkTo: "/reset-password-otp",
    element: <ResetPasswordOTPScreen />,
    authRequired: false,
    check_cond: true,
  },
  {
    linkTo: "/chat",
    element: <ChatScreen />,
    authRequired: false,
    check_cond: false,
  },
  {
    linkTo: "dashboard/*",
    element: <DashboardMain />,
    authRequired: true,
    check_cond: true,
  },
  {
    linkTo: "seller-dashboard/*",
    element: <SellerDashboardMain />,
    authRequired: true,
    check_cond: true,
  },
  {
    linkTo: "*",
    element: <Error_Routes />,
    authRequired: false,
    check_cond: false,
  },
]

export const nestedRoutes_dashboard = [
  {
    name: "Dashboard",
    linkTo: "",
    element: <Dashboard />,
    icon: <PiStackBold />,
    menu_item: true,
  },
  {
    name: "Order History",
    linkTo: "orderhistory",
    element: <OrderHistory />,
    icon: <PiStorefront />,
    menu_item: true,
  },
  // {
  //   name: "Shopping Cart",
  //   linkTo: "shoppingcart",
  //   element: <ShoppingCarts />,
  //   icon: <PiShoppingCartSimpleBold />,
  //   menu_item: true,
  // },
  {
    name: "Wishlist",
    linkTo: "wishlist",
    element: <WishList />,
    icon: <MdOutlineFavoriteBorder />,
    menu_item: true,
  },
  {
    name: "Cards & Address",
    linkTo: "cardaddress",
    element: <CardsAdress />,
    icon: <PiNotebookBold />,
    menu_item: true,
  },
  {
    name: "Setting",
    linkTo: "setting",
    element: <Setting />,
    icon: <TbSettings />,
    menu_item: true,
  },
  {
    name: "Security",
    linkTo: "security",
    element: <Security />,
    icon: <TbLockCancel />,
    menu_item: true,
  },
  {
    name: "Negotiations",
    linkTo: "negotiation",
    element: <Negotiations />,
    icon: <Diversity2TwoToneIcon />,
    menu_item: true,
  },
  {
    name: "Log-out",
    linkTo: "",
    element: "",
    icon: <MdOutlineLogout />,
    menu_item: true,
  },
]

export const seller_dashboard_routes = [
  {
    name: "Dashboard",
    linkTo: "",
    element: <SellerDashboard />,
    icon: <HiOutlineViewGrid />,
    menu_item: true,
  },
  {
    name: "Products",
    linkTo: "productslist",
    element: <ProductsList />,
    icon: <MdOutlineShoppingBag />,
    menu_item: true,
  },
  {
    name: "",
    linkTo: "productslist/editProduct/:id",
    element: <EditProduct />,
    icon: <MdOutlineShoppingBag />,
    menu_item: false,
  },
  {
    name: "Add Product",
    linkTo: "productslist/addproducts",
    element: <AddProducts />,
    icon: <MdOutlineShoppingBag />,
    menu_item: false,
  },
  {
    name: "Order List",
    linkTo: "order",
    element: <OrderList />,
    icon: <FiShoppingCart />,
    menu_item: true,
  },
  {
    name: "Order Details",
    linkTo: "order/order-details/:id",
    element: <OrderDetails />,
    icon: <FiShoppingCart />,
    menu_item: false,
  },
  {
    name: "Customers",
    linkTo: "customers",
    element: <Customers />,
    icon: <HiOutlineUsers />,
    menu_item: true,
  },
  {
    name: "Customer Details",
    linkTo: "customers-details",
    element: <CustomerDetail />,
    icon: <HiOutlineUsers />,
    menu_item: false,
  },
  {
    name: "Negotiations",
    linkTo: "negotiations",
    element: <Negotiations />,
    icon: <Diversity2TwoToneIcon />,
    menu_item: true,
  },
  {
    name: "Log-out",
    linkTo: "",
    element: "",
    icon: <MdOutlineLogout />,
    menu_item: true,
  },
]
