import { Stack, Typography } from "@mui/material"
import Input from "../Input/Input"
import { inputLabel } from "../../../utils/colorTheme"
import CountryCodePicker from "../../CountryCodePicker/CountryCodePicker"
import PasswordInput from "../PasswordInput/PasswordInput"
import { useState } from "react"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"

function AuthInput({
  onChange,
  label,
  showPasswordLabel,
  forPhone,
  forPassword,
  showPassHiddenIcon,
  id,
}) {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Stack>
      <Stack
        pb={0.5}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontSize={17} color={inputLabel}>
          {label}
        </Typography>
        {showPassHiddenIcon && (
          <Typography
            sx={{
              cursor: "pointer",
            }}
            fontSize={17}
            color={inputLabel}
            onClick={() => setShowPassword((prev) => !prev)}
            alignItems={"center"}
            display={"flex"}
            gap={0.2}
          >
            {!showPassword ? (
              <>
                <AiFillEye size={22} />
                Show
              </>
            ) : (
              <>
                <AiFillEyeInvisible size={22} />
                Hide
              </>
            )}
          </Typography>
        )}
      </Stack>
      <Stack>
        {forPhone ? (
          <CountryCodePicker sethandlePhoneNumber={onChange} />
        ) : forPassword ? (
          <PasswordInput
            showPassword={showPassword}
            onChange={onChange}
            id={id}
          />
        ) : (
          <Input
            style={{ backgroundColor: "transparent" }}
            onChange={onChange}
            id={id}
          />
        )}
      </Stack>
      {showPasswordLabel && (
        <Stack>
          <Typography fontSize={12} color={"#666666"}>
            Use 8 or more characters with a mix of letters, numbers & symbols
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default AuthInput
