import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"



export const create_business_async = createAsyncThunk(
  type_constants.CREATE_BUSINESS,
  async (business_profile_data) => {
    try {
      const response = await apiHandle.post(
        "/user/create-business",
        business_profile_data,
      )
      const res_data = await response.data
      return res_data
    } catch (error) {
      console.error("the BUSINESS ERROR ==>", error)
      if (error?.response?.data) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
