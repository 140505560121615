import React, { useEffect } from "react"
import { Avatar, Box, CircularProgress, Stack, Typography } from "@mui/material"
import css from "./TopCategories.module.css"
import { useDispatch, useSelector } from "react-redux"
import { asyncStatus } from "../../utils/asyncStatus"
import { get_all_categories_async } from "../../store/services/categoriesService"
import { get_all_products_async } from "../../store/services/productsService"
import { useNavigate } from "react-router-dom"
import { SAVE_CATEGORY_ID } from "../../store/constant"

function TopCategories() {
  const { categories_status, categories_data } = useSelector(
    (state) => state.categoriesManageSlice,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const category_loader = categories_status === asyncStatus.LOADING

  useEffect(() => {
    dispatch(get_all_categories_async())
  }, [])

  const click_handle = (id) => {
    dispatch(get_all_products_async({ data: { category_id: id } }))
    localStorage.setItem(SAVE_CATEGORY_ID, id)
    navigate("/category")
  }

  return (
    <Stack my={8} position={"relative"}>
      <Typography
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          fontSize: "24px",
        }}
      >
        Categories
      </Typography>
      <Typography
        fontSize={"24px"}
        style={{
          borderBottom: "4px solid rgba(248, 149, 0, 1)",
          position: "absolute",
          right: "77%",
          top: "-1px",
          zIndex: 1,
          left: "0%",
        }}
        color={"rgba(102, 102, 102, 1)"}
      >
        &nbsp;
      </Typography>
      <Typography
        fontSize={"24px"}
        style={{
          borderBottom: "2px solid lightgray",
          position: "absolute",
          left: "20%",
          right: "0%",
        }}
        color={"rgba(102, 102, 102, 1)"}
      >
        <span style={{ color: "rgba(248, 149, 0, 1)" }}>&nbsp;</span>
      </Typography>

      <Stack mt={8} gap={2} flexDirection={"row"} id={css.top_categpries}>
        {category_loader ? (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={"6vh"}
          >
            <CircularProgress
              size={20}
              sx={{ color: "rgba(248, 149, 0, 1)" }}
            />
          </Stack>
        ) : categories_data.length === 0 ? (
          ""
        ) : (
          categories_data?.map(({ category_title, image_url, _id }, i) => {
            return (
              <Stack
                key={i}
                gap={1}
                onClick={() => click_handle(_id)}
                sx={{ cursor: "pointer" }}
              >
                <Stack
                  sx={{
                    width: 140,
                    height: 140,
                    bgcolor: "#F5F5F5",
                    borderRadius: "100%",
                    overflow: "hidden",
                    p:2
                  }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    src={image_url ?? ""}
                    alt=""
                    style={{
                      height: "90%",
                      width: "90%",
                      objectFit: "contain",
                    }}
                  />
                </Stack>
                <Typography align="center">{category_title ?? ""}</Typography>
              </Stack>
            )
          })
        )}
      </Stack>
    </Stack>
  )
}

export default TopCategories
