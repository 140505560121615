import React from "react"
import Navbar from "../../components/common/navbar/Navbar"
import Banner from "../../components/Banner/Banner"
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import TopCategories from "../../components/TopCategories/TopCategories"

import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { get_all_products_async } from "../../store/services/productsService"
import { useNavigate } from "react-router-dom"
import Footer from "../../components/footer/Footer"
import FooterEnd from "../../components/footer/footerEnd/FooterEnd"
import { ProductCard_Grid } from "../../components/Cards/ProductCard/ProductCard"
import { get_all_categories_async } from "../../store/services/categoriesService"
import { asyncStatus } from "../../utils/asyncStatus"
import Alert from "@mui/material/Alert"
import { setBusinessProfileData } from "../../store/slices/auth_slice"

const Home = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data, status } = useSelector((state) => state.product)
  const { userData, userAuth } = useSelector((state) => state.auth)
  const { business_profile_status, business_profile_data } = useSelector(
    (state) => state.businessManageSlice,
  )

  useEffect(() => {
    dispatch(get_all_products_async({ page: 1 }))
    dispatch(get_all_categories_async())
  }, [])

  useEffect(() => {
    if (business_profile_status === asyncStatus.SUCCEEDED) {
      dispatch(setBusinessProfileData(business_profile_data))
    }
  }, [business_profile_status,userData?.seller_business_profile])
  return (
    <>
      {userAuth &&
        userData.user_type === "seller" &&
        !userData.seller_business_profile && (
          <Alert
            color="warning"
            severity="warning"
            onClose={() => setShowAlert(false)}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => navigate("/complete-business-profile")}
              >
                Business Profile
              </Button>
            }
          >
            Create business profile first.
          </Alert>
        )}
      <Navbar />
      <Banner />
      <Container  sx={{ my: 2 }}>
        <Grid  container >
          {status === asyncStatus.LOADING ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={"20vh"}
            >
              <CircularProgress
                size={30}
                sx={{ color: "rgba(248, 149, 0, 1)" }}
              />
            </Stack>
          ) : (
            data?.data.map((e, i) => {
              return (
                <ProductCard_Grid
                  key={i}
                  Rating={29}
                  productImg={e.images[e.feature_image]}
                  price={e.price}
                  title={e.title}
                  description={e.description}
                  inStock={e.in_stock}
                  onClick={() => navigate(`/product/${e._id}`)}
                  avgRating={1}
                />
              )
            })
          )}
        </Grid>
        <TopCategories />
        {/*<Stack direction={"row"} justifyContent={"space-between"} my={2}>
          <Stack>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 22,
              }}
            >
              New Products
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                color: "#0156FF",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: 13,
              }}
            >
              See All New Products
            </Typography>
          </Stack>
        </Stack>
        <Grid container spacing={1} gap={1}>
          {productData.map((e, i) => (
            <ProductCard_Grid
              key={i}
              Rating={e.Rating}
              productImg={
                "https://images.unsplash.com/photo-1560343090-f0409e92791a?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cHJvZHVjdHxlbnwwfHwwfHx8MA%3D%3D"
              }
              price={e.price}
              description={e.description}
              inStock={e.inStock}
              avgRating={e.avgRating}
            />
          ))}
        </Grid> */}

        <Footer />
      </Container>
      <Divider />
      <FooterEnd />
    </>
  )
}

export default Home
