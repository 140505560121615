import { Box, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import css from "./Customers.module.css"
import CustomButton from "../../../components/common/Button/Button"
import { FiPlus } from "react-icons/fi"
import { BiSlider } from "react-icons/bi"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { Input } from "antd"
import ProductListTable from "../../../components/table/ProductListTable/ProductListTable"
import { useState } from "react"
import { useEffect } from "react"
import watch_pic1 from "../../../assets/productsListImages/watch.png"
import { FiTrash2 } from "react-icons/fi"
import { LuPencil } from "react-icons/lu"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { set_product_status } from "../../../store/slices/product_slice"
import {
  delete_product_async,
  get_product_by_user_id_async,
} from "../../../store/services/productsService"
const ProductComponent = (props) => {
  const { images, title, email } = props
  return (
    <Stack spacing={1} direction={"row"}>
      <Stack sx={{ borderRadius: "100%", overflow: "hidden" }}>
        <img
          height={44}
          width={44}
          //   src={images[0] ?? ""}
          src={watch_pic1}
          alt="product_image"
        />
      </Stack>
      <Stack justifyContent={"center"} gap={0.5}>
        <Typography className={css.image_title}>
          {title ?? "John Bushmill"}
        </Typography>
        <Typography className={css.email}>
          {email ?? "John@mail.com"}
        </Typography>
      </Stack>
    </Stack>
  )
}
const Customers = () => {
  const [inner_width, setCurrentWidth] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const { get_product_by_user_id_data } = useSelector((state) => state.product)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParmas] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: get_product_by_user_id_data?.length,
    },
  })
  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (props) => {
        return (
          <>
            <ProductComponent {...props} />
          </>
        )
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (phone) => (
        <Typography className={css.phone}>
          {phone ?? "078 5054 8877"}
        </Typography>
      ),
    },
    {
      title: "Orders",
      dataIndex: "orders",
      render: (orders) => (
        <Typography className={css.orders}>{orders ?? "0"}</Typography>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (balance) => (
        <Typography className={css.balance}>{`$${balance ?? "0"}`}</Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        const status_val = status === "Blocked"
        return (
          <Typography
            className={css.status}
            sx={{
              backgroundColor: status_val ? "#FEEDEC" : "#E7F4EE",
              color: status_val ? "#F04438" : "#0D894F",
            }}
          >
            {status ?? "Status"}
          </Typography>
        )
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (date) => (
        <Typography className={css.date}>
          {date?.split("T")[0] ?? "29 Dec 2022"}
        </Typography>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (_id) => {
        return (
          <Box className={css.actions}>
            <MdOutlineRemoveRedEye className={css.icon} />
            <LuPencil className={css.icon} />
            <FiTrash2
              className={css.icon}
              onClick={() => {
                dispatch(delete_product_async({ product_id: _id }))
              }}
            />
          </Box>
        )
      },
    },
  ]
  const navigate = useNavigate()
  const handleTabChange = (pagination) => {
    setTableParmas({
      pagination,
    })
    // Calculate the current page data based on pagination
    const { current, pageSize } = pagination
    const start = (current - 1) * pageSize
    const end = start + pageSize
    setData(get_product_by_user_id_data.slice(start, end))
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([])
    }
  }
  useEffect(() => {
    dispatch(set_product_status())
    dispatch(get_product_by_user_id_async())
    const handleInnerWidth = () => {
      setCurrentWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleInnerWidth)
    return () => window.addEventListener("resize", handleInnerWidth)
  }, [])
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      const { current, pageSize } = tableParams.pagination
      const start = (current - 1) * pageSize
      const end = start + pageSize
      setData(get_product_by_user_id_data.slice(start, end))
      setLoading(false)
    }, 500) // Simulated API response delay of 500ms
  }, [tableParams])
  return (
    <Box width={inner_width < 500 ? "95%" : "100%"}>
      <Typography className={css.title}>Customer</Typography>
      {/* ---- search field and add customers btn and filter btn ---- */}
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "nowrap",
          xs: "wrap",
        }}
      >
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} alignItems={"center"}>
          <Input
            className={css.input}
            size="large"
            placeholder="Search product. . ."
            prefix={<HiOutlineMagnifyingGlass size={22} color="#FA8232" />}
          />
        </Grid>
        {/* ---- add customer btn and filter ---- */}
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <CustomButton
              style={{
                backgroundColor: "white",
                border: "1px solid #E0E2E7",
                borderRadius: "8px",
              }}
              onClick={() => {}}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <BiSlider size={20} color="#667085" />
                <Typography className={css.select_filter_btn}>
                  Filters
                </Typography>
              </Stack>
            </CustomButton>
            <CustomButton
              style={{
                border: "1px solid #FF9900",
                borderRadius: "8px",
              }}
              onClick={() => {
                navigate("/seller-dashboard/customers-details")
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FiPlus size={20} />
                <Typography
                  className={css.customer_add_btn}
                  sx={{ color: "white" }}
                >
                  Add Customer
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>
      {/** ------- customers table ------- **/}
      <Box className={`${css.table} tabled`}>
        <ProductListTable
          // handleTabChange={handleTabChange}
          loading={loading}
          // pagination={tableParams.pagination}
          dataSource={get_product_by_user_id_data}
          columns={columns}
        />
      </Box>
    </Box>
  )
}
export default Customers