import { createSlice } from "@reduxjs/toolkit"
import {
  get_order_buyer_detail_by_id_async,
  get_order_detail_by_id_async,
  get_orders_async,
  get_orders_buyer_async,
  place_order_async,
} from "../services/ordersService"
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification"
import { asyncStatus } from "../../utils/asyncStatus"

const initialState = {
  place_order_negotiation_status: asyncStatus.IDLE,
  place_order_negotiation_data: null,
  place_order_negotiation_error: null,

  get_orders_status: asyncStatus.IDLE,
  get_orders_data: null,
  get_orders_error: null,

  get_orders_buyer_status: asyncStatus.IDLE,
  get_orders_buyer_data: null,
  get_orders_buyer_error: null,

  get_orders_detail_by_id_status: asyncStatus.IDLE,
  get_orders_detail_by_id_data: null,
  get_orders_detail_by_id_error: null,

  get_orders_buyer_detail_by_id_status: asyncStatus.IDLE,
  get_orders_buyer_detail_by_id_data: null,
  get_orders_buyer_detail_by_id_error: null,

}

const orders_slice = createSlice({
  name: "orders_slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // PLACE ORDER

    builder.addCase(place_order_async.pending, (state, actions) => {
      state.place_order_negotiation_status = asyncStatus.LOADING
    })
    builder.addCase(place_order_async.fulfilled, (state, { payload }) => {
      state.place_order_negotiation_status = asyncStatus.SUCCEEDED
      state.place_order_negotiation_data = payload.data
      state.place_order_negotiation_error = null
      success_toast_message(payload.message)
    })
    builder.addCase(place_order_async.rejected, (state, actions) => {
      state.place_order_negotiation_status = asyncStatus.ERROR
      state.place_order_negotiation_data = null
      state.place_order_negotiation_error = actions.error.message
      error_toast_message(actions.error.message)
    })

    // GET ORDERS LIST SELLER

    builder.addCase(get_orders_async.pending, (state, actions) => {
      state.get_orders_status = asyncStatus.LOADING
    })
    builder.addCase(get_orders_async.fulfilled, (state, { payload }) => {
      state.get_orders_status = asyncStatus.SUCCEEDED
      state.get_orders_data = payload.data
      state.get_orders_error = null
    })
    builder.addCase(get_orders_async.rejected, (state, actions) => {
      state.get_orders_status = asyncStatus.ERROR
      state.get_orders_data = null
      state.get_orders_error = actions.error.message
    })

    // GET ORDERS LIST BUYER

    builder.addCase(get_orders_buyer_async.pending, (state, actions) => {
      state.get_orders_buyer_status = asyncStatus.LOADING
    })
    builder.addCase(get_orders_buyer_async.fulfilled, (state, { payload }) => {
      state.get_orders_buyer_status = asyncStatus.SUCCEEDED
      state.get_orders_buyer_data = payload.data
      state.get_orders_buyer_error = null
    })
    builder.addCase(get_orders_buyer_async.rejected, (state, actions) => {
      state.get_orders_buyer_status = asyncStatus.ERROR
      state.get_orders_buyer_data = null
      state.get_orders_buyer_error = actions.error.message
    })

    // GET ORDER DETAIL BY ORDER ID
    builder.addCase(get_order_detail_by_id_async.pending, (state, actions) => {
      state.get_orders_detail_by_id_status = asyncStatus.LOADING
    })
    builder.addCase(
      get_order_detail_by_id_async.fulfilled,
      (state, { payload }) => {
        state.get_orders_detail_by_id_status = asyncStatus.SUCCEEDED
        state.get_orders_detail_by_id_data = payload.data
        state.get_orders_detail_by_id_error = null
      },
    )
    builder.addCase(get_order_detail_by_id_async.rejected, (state, actions) => {
      state.get_orders_detail_by_id_status = asyncStatus.ERROR
      state.get_orders_detail_by_id_data = null
      state.get_orders_detail_by_id_error = actions.error.message
      error_toast_message(actions.error.message)
    })

    // GET ORDER BUYER DETAIL BY ORDER ID
    builder.addCase(
      get_order_buyer_detail_by_id_async.pending,
      (state, actions) => {
        state.get_orders_buyer_detail_by_id_status = asyncStatus.LOADING
      },
    )
    builder.addCase(
      get_order_buyer_detail_by_id_async.fulfilled,
      (state, { payload }) => {
        state.get_orders_buyer_detail_by_id_status = asyncStatus.SUCCEEDED
        state.get_orders_buyer_detail_by_id_data = payload.data
        state.get_orders_buyer_detail_by_id_error = null
      },
    )
    builder.addCase(
      get_order_buyer_detail_by_id_async.rejected,
      (state, actions) => {
        state.get_orders_buyer_detail_by_id_status = asyncStatus.ERROR
        state.get_orders_buyer_detail_by_id_data = null
        state.get_orders_buyer_detail_by_id_error = actions.error.message
        error_toast_message(actions.error.message)
      },
    )

   
  },
})

export default orders_slice.reducer
