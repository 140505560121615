import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material"
import AuthContainer from "../../../components/common/AuthContainer/AuthContainer"
import { primaryColour } from "../../../utils/colorTheme"
import { user_type_constant } from "../../../utils/constants"
import AuthInput from "../../../components/common/AuthInput/AuthInput"
import CustomButton from "../../../components/common/Button/Button"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { login_async } from "../../../store/services/authService"
import { asyncStatus } from "../../../utils/asyncStatus"
import { useEffect } from "react"
import { setResetStatusState } from "../../../store/slices/auth_slice"

function Login() {
  // const BUYER_LABEL = <span style={{ fontWeight: "bold" }}>Buyer</span>
  // const SELLER_LABEL = <span style={{ fontWeight: "bold" }}>Seller</span>
  // const SELLER_PARTNER_LABEL = (
  //   <span style={{ fontWeight: "bold" }}>Seller Partner</span>
  // )

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loginData, setLoginData] = useState({})
  const { login_status, userAuth, userData } = useSelector(
    (state) => state.auth,
  )

  const loading = login_status === asyncStatus.LOADING

  const InputHandler = (e, forRadio) => {
    setLoginData((prev) => {
      // if (forPhone) {
      //   return { ...prev, contact_number: e }
      // }
      if (forRadio) {
        return { ...prev, user_type: e.target.value }
      } else {
        return { ...prev, [e.target.id]: e.target.value }
      }
    })
  }
  const submitHandler = () => {
    dispatch(login_async(loginData))
  }
  useEffect(() => {
    dispatch(setResetStatusState())
  }, [])

  return (
    <Container>
      <AuthContainer>
        <Stack maxWidth={600} mx={"auto"} rowGap={"30px"}>
          <Stack>
            <Typography variant="h2" className="heading">
              Login
            </Typography>
          </Stack>
          {/*  USER-TYPE RADIO BUTTONS
          <Stack>
            <Stack mt={1}>
              <Typography>
                Select your user type to access your account.
              </Typography>
            </Stack>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{
                fontWeight: "bold",
              }}
              // onChange={InputHandler}
            >
              <FormControlLabel
                value={user_type_constant.BUYER}
                control={
                  <Radio
                    sx={{
                      color: primaryColour,
                      "&.Mui-checked": {
                        color: primaryColour,
                      },
                    }}
                  />
                }
                labelPlacement="end"
                label={BUYER_LABEL}
              />
              <FormControlLabel
                labelPlacement="end"
                value={user_type_constant.SELLER}
                control={
                  <Radio
                    sx={{
                      color: primaryColour,
                      "&.Mui-checked": {
                        color: primaryColour,
                      },
                      fontWeight: "bold",
                    }}
                  />
                }
                color={primaryColour}
                label={SELLER_LABEL}
                sx={{ fontWeight: "bold" }}
              />
              <FormControlLabel
                labelPlacement="end"
                value={user_type_constant.BUSINESS_PARTNER}
                control={
                  <Radio
                    sx={{
                      color: primaryColour,
                      "&.Mui-checked": {
                        color: primaryColour,
                      },
                      fontWeight: "bold",
                    }}
                  />
                }
                color={primaryColour}
                label={SELLER_PARTNER_LABEL}
                sx={{ fontWeight: "bold" }}
              />
            </RadioGroup>
          </Stack> */}

          <Grid container rowGap={2} mt={2}>
            <Grid item xs={12}>
              <AuthInput
                id={"email"}
                onChange={InputHandler}
                label={"Email Address or Phone Number"}
              />
            </Grid>

            <Grid item xs={12}>
              <AuthInput
                id={"password"}
                onChange={InputHandler}
                forPassword
                showPassHiddenIcon
                label={"Password"}
              />
              <Typography
                pt={0.5}
                className="span-link"
                fontSize={12}
                textAlign={"end"}
                onClick={() => navigate("/forget-password")}
              >
                Forget Password?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <CustomButton
                style={{ width: "100%", fontSize: 17, fontWeight: "bold" }}
                disable={loading}
                onClick={submitHandler}
              >
                {!loading ? "Login" : <CircularProgress color="inherit" size={17} />}
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={12} textAlign={"center"}>
                Don't have an Account ?{" "}
                <span onClick={() => navigate("/signup")} className="span-link">
                  SignUp
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </AuthContainer>
    </Container>
  )
}

export default Login
