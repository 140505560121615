import * as React from "react"
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import { CURRENCY } from "../../../utils/constants"
import { Stack, Typography } from "@mui/material"
import { inputLabel } from "../../../utils/colorTheme"
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const MultipleSelectBox = ({ onChange, value }) => {
  const theme = useTheme()

  return (
    <Stack gap={0.2} justifyContent={"space-between"} alignItems={"flex-start"}>
      <Typography fontSize={17} color={inputLabel}>
        Currency
      </Typography>
      <FormControl
        sx={{
          width: "100%",
          "&:active": {
            border: "none",
            outline: "none",
          },
        }}
        size="small"
      >
        <Select
          id="demo-multiple-chip"
          multiple
          displayEmpty
          value={value}
          onChange={onChange}
          // sx={{

          // }}
          renderValue={(selected) => (
            <Stack gap={0.5} direction={"center"} alignItems={"center"}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Stack>
          )}
          MenuProps={MenuProps}
        >
          {CURRENCY.map(({ country, currency, currency_code }, i) => (
            <MenuItem
              key={i}
              value={currency_code}
              style={getStyles(currency_code, value, theme)}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={1}
              >
                <Typography>{currency}</Typography>
                <Typography>{currency_code}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )
}

export default MultipleSelectBox
