import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material"
import AuthContainer from "../../../components/common/AuthContainer/AuthContainer"
import { primaryColour } from "../../../utils/colorTheme"
import { user_type_constant } from "../../../utils/constants"
import CustomButton from "../../../components/common/Button/Button"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { asyncStatus } from "../../../utils/asyncStatus"
import MultipleSelectBox from "../../../components/common/MultipleSelectBox/MultipleSelectBox"
import AuthInput from "../../../components/common/AuthInput/AuthInput"
import { create_business_async } from "../../../store/services/businessMangeService"

const BUYER_LABEL = <span style={{ fontWeight: "bold" }}>Buyer</span>
const SELLER_LABEL = <span style={{ fontWeight: "bold" }}>Seller</span>

function CompleteBusinessProfile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { business_profile_status } = useSelector(
    (state) => state.businessManageSlice,
  )
  const loading = business_profile_status === asyncStatus.LOADING

  const [currency, setCurrency] = useState([])
  const [signUpData, setSignUpData] = useState("")

  const InputHandler = (e, forPhone, forRadio) => {
    setSignUpData((prev) => {
      if (forPhone) {
        return { ...prev, contact_number: e }
      }
      if (forRadio) {
        return { ...prev, user_type: e.target.value }
      } else {
        return { ...prev, [e.target.id]: e.target.value }
      }
    })
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setCurrency(typeof value === "string" ? value.split(",") : value)
  }

  useEffect(() => {
    setSignUpData((prev) => ({ ...prev, currencies: currency }))
  }, [currency])

  const submitHandler = () => {
    dispatch(create_business_async(signUpData))
  }

  useEffect(() => {
    if (business_profile_status === asyncStatus.SUCCEEDED) {
      navigate("/")
    }
  }, [business_profile_status])

  return (
    <Container>
      <AuthContainer>
        <Stack maxWidth={600} mx={"auto"}>
          <Stack>
            <Typography variant="h2" className="heading">
              Create Business Profile
            </Typography>
          </Stack>

          <Grid container rowGap={2} mt={2}>
            <Grid item xs={12}>
              <AuthInput
                id={"business_name"}
                onChange={InputHandler}
                label={"Legal Business Name"}
              />
            </Grid>
            <Grid item xs={6} pr={2}>
              <AuthInput onChange={InputHandler} id="city" label={"City"} />
            </Grid>
            <Grid item xs={6}>
              <AuthInput
                id={"country"}
                onChange={InputHandler}
                label={"Country"}
              />
            </Grid>
            <Grid item xs={6} pr={2}>
              <AuthInput
                id={"zip_code"}
                onChange={InputHandler}
                label={
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Typography  >Zip Code</Typography>
                    <Typography sx={{fontSize:'13px'}}>(optional)</Typography>
                  </Stack>
                }
              />
            </Grid>
            <Grid item xs={6}  height={"30px"}>
              <MultipleSelectBox value={currency} onChange={handleChange} />
            </Grid>

            <Grid item xs={12}>
              <AuthInput
                id={"address"}
                onChange={InputHandler}
                label={"Address"}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Stack
                direction={"row"}
                flexWrap={"nowrap"}
                alignItems={"center"}
              >
                <Checkbox
                  sx={{
                    m: 0,
                    p: 0,
                    color: primaryColour,
                    "&.Mui-checked": {
                      color: primaryColour,
                    },
                  }}
                />
                <Typography pl={1} fontSize={12}>
                  By creating an account, I agree to our Terms of use and
                  Privacy Policy
                </Typography>
              </Stack>
            </Grid> */}

            <Grid item xs={12}>
              <CustomButton
                disable={loading}
                onClick={submitHandler}
                style={{ width: "100%", fontSize: 17, fontWeight: "bold" }}
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <CircularProgress color="inherit" size={17} />
                )}
              </CustomButton>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography fontSize={12} textAlign={"center"}>
                Already have an Account ?{" "}
                <span className="span-link" onClick={() => navigate("/login")}>
                  Log in
                </span>
              </Typography>
            </Grid> */}
          </Grid>
        </Stack>
      </AuthContainer>
    </Container>
  )
}

export default CompleteBusinessProfile
