import { Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import { primaryColour } from "../../utils/colorTheme"
import { orange } from "@mui/material/colors"

const PageCount = ({ num, onChange,defaultPage }) => {
  
  return (
    <>
      {/* <Stack
        sx={{
          border: `1px solid ${primaryColour}`,
          borderRadius: "50%",
          padding: 1,
          width: 30,
          height: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer", // Optional: Add a pointer cursor to indicate interactivity
          "&:hover": {
            backgroundColor: primaryColour, // Change background color on hover
            color: "#fff", // Change text color on hover
          },
          "&:active": {
            // Add additional styles for the active state if needed
          },
        }}
      >
        <Typography
          sx={{
            "&:hover": {
              color: "#fff", // Change text color on hover
            },
            "&:active": {
              // Add additional styles for the active state if needed
            },
          }}
        >
          {num}
        </Typography>
      </Stack> */}
      <Pagination
        sx={{
          "& .MuiPaginationItem-root": {
            backgroundColor: "white",
            border: `1px solid ${primaryColour}`,
            color: primaryColour,
            ":hover": {
              border: `1px solid white`,
              backgroundColor: primaryColour,
              color: "white",
            },
            ":focus": {
              border: `1px solid white`,
              backgroundColor: primaryColour,
              color: "white",
            },
            ":active":{
              border: `1px solid white`,
              backgroundColor: primaryColour,
              color: "white",
            }
          },
        }}
        defaultChecked={1}
        defaultPage={defaultPage}
        onChange={onChange}
        count={num}
      />
    </>
  )
}

export default PageCount
