import React, { useEffect, useState } from "react"
import Navbar from "../../components/common/navbar/Navbar"
import Banner from "../../components/Banner/Banner"
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material"

import { useDispatch, useSelector } from "react-redux"

import {
  BsFillGrid3X3GapFill,
  BsReverseListColumnsReverse,
} from "react-icons/bs"
import CustomButton from "../../components/common/Button/Button"
import CustomDrawer from "../../components/Drawer/Drawer"
import { get_all_products_async } from "../../store/services/productsService"
import PageCount from "../../components/pageCount/PageCount"
import { asyncStatus } from "../../utils/asyncStatus"
import { useNavigate } from "react-router-dom"
import { BiMenuAltLeft } from "react-icons/bi"
import {
  ProductCard_Grid,
  ProductCard_List,
} from "../../components/Cards/ProductCard/ProductCard"
import CustomAccordion from "../../components/CustomAccordion/CustomAccordion"
import { SAVE_CATEGORY_ID } from "../../store/constant"

const Category = () => {
  const { data, status } = useSelector((state) => state.product)

  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [list, setList] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [innerWidth])

  const handleChange = (event, page) => {
    setCurrentPage(page)
    dispatch(get_all_products_async({ page }))
  }

  const _getFilteredProducts = () => {
    const category_id = localStorage.getItem(SAVE_CATEGORY_ID)
    dispatch(get_all_products_async({ data: { category_id: category_id } }))
  }

  useEffect(() => {
    // handleChange()
    _getFilteredProducts()
  }, [])

  return (
    <>
      <Navbar />
      <Container sx={{ my: 2 }}>
        <Grid container>
          {window.innerWidth <= 900 ? (
            <Grid item md={12} sm={12} xs={false} sx={{ my: 2 }}>
              <CustomAccordion
                title={"Filter Options"}
                Child={<CustomDrawer />}
              />
            </Grid>
          ) : (
            <Grid item md={3} sm={3} xs={false} sx={{ my: 2 }}>
              <CustomDrawer />
            </Grid>
          )}

          <Grid item xl={9} lg={9} md={9} xs={12} sm={12}>
            <Stack pr={1} flexDirection={"row"} justifyContent={"flex-end"}>
              {innerWidth >= 600 && (
                <CustomButton
                  style={{
                    background: "none",
                    color: list ? "black" : "lightgray",
                  }}
                  onClick={() => setList(true)}
                >
                  {/* <BsReverseListColumnsReverse size={20} /> */}
                  <BiMenuAltLeft size={25} />
                </CustomButton>
              )}

              <CustomButton
                style={{
                  background: "none",
                  color: !list ? "black" : "lightgray",
                }}
                onClick={() => setList(false)}
              >
                <BsFillGrid3X3GapFill size={20} />
              </CustomButton>
            </Stack>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              rowGap={2}
            >
              {status === asyncStatus.LOADING ? (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"80vh"}
                >
                  <CircularProgress color="warning" size={30} />
                </Stack>
              ) : data?.data.length ? (
                data?.data.map((e, i) => {
                  return list ? (
                    <ProductCard_List
                      key={i}
                      Rating={e.reviews.length}
                      title={e.title}
                      productImg={e.images[e.feature_image]}
                      price={e.price}
                      description={e.description}
                      inStock={e.in_stock}
                      onClick={() => {
                        navigate(`/product/${e?._id}`)
                      }}
                      avgRating={e.average_rating ?? 0}
                    />
                  ) : (
                    <ProductCard_Grid
                      key={i}
                      Rating={e.reviews.length}
                      productImg={e.images[e.feature_image]}
                      title={e.title}
                      price={e.price}
                      description={e.description}
                      inStock={e.in_stock}
                      onClick={() => {
                        navigate(`/product/${e?._id}`)
                      }}
                      avgRating={e.average_rating ?? 0}
                    />
                  )
                })
              ) : (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"80vh"}
                >
                  <Typography variant="h5">No data found</Typography>
                </Stack>
              )}
            </Grid>

            <Stack alignItems={"center"} justifyContent={"center"} mt={1}>
              <PageCount
                defaultPage={currentPage ?? 1}
                onChange={handleChange}
                num={data?.total_pages}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Category
