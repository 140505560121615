import Box from "@mui/joy/Box"
import Slider from "@mui/joy/Slider"
import * as React from "react"
import { primaryColour } from "../../utils/colorTheme"

function valueText(value) {
  return `${value}°C`
}

export default function RangeInput(props) {
  const { value, onChange, onChangeCommitted } = props

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Slider
        onChangeCommitted={onChangeCommitted}
        color="warning"
        max={1000}
        getAriaLabel={() => "Temperature range"}
        value={value}
        trackColor="orange"
        defaultValue={[20, 30]}
        onChange={onChange}
        valueLabelDisplay="auto"
        sx={{ trackColor: "orange" }}
        getAriaValueText={valueText}
      />
    </Box>
  )
}
