import React, { useEffect, useState } from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Grid } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Stack, Typography } from "@mui/joy"
import { exit_session } from "../../config/apiHandle/apiHandle"
import { success_toast_message } from "../../utils/toast_notification"

const Breadcrumb = ({ breadCrumbsRoutes, rootDashboardRout }) => {
  const navigate = useNavigate()
  const [inner_width, setInner_width] = useState(window.innerWidth)
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    const handleWidth = () => {
      setInner_width(window.innerWidth)
    }
    window.addEventListener("resize", handleWidth)

    return () => window.addEventListener("resize", handleWidth)
  }, [])

  const location = useLocation() // Get the current URL location
  const currentPath = location.pathname // Get the current path from the URL

  let current_path_active = currentPath.split("/")[2] || ""

  const handleClick = (route) => {
    navigate(`/${rootDashboardRout}/${route}`)
  }

  const submit = () => {
    exit_session()
    success_toast_message("Logout successfully")
  }

  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(242, 244, 245, 1)",
        height: "55px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item xl={6.6} lg={7} md={6.8} sm={10.5} xs={9.8}>
        {inner_width <= 900 ? (
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="rgba(250, 130, 50, 1)"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {breadCrumbsRoutes.map((routes, i) => {
              const { name } = routes
              let isCurrentPath = current_path_active === routes.linkTo

              return name === "Home" ? (
                <Tab
                  key={i}
                  label={routes.name}
                  onClick={() => navigate(`/`)}
                />
              ) : (
                <Tab
                  key={i}
                  label={routes.name}
                  onClick={() => handleClick(routes.linkTo)}
                  sx={{
                    color: isCurrentPath ? "#FA8232" : "inherit", // Change color for active tab
                    "&.Mui-selected": {
                      color: "rgba(250, 130, 50, 1)", // Change color for active tab's text
                    },
                  }}
                />
              )
            })}
          </Tabs>
        ) : (
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadCrumbsRoutes.map((routes, i) => {
              const { name } = routes
              let isCurrentPath = current_path_active === routes.linkTo

              const isLogout = name === "Log-out"

              return name === "Home" ? (
                <Stack
                  key={i}
                  gap={0.5}
                  flexDirection="row"
                  alignItems="center"
                  sx={{
                    pointerEvents: "all",
                    cursor: "pointer",
                    ":hover": {
                      textDecorationLine: "underline",
                    },
                  }}
                  onClick={() => (isLogout ? submit() : navigate(`/`))}
                >
                  {routes.icon ?? ""}
                  <Typography fontSize={"15px"}>{routes.name}</Typography>
                </Stack>
              ) : (
                isCurrentPath && (
                  <Typography
                    key={i}
                    fontSize={"15px"}
                    sx={{ color: "orange" }}
                  >
                    {routes.name}
                  </Typography>
                )
              )
            })}
          </Breadcrumbs>
        )}
      </Grid>
    </Grid>
  )
}

export default Breadcrumb
